import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Spinner } from 'lp-components'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { AccountType, AllSavingsClubTypes } from 'types'
import { withApiAuth } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  allPrograms: AllSavingsClubTypes,
  children: PropTypes.node.isRequired,
  clearAllProgramsForPartner: PropTypes.func.isRequired,
  fetchAllProgramsForPartner: PropTypes.func.isRequired,
}
const defaultProps = {}

function AllProgramsProvider({
  account,
  allPrograms,
  children,
  clearAllProgramsForPartner,
  fetchAllProgramsForPartner,
}) {
  const { sfid: partnerAccountId } = account

  useEffect(() => {
    fetchAllProgramsForPartner(partnerAccountId)

    return () => clearAllProgramsForPartner()
  }, [])

  if (!allPrograms) return <Spinner />

  return children
}

AllProgramsProvider.propTypes = propTypes
AllProgramsProvider.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    account: globalSelectors.account(state),
    allPrograms: selectors.allPrograms(state),
  }
}

const mapDispatchToProps = {
  clearAllProgramsForPartner: actions.clearAllProgramsForPartner,
}

const mapApiAuthToProps = {
  fetchAllProgramsForPartner: apiActions.fetchAllProgramsForPartner,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(AllProgramsProvider)
