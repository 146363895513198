import React from 'react'
import PropTypes from 'prop-types'
import {
  AppliedDiscountCodeType,
  GIFT_DELIVERY_METHOD,
  ProductPrices,
} from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { COST } from 'config'
import { calculateTotalGiftCost, formatCurrency } from 'utils'

const propTypes = {
  deliveryMethod: PropTypes.string.isRequired,
  discountCodeDetails: AppliedDiscountCodeType,
  giftWrapped: PropTypes.bool.isRequired,
  membershipPrices: ProductPrices.isRequired,
  selectedMembershipType: PropTypes.string.isRequired,
  shippingMethod: PropTypes.string,
  withDetail: PropTypes.bool,
}
const defaultProps = {
  withDetail: false,
}

function GiftInvoice({
  deliveryMethod,
  discountCodeDetails,
  giftWrapped,
  membershipPrices,
  selectedMembershipType,
  shippingMethod,
  withDetail,
}) {
  const giftAndShippingCosts = calculateTotalGiftCost({
    deliveryMethod,
    discountCodeDetails,
    giftWrapped,
    membershipPrices,
    selectedMembershipType,
    shippingMethod,
  })
  const {
    originalMembershipCost,
    discountedMembershipCost,
    shippingCost,
    totalCost,
  } = giftAndShippingCosts

  return (
    <>
      <div className="invoice-summary">
        <div className="invoice-item">
          <img src="/assets/images/imagery/ST_Icon_membercard.svg" alt="" />
          <p className="title">{selectedMembershipType} Membership</p>
          <div className="cost">
            {originalMembershipCost !== discountedMembershipCost && (
              <p className="strikethrough">
                {formatCurrency(originalMembershipCost)}
              </p>
            )}
            <p>{formatCurrency(discountedMembershipCost)}</p>
          </div>
        </div>
        {deliveryMethod === GIFT_DELIVERY_METHOD.MAILED && (
          <>
            <div className="invoice-item">
              <img src="/assets/images/imagery/ST_Icon_shipping.svg" alt="" />
              <p className="title">Packet Shipping and Handling</p>
              <p className="cost">{formatCurrency(shippingCost)}</p>
            </div>
            {giftWrapped && (
              <div className="invoice-item">
                <img
                  src="/assets/images/imagery/ST_Icon_gift-wrap.svg"
                  alt=""
                />
                <p className="title">Gift Wrapping</p>
                <p className="cost">{formatCurrency(COST.GIFT_WRAPPING)}</p>
              </div>
            )}
          </>
        )}
        {withDetail && (
          <>
            <div className="invoice-item total">
              <p className="title">Total:</p>
              <p className="cost">{formatCurrency(totalCost)}</p>
            </div>
          </>
        )}
      </div>
    </>
  )
}

GiftInvoice.propTypes = exact(propTypes)
GiftInvoice.defaultProps = defaultProps

export default pure(GiftInvoice)
