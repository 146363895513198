import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {}
const defaultProps = {}

function PostLogin() {
  return null
}

PostLogin.propTypes = exact(propTypes)
PostLogin.defaultProps = defaultProps

export default pure(PostLogin)
