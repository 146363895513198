import React from 'react'
import PropTypes from 'prop-types'
import { OFFER_CATEGORY } from 'types'
import { difference } from 'lodash'
import CategoryCard from './CategoryCard'
import { makeMemberPortalPath, PATH, QUERY_KEY } from 'config'
import { makeQueryPath } from 'utils'

const propTypes = {
  categoriesVisible: PropTypes.bool.isRequired,
}
const defaultProps = {}

function CategoryCards({ categoriesVisible }) {
  const primaryCategories = [
    OFFER_CATEGORY.FUEL,
    OFFER_CATEGORY.FISHING,
    OFFER_CATEGORY.EQUIPMENT_AND_SUPPLIES,
    OFFER_CATEGORY.MARINAS_DOCKAGE_AND_STORAGE,
    OFFER_CATEGORY.SAFETY,
  ]

  const additionalCategories = difference(
    Object.values(OFFER_CATEGORY),
    primaryCategories
  )

  return (
    <>
      <OfferCategory categories={primaryCategories} />
      {categoriesVisible && <OfferCategory categories={additionalCategories} />}
    </>
  )
}

CategoryCards.propTypes = propTypes
CategoryCards.defaultProps = defaultProps

export default CategoryCards

function OfferCategory({ categories }) {
  return categories.map((category) => (
    <CategoryCard
      key={category}
      savingsClubCategoryPath={makeQueryPath(
        makeMemberPortalPath(PATH.SAVINGS_CLUB),
        {
          [QUERY_KEY.CATEGORY]: category,
        }
      )}
      savingsClubCategoryType={category}
    />
  ))
}
