import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as memberActions from 'member-actions'

const propTypes = {
  clearShoppingCart: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function MemberApplicationReset({ clearShoppingCart, children }) {
  useEffect(() => {
    clearShoppingCart()
  }, [])

  return children
}

MemberApplicationReset.propTypes = propTypes
MemberApplicationReset.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  clearShoppingCart: memberActions.clearShoppingCart,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MemberApplicationReset
)
