import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { LinkButton } from 'components'
import { useToggle } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
  notificationCount: PropTypes.number.isRequired,
}
const defaultProps = {}

function NotificationCenter({ children, notificationCount }) {
  if (notificationCount === 0) return null

  const [notificationsDisplayed, toggleNotificationsDisplayed] = useToggle(true)

  return (
    <section className="notifications">
      <header>
        <h2>Notifications ({notificationCount})</h2>
        <LinkButton
          className="link-secondary"
          onClick={toggleNotificationsDisplayed}
        >
          ({notificationsDisplayed ? 'Collapse' : 'Expand'})
        </LinkButton>
      </header>
      {notificationsDisplayed && (
        <ul>
          <li className="default">
            <div className="text-block">
              {notificationCount > 0 ? children : <h3>No notifications</h3>}
            </div>
          </li>
        </ul>
      )}
    </section>
  )
}

NotificationCenter.propTypes = exact(propTypes)
NotificationCenter.defaultProps = defaultProps

export default pure(NotificationCenter)
