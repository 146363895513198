import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isEqual, isNil, omit } from 'lodash'
import { Spinner } from 'lp-components'
import { SavingsClubSearchResults } from '../components'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { OPTIONAL_SAVINGS_CLUB_SEARCH_KEYS } from 'config'
import { SavingsClubOfferType, SearchCriteriaType } from 'types'
import {
  pagingParametersFromQuery,
  searchParametersFromQuery,
  parseQuery,
  withApiAuth,
} from 'utils'

const propTypes = {
  currentPage: PropTypes.number,
  resetSearch: PropTypes.func.isRequired,
  searchCriteria: SearchCriteriaType.isRequired,
  searchResults: PropTypes.arrayOf(SavingsClubOfferType),
  searchSavingsClub: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setSearchCriteria: PropTypes.func.isRequired,
}
const defaultProps = {}

function SavingsClub({
  currentPage,
  resetSearch,
  searchCriteria,
  searchResults,
  searchSavingsClub,
  setCurrentPage,
  setSearchCriteria,
}) {
  const { search } = useLocation()

  // Process the query parameters to determine if:
  //  --  We've never done a search before. If so, trigger a search API call with
  //      the current search criteria.
  //  --  The search-related parameters have changed. If so, update the search
  //      criteria and trigger a search API call. Note that every new API call will
  //      require the pagination to begin at the initial (first) page so the
  //      pagination parameters are also updated in this case.
  //  --  The pagination parameters have changed. If so, update the pagination
  //      criteria, which will trigger the next page of results to be displayed.

  useEffect(() => {
    const query = parseQuery(search)
    const searchParameters = searchParametersFromQuery(query)
    const page = pagingParametersFromQuery(query)
    const newSearchCriteria = {
      // Remove optional keys when creating the new search criteria in
      // case such keys were removed from the query parameters...
      ...omit(searchCriteria, OPTIONAL_SAVINGS_CLUB_SEARCH_KEYS),
      ...searchParameters,
    }

    if (isNil(searchResults) || !isEqual(searchCriteria, newSearchCriteria)) {
      resetSearch()
      setCurrentPage(1)
      setSearchCriteria(newSearchCriteria)
      searchSavingsClub(newSearchCriteria)
    }

    if (!isEqual(currentPage, page)) {
      setCurrentPage(page)
    }
  }, [search])

  if (!searchResults) return <Spinner />

  return (
    <SavingsClubSearchResults
      results={searchResults}
      {...{ currentPage, searchCriteria }}
    />
  )
}

SavingsClub.propTypes = propTypes
SavingsClub.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentPage: selectors.currentPage(state),
    searchCriteria: selectors.searchCriteria(state),
    searchResults: selectors.searchResults(state),
  }
}

const mapDispatchToProps = {
  resetSearch: actions.resetSearch,
  setCurrentPage: actions.setCurrentPage,
  setSearchCategory: actions.setSearchCategory,
  setSearchCriteria: actions.setSearchCriteria,
}

const mapApiAuthToProps = {
  searchSavingsClub: apiActions.searchSavingsClub,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(SavingsClub)
