import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { Spinner } from 'lp-components'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { AllMembershipsType } from 'types'
import { USER_METADATA } from 'config'
import { withApiAuth } from 'utils'

const propTypes = {
  allMemberships: AllMembershipsType,
  children: PropTypes.node.isRequired,
  clearAllMemberships: PropTypes.func.isRequired,
  fetchAllMembershipsForAccount: PropTypes.func.isRequired,
}
const defaultProps = {}

function AllMembershipsProvider({
  allMemberships,
  children,
  clearAllMemberships,
  fetchAllMembershipsForAccount,
}) {
  const { user } = useAuth0()
  const memberAccountId = user[USER_METADATA.MEMBER_ACCOUNT_ID]

  useEffect(() => {
    fetchAllMembershipsForAccount(memberAccountId)

    return () => clearAllMemberships()
  }, [])

  if (!allMemberships) return <Spinner />

  return children
}

AllMembershipsProvider.propTypes = propTypes
AllMembershipsProvider.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    allMemberships: selectors.allMemberships(state),
  }
}

const mapDispatchToProps = {
  clearAllMemberships: actions.clearAllMemberships,
}

const mapApiAuthToProps = {
  fetchAllMembershipsForAccount: apiActions.fetchAllMembershipsForAccount,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(AllMembershipsProvider)
