import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import BackLink from './BackLink'

const propTypes = {
  backLinkText: PropTypes.string,
  backLinkTo: PropTypes.string,
  sectionHeaderName: PropTypes.string.isRequired,
  sectionDescription: PropTypes.string,
}
const defaultProps = {}

function SectionHeader({
  backLinkText,
  backLinkTo,
  sectionHeaderName,
  sectionDescription,
}) {
  return (
    <header className="secondary-header">
      {backLinkTo && <BackLink linkText={backLinkText} to={backLinkTo} />}
      <h2>{sectionHeaderName}</h2>
      {sectionDescription && <p>{sectionDescription}</p>}
    </header>
  )
}

SectionHeader.propTypes = exact(propTypes)
SectionHeader.defaultProps = defaultProps

export default pure(SectionHeader)
