import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { isNil } from 'lodash'
import {
  Field,
  SubmissionError,
  formValues,
  propTypes as formPropTypes,
} from 'redux-form'
import { lpForm } from 'lp-form'
import { SubmitButton, Input } from 'lp-components'
import { BackLink } from 'components'
import { HomePortAddress } from '../components'

const propTypes = {
  homePortLocality: PropTypes.object.isRequired,
  previousFormStep: PropTypes.object.isRequired,
  ...formPropTypes,
}
const defaultProps = {}

function LocatePortForm({
  homePortLocality,
  homePortName,
  handleSubmit,
  previousFormStep,
  submitting,
}) {
  const { city, country, streetNumber, state, street } = homePortLocality
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Field
        name="homePortName"
        label="Name/Description"
        component={Input}
        required
        requiredIndicator="*"
      />
      <div>
        {city && (
          <div className="read-only">
            <h3>Home Port Location</h3>
            <HomePortAddress
              address={streetNumber ? `${streetNumber} ${street}` : street}
              city={city}
              country={country}
              name={homePortName}
              state={state}
            />
          </div>
        )}

        <div className="button-navigation">
          <BackLink linkText="Back" to={previousFormStep} />
          <SubmitButton {...{ submitting }}>
            Confirm & Add Home Port
          </SubmitButton>
        </div>
      </div>
    </form>
  )
}

LocatePortForm.propTypes = propTypes
LocatePortForm.defaultProps = defaultProps

const beforeSubmit = (formValues, { homePortLocality }) => {
  const { city } = homePortLocality

  if (isNil(city)) {
    throw new SubmissionError({
      _error: 'Move your marker to find the closest home port city.',
    })
  }

  return formValues
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'LocatePortForm',
    constraints: {
      homePortName: { presence: { allowEmpty: false } },
    },
  }),
  formValues('homePortName')
)(LocatePortForm)
