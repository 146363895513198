import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { isNil } from 'lodash'
import { Spinner } from 'lp-components'
import { AccountProvider } from 'global-account/components'
import { NotAProgramsParticipant } from 'programs-portal/components'
import { USER_METADATA } from 'config'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { withApiAuth } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
  clearPermanentAccountId: PropTypes.func.isRequired,
  swapMetadata: PropTypes.func.isRequired,
  permanentAccountId: PropTypes.string,
}
const defaultProps = {}

// Partner accounts either have a valid account id,
// a temporary account id, or no account id.
// If the account id is valid, use it. Otherwise, if a temporary
// account id exists, attempt to exchange it for a permanent account id using the
// swapMetadata() API call. This API will return a valid permanent participant account id if
// successful or an invalid account id if not.
// Otherwise, if no account id is associated with the user's Auth0 account,
// inform the user that they are not a valid participant via the "NotAProgramsParticipant" component.
function PartnerAccountProvider({
  children,
  clearPermanentAccountId,
  swapMetadata,
  permanentAccountId,
}) {
  const { user } = useAuth0()
  const partnerAccountId = user[USER_METADATA.PARTNER_ACCOUNT_ID]
  const temporaryPartnerAccountId =
    user[USER_METADATA.TEMPORARY_PARTNER_ACCOUNT_ID]
  const validPartnerAccountId =
    partnerAccountId ?? verifiedPartnerAccountId(permanentAccountId)

  useEffect(() => {
    if (temporaryPartnerAccountId) {
      const { sub: auth0_id } = user

      swapMetadata({
        auth0_id,
        account_uuid: temporaryPartnerAccountId,
      })

      return () => clearPermanentAccountId()
    }
  }, [])

  if (validPartnerAccountId) {
    return (
      <AccountProvider accountId={validPartnerAccountId}>
        {children}
      </AccountProvider>
    )
  } else if (temporaryPartnerAccountId && isNil(permanentAccountId)) {
    return <Spinner />
  } else {
    return <NotAProgramsParticipant />
  }
}

PartnerAccountProvider.propTypes = propTypes
PartnerAccountProvider.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    permanentAccountId: selectors.permanentAccountId(state),
  }
}

const mapDispatchToProps = {
  clearPermanentAccountId: actions.clearPermanentAccountId,
}

const mapApiAuthToProps = {
  swapMetadata: apiActions.swapMetadata,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(PartnerAccountProvider)

function verifiedPartnerAccountId(accountId) {
  if (isNil(accountId)) return
  if (accountId === USER_METADATA.INVALID_ACCOUNT_ID) return

  return accountId
}
