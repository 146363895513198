import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, useLocation } from 'react-router-dom'

const propTypes = {
  to: PropTypes.string.isRequired,
  search: PropTypes.string,
}
const defaultProps = {
  search: null,
}

function RedirectWithQueryParams({ to, search }) {
  const location = useLocation()
  return (
    <Redirect
      to={{
        pathname: to,
        search: search || location.search,
      }}
    />
  )
}

RedirectWithQueryParams.propTypes = propTypes
RedirectWithQueryParams.defaultProps = defaultProps

export default React.memo(RedirectWithQueryParams)
