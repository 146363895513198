import React from 'react'
import PropTypes from 'prop-types'
import Contact from './Contact'
import { Link } from 'react-router-dom'
import { ContactArrayType, isMemberAccountType } from 'types'
import {
  QUERY_KEY,
  PATH,
  makeMemberPortalPath,
  makeProgramsPortalPath,
} from 'config'
import { makeQueryPath } from 'utils'

const propTypes = {
  accountId: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
  backLink: PropTypes.string.isRequired,
  contacts: ContactArrayType.isRequired,
  country: PropTypes.string.isRequired,
  deleteContact: PropTypes.func.isRequired,
  fetchContacts: PropTypes.func.isRequired,
  updateContact: PropTypes.func.isRequired,
}

const defaultProps = {}

function Contacts({
  accountId,
  accountType,
  backLink,
  contacts,
  country,
  updateContact,
  fetchContacts,
  deleteContact,
}) {
  const newContactPath = isMemberAccountType(accountType)
    ? makeMemberPortalPath(PATH.ACCOUNT, PATH.NEW)
    : makeProgramsPortalPath(PATH.ACCOUNT, PATH.NEW)

  return (
    <div className="card">
      <header>
        <img
          src="/assets/images/icons/with-background/ST_YIcon_M-Contacts.svg"
          alt=""
          className="icon"
        />
        <h2>Manage Contact & Communication Preferences</h2>
      </header>
      <div className="card-inner">
        {contacts.map((contact) => {
          return (
            <Contact
              key={contact.id}
              accountType={accountType}
              contact={contact}
              country={country}
              updateContact={updateContact}
              fetchContacts={fetchContacts}
              deleteContact={deleteContact}
            />
          )
        })}
        <Link
          className="button-primary-outline plus-left-button-icon"
          to={makeQueryPath(newContactPath, {
            [QUERY_KEY.ACCOUNT_ID]: accountId,
            [QUERY_KEY.ACCOUNT_TYPE]: accountType,
            [QUERY_KEY.BACK]: backLink,
            [QUERY_KEY.COUNTRY]: country,
          })}
        >
          Add New Contact
        </Link>
      </div>
    </div>
  )
}

Contacts.propTypes = propTypes
Contacts.defaultProps = defaultProps

export default Contacts
