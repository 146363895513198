import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ButtonLink } from 'components'
import { COST, PATH, makeMemberPortalPath } from 'config'
import { formatCurrency } from 'utils'

const propTypes = {
  credits: PropTypes.number.isRequired,
}
const defaultProps = {}

function Referrals({ credits }) {
  return (
    <>
      <div className="card summary">
        <header>
          <img
            src="/assets/images/icons/with-background/ST_YIcon_Referral.svg"
            alt=""
            className="icon"
          />
          <p>My Referral Credits</p>
          <p className="summary-data">{formatCurrency(credits, 0)}</p>
        </header>
      </div>
      <div className="card image-card">
        <div className="image-block">
          <img src="/assets/images/imagery/refer.jpg" alt="" />
        </div>
        <h2>Refer a Boater & Earn {COST.MEMBERSHIP_RENEWAL_CREDIT}!</h2>
        <p>
          Get {COST.MEMBERSHIP_RENEWAL_CREDIT} off your membership renewal for
          each new Sea Tow member you refer.
        </p>
        <ButtonLink
          className="button-primary"
          to={makeMemberPortalPath(PATH.REFERRALS)}
        >
          Refer Boater
        </ButtonLink>
      </div>
    </>
  )
}

Referrals.propTypes = exact(propTypes)
Referrals.defaultProps = defaultProps

export default pure(Referrals)
