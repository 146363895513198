import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { PageHeader } from 'components'
import {
  Memberships,
  MembershipNotificationCenter,
  SidePanel,
} from '../components'
import { SavingsClubDirectory } from 'savings-club/components'
import { AccountType, AllMembershipsType } from 'types'

const propTypes = {
  account: AccountType.isRequired,
  allMemberships: AllMembershipsType,
  updateMembershipAssociatedAccount: PropTypes.func.isRequired,
}
const defaultProps = {}

function Dashboard({
  account,
  allMemberships,
  updateMembershipAssociatedAccount,
}) {
  const { user } = useAuth0()
  const { name } = account
  const { memberships } = allMemberships
  const membershipNumber = memberships[0].membership_number__c

  useEffect(() => {
    if (membershipNumber && user) {
      updateMembershipAssociatedAccount(user.email, membershipNumber)
    }
  }, [])

  return (
    <>
      <PageHeader headerText={`Welcome back, ${name}`} />
      <div className="dashboard-block-container">
        <MembershipNotificationCenter memberships={memberships} />
        <div className="content-with-aside-wrapper">
          <div className="content-with-aside">
            <Memberships />
            <SavingsClubDirectory memberships={memberships} />
          </div>
          <SidePanel account={account} />
        </div>
      </div>
    </>
  )
}

Dashboard.propTypes = propTypes
Dashboard.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    account: selectors.account(state),
    allMemberships: selectors.allMemberships(state),
  }
}

const mapDispatchToProps = {
  updateMembershipAssociatedAccount:
    apiActions.updateMembershipAssociatedAccount,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboard)
