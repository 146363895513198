import React from 'react'
import PropTypes from 'prop-types'
import {
  MEMBERSHIP_DETAIL,
  AppliedDiscountCodeType,
  ProductPrices,
  MembershipType,
} from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { DiscountCode } from 'member-portal/components'
import { capitalize } from 'lodash'
import {
  calculateFutureMembershipExpirationDate,
  calculateDiscountedCost,
  calculateDiscountDollarsOff,
  calculateReferralCreditsUsed,
  formatCurrency,
} from 'utils'

const propTypes = {
  allMembershipsPath: PropTypes.string,
  basePath: PropTypes.string.isRequired,
  clearDiscountCodeStateAndLS: PropTypes.func.isRequired,
  discountCodeDetails: AppliedDiscountCodeType,
  isRenewingMembership: PropTypes.bool,
  isTrialMembershipsActive: PropTypes.bool,
  membershipPrices: ProductPrices.isRequired,
  membershipToRenew: MembershipType,
  promoCodeIsTrial: PropTypes.bool,
  referralCredits: PropTypes.number,
  selectedMembershipType: PropTypes.string.isRequired,
  trialDuration: PropTypes.number,
}

const defaultProps = {
  isRenewingMembership: false,
  referralCredits: 0,
}

function MembershipDetails({
  basePath,
  clearDiscountCodeStateAndLS,
  discountCodeDetails,
  isRenewingMembership,
  isTrialMembershipsActive,
  membershipPrices,
  membershipToRenew,
  promoCodeIsTrial,
  referralCredits,
  selectedMembershipType,
  trialDuration,
}) {
  const originalCost = membershipPrices[selectedMembershipType]
  const discountedCost = calculateDiscountedCost(
    originalCost,
    discountCodeDetails
  )

  return (
    <div className="group-block">
      <div className="product-block">
        <img src={MEMBERSHIP_DETAIL[selectedMembershipType].image} alt="" />

        <div className="product-block-inner">
          <div className="product-details main-product-details">
            <div className="product-details-block">
              <div className="text-block">
                <h3>
                  {promoCodeIsTrial && isTrialMembershipsActive
                    ? `${selectedMembershipType} - ${trialDuration} days`
                    : selectedMembershipType}
                </h3>
                <ul>
                  {MEMBERSHIP_DETAIL[selectedMembershipType].coverage && (
                    <li>
                      Coverage:{' '}
                      <em>
                        {MEMBERSHIP_DETAIL[selectedMembershipType].coverage}
                      </em>
                    </li>
                  )}
                  {MEMBERSHIP_DETAIL[selectedMembershipType].usedIn && (
                    <li>
                      Used In:{' '}
                      <em>
                        {MEMBERSHIP_DETAIL[selectedMembershipType].usedIn}
                      </em>
                    </li>
                  )}
                  <li>
                    Boat Use:{' '}
                    <em>{MEMBERSHIP_DETAIL[selectedMembershipType].boatUse}</em>
                  </li>
                </ul>
              </div>
              <div className="price-block">
                <p className="price">
                  <span>
                    {promoCodeIsTrial && isTrialMembershipsActive
                      ? formatCurrency(0)
                      : formatCurrency(originalCost)}
                  </span>
                  {/* For every use case except when promo code is Trial Memberships and the Trial Memberships feature is active, show the span */}
                  {promoCodeIsTrial && isTrialMembershipsActive ? (
                    ''
                  ) : (
                    <span className="time"> / Year</span>
                  )}
                </p>
              </div>
            </div>
            {/* If the Discount Code Details has a value and the Promo Code is Trial Memberships (and the Trial Memberships feature is set to active), then don't render anything. Otherwise as long as we have the Discount Code Details, then render the Promo Code Block */}
            {discountCodeDetails && promoCodeIsTrial && isTrialMembershipsActive
              ? ''
              : discountCodeDetails && (
                  <div className="product-details-block promo-code-block">
                    <div className="text-block">
                      <h4>{`${capitalize(discountCodeDetails.type)} Code`}</h4>
                    </div>
                    <div className="price-block">
                      <p className="price">
                        <span>{`- ${formatCurrency(
                          calculateDiscountDollarsOff(
                            originalCost,
                            discountCodeDetails
                          )
                        )}`}</span>
                      </p>
                    </div>
                  </div>
                )}
          </div>
          <p className="description">
            {promoCodeIsTrial && isTrialMembershipsActive
              ? discountCodeDetails?.description
              : MEMBERSHIP_DETAIL[selectedMembershipType].description}
          </p>
          <ul>
            {isRenewingMembership && (
              <>
                <li>
                  <strong>Current membership expiration date:</strong>{' '}
                  <em>{membershipToRenew.membership_expiration_date__c}</em>
                </li>
                <li>
                  <strong>Expiration date after renewal will be:</strong>{' '}
                  <em>
                    {calculateFutureMembershipExpirationDate(
                      membershipToRenew.membership_expiration_date__c,
                      1
                    )}
                  </em>
                </li>
              </>
            )}
            {isRenewingMembership && (
              <li>
                <strong>New member referral credits applied:</strong>{' '}
                <em>
                  {formatCurrency(
                    calculateReferralCreditsUsed(
                      discountedCost,
                      referralCredits
                    )
                  )}
                </em>
              </li>
            )}
          </ul>
        </div>
      </div>
      <DiscountCode
        formName="MembershipDetailsDiscount"
        {...{
          basePath,
          clearDiscountCodeStateAndLS,
          discountCodeDetails,
        }}
        // If Trial Membeship is Active, set the following prop
        {...(isTrialMembershipsActive && {
          promoCodeIsTrial,
        })}
      />
    </div>
  )
}

MembershipDetails.propTypes = propTypes
MembershipDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
  }
}

export default compose(connect(mapStateToProps, null))(MembershipDetails)
