import React from 'react'
import PropTypes from 'prop-types'
import { DISCOUNT_CODE_TYPE } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { lpForm } from 'lp-form'
import {
  Field,
  formValues,
  propTypes as formPropTypes,
  SubmissionError,
} from 'redux-form'
import { Checkbox, Input, SubmitButton } from 'lp-components'
import classnames from 'classnames'

const propTypes = {
  ...formPropTypes,
  appliedDiscountCodeDescription: PropTypes.string,
  clearDiscountCode: PropTypes.func.isRequired,
  discountCodeDetails: PropTypes.object,
  codeFieldType: PropTypes.string,
  isTrialMembershipsActive: PropTypes.bool,
  promoCodeIsTrial: PropTypes.bool,
  showDiscountCodeFields: PropTypes.bool,
}

const defaultProps = {
  showDiscountCodeFields: false,
  codeFieldType: '',
}

function DiscountCodeForm({
  appliedDiscountCodeDescription,
  clearDiscountCode,
  codeFieldType,
  discountCodeDetails,
  handleSubmit,
  isTrialMembershipsActive,
  pristine,
  promoCodeIsTrial,
  showDiscountCodeFields,
  submitting,
}) {
  const hasNoCodeApplied = !codeFieldType
  const { isPromoCodeHidden } = discountCodeDetails || {}

  return (
    <form onSubmit={handleSubmit} noValidate className="inline-form">
      <Field
        component={Checkbox}
        disabled={isTrialMembershipsActive && promoCodeIsTrial}
        label="Promo or Activation Code?"
        name="showDiscountCodeFields"
        onChange={clearDiscountCode}
      />
      {showDiscountCodeFields && (
        <>
          {!isPromoCodeHidden && (
            <div className="row">
              {(hasNoCodeApplied ||
                codeFieldType === DISCOUNT_CODE_TYPE.PROMO) && (
                  <div className="col-6">
                    <Field
                      component={Input}
                      disabled={isTrialMembershipsActive && promoCodeIsTrial}
                      label="Promo Code"
                      name="promoCode"
                      validate={onlyOneDiscountAllowed}
                    />
                  </div>
                )}
              {(hasNoCodeApplied ||
                codeFieldType === DISCOUNT_CODE_TYPE.ACTIVATION) && (
                  <div className="col-6">
                    <Field
                      component={Input}
                      label="Activation Code"
                      name="activationCode"
                      validate={onlyOneDiscountAllowed}
                    />
                  </div>
                )}
            </div>
          )}
          {appliedDiscountCodeDescription && (
            <ul className="with-checkmark-list">
              <li>{appliedDiscountCodeDescription}</li>
            </ul>
          )}
        </>
      )}
      {/* Submit button is required when a form exists so it will be
      hidden and disabled when the other fields aren't displayed */}
      <div
        className={classnames({ 'visually-hidden': !showDiscountCodeFields })}
      >
        <SubmitButton
          {...{ pristine, submitting }}
          disabled={pristine || !showDiscountCodeFields}
        >
          Apply
        </SubmitButton>
      </div>
    </form>
  )
}

const beforeSubmit = (formValues, { isRecreationalCard }) => {
  const { activationCode } = formValues
  const hasActivationCode = !!activationCode

  if (hasActivationCode && !isRecreationalCard) {
    throw new SubmissionError({
      _error:
        'To use your Activation Code for this membership type please call 800-4-SEATOW (800-473-2869)',
    })
  }

  return formValues
}

function mapStateToProps(state) {
  return {
    discountCodeDetails: memberSelectors.discountCodeDetails(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
  }
}

DiscountCodeForm.propTypes = propTypes
DiscountCodeForm.defaultProps = defaultProps

export default compose(
  lpForm({
    beforeSubmit,
    enableReinitialize: true,
  }),
  formValues('showDiscountCodeFields'),
  connect(mapStateToProps, null),
)(DiscountCodeForm)

function onlyOneDiscountAllowed(discountCode, formValues) {
  if (!discountCode) return
  if (formValues.activationCode && formValues.promoCode)
    return 'Only one discount code can be applied'
}
