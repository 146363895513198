import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { scrollToTop } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function Layout({ children }) {
  const { search } = useLocation()

  // Scroll to top of page when search string changes
  useEffect(() => {
    scrollToTop()
  }, [search])

  return <div>{children}</div>
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default Layout
