import React from 'react'
import PropTypes from 'prop-types'
import {
  AccountType,
  AppliedDiscountCodeType,
  ProductPrices,
  MEMBERSHIP_TYPE,
  MEMBERSHIP_TYPES_WITH_TRAILER_CARE,
  MembershipType,
} from 'types'
import exact from 'prop-types-exact'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { SectionHeader, ButtonLink } from 'components'
import { MembershipDetails, TrailerCareAddOn } from 'member-portal/components'
import { LakeToGoldUpgradeBox } from '../components'
import * as memberActions from 'member-actions'
import { selectors as memberSelectors } from 'member-reducer'
import { PATH, makeMembershipIdPath } from 'config'

const propTypes = {
  account: AccountType.isRequired,
  clearDiscountCodeStateAndLS: PropTypes.func.isRequired,
  discountCodeDetails: AppliedDiscountCodeType,
  lakeGoldPriceDifference: PropTypes.number.isRequired,
  membershipPrices: ProductPrices.isRequired,
  membershipToRenew: MembershipType.isRequired,
  selectedMembershipType: PropTypes.string.isRequired,
  selectedTrailerCareType: PropTypes.string.isRequired,
  setSelectedMembershipType: PropTypes.func.isRequired,
  setSelectedTrailerCareType: PropTypes.func.isRequired,
  trailerCarePrices: ProductPrices.isRequired,
}

const defaultProps = {}

function RenewalSelectedMembershipType({
  account,
  setSelectedTrailerCareType,
  setSelectedMembershipType,
  selectedMembershipType,
  membershipPrices,
  trailerCarePrices,
  lakeGoldPriceDifference,
  selectedTrailerCareType,
  clearDiscountCodeStateAndLS,
  discountCodeDetails,
  membershipToRenew,
}) {
  const { pathname } = useLocation()
  const { referral_credits__c } = account

  // Trial memberships are not "renewed" per se. Rather, the trial membership
  // is converted to an actual paid Sea Tow membership type. Determine if a
  // trial membership is being renewed and redirect to allow the user to
  // select their new membership type. Continue to redirect until
  // a non-trial membership type has been selected (selectedMembershipType).
  const { membership_type__c: membershipTypeToRenew } = membershipToRenew
  if (
    membershipTypeToRenew === MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP &&
    selectedMembershipType === MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP
  ) {
    return (
      <Redirect
        to={makeMembershipIdPath(
          membershipToRenew.sfid,
          PATH.RENEW_MEMBERSHIP,
          PATH.RENEWAL_QUESTIONNAIRE
        )}
      />
    )
  }

  return (
    <section className="form-block-container">
      <header className="header-title">
        <h1>Renew Membership</h1>
      </header>
      <div className="card card-medium">
        <div className="card-inner">
          <div className="form-block">
            <SectionHeader sectionHeaderName="Selected Sea Tow Membership" />
            <MembershipDetails
              basePath={pathname}
              {...{
                clearDiscountCodeStateAndLS,
                discountCodeDetails,
                membershipPrices,
                membershipToRenew,
                selectedMembershipType,
              }}
              isRenewingMembership
              referralCredits={referral_credits__c}
            />
            {membershipToRenew.membership_type__c ===
              MEMBERSHIP_TYPE.LAKE_CARD && (
              <LakeToGoldUpgradeBox
                checked={selectedMembershipType === MEMBERSHIP_TYPE.GOLD_CARD}
                priceDifference={lakeGoldPriceDifference}
                toggleMembershipType={(checked) => {
                  const newType = checked
                    ? MEMBERSHIP_TYPE.GOLD_CARD
                    : MEMBERSHIP_TYPE.LAKE_CARD
                  setSelectedMembershipType(newType)
                }}
              />
            )}
            {MEMBERSHIP_TYPES_WITH_TRAILER_CARE.includes(
              selectedMembershipType
            ) && (
              <>
                <hr />
                <TrailerCareAddOn
                  {...{
                    selectedTrailerCareType,
                    setSelectedTrailerCareType,
                    trailerCarePrices,
                  }}
                />
              </>
            )}
          </div>
          <div className="button-group">
            <ButtonLink
              to={makeMembershipIdPath(
                membershipToRenew.sfid,
                PATH.RENEW_MEMBERSHIP,
                PATH.CONTACT
              )}
              className="button-primary"
            >
              Continue
            </ButtonLink>
          </div>
        </div>
      </div>
    </section>
  )
}

RenewalSelectedMembershipType.propTypes = exact(propTypes)
RenewalSelectedMembershipType.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    account: memberSelectors.account(state),
    discountCodeDetails: memberSelectors.discountCodeDetails(state),
    lakeGoldPriceDifference: memberSelectors.lakeGoldPriceDifference(state),
    membershipPrices: memberSelectors.membershipPrices(state),
    membershipToRenew: memberSelectors.membership(state),
    selectedMembershipType: memberSelectors.selectedMembershipType(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
    trailerCarePrices: memberSelectors.trailerCarePrices(state),
  }
}

const mapDispatchToProps = {
  clearDiscountCodeStateAndLS: memberActions.clearDiscountCodeStateAndLS,
  setSelectedTrailerCareType: memberActions.setSelectedTrailerCareType,
  setSelectedMembershipType: memberActions.setSelectedMembershipType,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RenewalSelectedMembershipType
)
