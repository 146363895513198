import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
const defaultProps = {
  className: 'link-primary',
}

// A button that is styled to appear as a link...
function LinkButton({ children, className, ...rest }) {
  return (
    <button className={className} {...rest}>
      {children}
    </button>
  )
}

LinkButton.propTypes = propTypes
LinkButton.defaultProps = defaultProps

export default pure(LinkButton)
