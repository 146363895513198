import React from 'react'
import PropTypes from 'prop-types'
import {
  AppliedDiscountCodeType,
  BillingType,
  ContactType,
  MembershipType,
  ProductPrices,
  ReferralType,
  TRAILER_CARE_DETAIL,
} from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { useHistory } from 'react-router-dom'
import { ExternalLink, SmsNotificationLabel } from 'components'
import { Button, Checkbox, SubmitButton } from 'lp-components'
import { lpForm } from 'lp-form'
import { HomePortAddress } from 'memberships/boats/components'
import { first, isNil } from 'lodash'
import {
  makePath,
  makeMemberApplicationPath,
  makeMemberApplicationApplyPath,
  PATH,
} from 'config'
import { formatCurrency, formatPhoneNumber } from 'utils'

const propTypes = {
  ...formPropTypes,
  billing: BillingType.isRequired,
  contact: ContactType.isRequired,
  canEditBoat: PropTypes.bool.isRequired,
  canEditContactInfo: PropTypes.bool.isRequired,
  canEditProductSelections: PropTypes.bool.isRequired,
  discountCodeDetails: AppliedDiscountCodeType,
  isReferralMatch: PropTypes.bool,
  isTrialMembershipsActive: PropTypes.bool,
  membership: MembershipType.isRequired,
  membershipPrices: ProductPrices.isRequired,
  paymentPath: PropTypes.string.isRequired,
  promoCodeIsTrial: PropTypes.bool,
  referralCreditsUsed: PropTypes.number.isRequired,
  referralInfo: ReferralType,
  referredByMemberNumber: PropTypes.string,
  selectedMembershipType: PropTypes.string.isRequired,
  selectedTrailerCareType: PropTypes.string.isRequired,
  showMembershipCost: PropTypes.bool.isRequired,
  showTrailerCareCost: PropTypes.bool.isRequired,
  trailerCarePrices: ProductPrices.isRequired,
  trialDuration: PropTypes.number,
}
const defaultProps = {}

function ReviewOrderForm({
  billing,
  contact,
  canEditBoat,
  canEditContactInfo,
  canEditProductSelections,
  discountCodeDetails,
  handleSubmit,
  isReferralMatch,
  isTrialMembershipsActive,
  membership,
  membershipPrices,
  paymentPath,
  promoCodeIsTrial,
  referralCreditsUsed,
  referralInfo,
  referredByMemberNumber,
  selectedMembershipType,
  selectedTrailerCareType,
  showMembershipCost,
  showTrailerCareCost,
  submitting,
  trailerCarePrices,
  trialDuration,
}) {
  const {
    address__c: address,
    home_port_city__c: city,
    home_port_state__c: state,
    home_port_country__c: country,
    marina_name__c: name,
  } = first(membership.boats)

  const {
    cardNumber,
    finalDonation,
    subtotalWithoutDonations,
    account: { postalCode },
  } = billing

  const { name: contactName, email, mobilephone } = contact

  const history = useHistory()

  const originalMembershipCost = membershipPrices[selectedMembershipType]
  const trailerCareCost = trailerCarePrices[selectedTrailerCareType] ?? 0
  const trailerCareDetail =
    trailerCareCost > 0 && TRAILER_CARE_DETAIL[selectedTrailerCareType]

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="invoice-summary group-block">
        <h3 className="group-title">
          Membership Selections{' '}
          {canEditProductSelections && (
            <Button
              className="link-primary"
              onClick={() =>
                history.push(
                  makeMemberApplicationPath(PATH.MEMBERSHIP_TYPE_SELECTED),
                )
              }
              aria-label="Edit Membership Selections"
            >
              (Edit)
            </Button>
          )}
        </h3>
        {showMembershipCost && (
          <div className="invoice-item">
            <img src="/assets/images/imagery/ST_Icon_membercard.svg" alt="" />
            <p className="title">
              {promoCodeIsTrial && isTrialMembershipsActive
                ? `${selectedMembershipType} - ${trialDuration} days`
                : `${selectedMembershipType} Membership`}
            </p>
            <div className="cost">
              <p>
                {promoCodeIsTrial && isTrialMembershipsActive
                  ? formatCurrency(0)
                  : formatCurrency(originalMembershipCost)}
              </p>
            </div>
          </div>
        )}
        {/* If the Discount Code Details has a value and the Promo Code is Trial Memberships (and the Trial Memberships feature is set to active), then don't render anything. Otherwise as long as we have the Discount Code Details, then render the Invoice Item block */}
        {discountCodeDetails && promoCodeIsTrial && isTrialMembershipsActive
          ? ''
          : discountCodeDetails && (
            <div className="invoice-item">
              <img src="/assets/images/imagery/ST_Icon_discount.svg" alt="" />
              <p className="title">{`${discountCodeDetails.type.toUpperCase()}: ${discountCodeDetails.name
                }`}</p>
              <div className="cost">
                <p>
                  {discountCodeDetails.dollarsOff > 0 &&
                    `${formatCurrency(-discountCodeDetails.dollarsOff)}`}
                  {discountCodeDetails.percentOff > 0 &&
                    `(${discountCodeDetails.percentOff}%)`}
                </p>
              </div>
            </div>
          )}

        {referralCreditsUsed > 0 && (
          <div className="invoice-item">
            <img src="/assets/images/imagery/ST_Icon_discount.svg" alt="" />
            <p className="title">Referral Credits Applied</p>
            <div className="cost">
              <p>{formatCurrency(-referralCreditsUsed)}</p>
            </div>
          </div>
        )}
        {showTrailerCareCost && trailerCareCost > 0 && (
          <div className="invoice-item">
            <img src="/assets/images/imagery/ST_Icon_trailer-care.svg" alt="" />
            <p className="title">{trailerCareDetail.marketingTitle}</p>
            <div className="cost">
              <p>{formatCurrency(trailerCareCost)}</p>
            </div>
          </div>
        )}
        {finalDonation > 0 && (
          <div className="invoice-item">
            <img src="/assets/images/imagery/ST_Icon_donation.svg" alt="" />
            <p className="title">Sea Tow Foundation Donation</p>
            <div className="cost">
              <p>{formatCurrency(finalDonation)}</p>
            </div>
          </div>
        )}
        <div className="invoice-item total">
          <p className="title">Total:</p>
          <p className="cost">
            {` ${formatCurrency(
              calculateTotalCost(
                {
                  finalDonation,
                  referralCreditsUsed,
                  subtotalWithoutDonations,
                  trailerCareCost,
                  isTrialMembershipsActive,
                },
                { ...(isTrialMembershipsActive && { promoCodeIsTrial }) },
              ),
            )}`}
          </p>
        </div>
      </div>

      <div className="group-block">
        <h3 className="group-title">
          Contact Info{' '}
          {canEditContactInfo && (
            <Button
              className="link-primary"
              onClick={() =>
                history.push(makeMemberApplicationApplyPath(PATH.CONTACT))
              }
              aria-label="Edit Contact Information"
            >
              (Edit)
            </Button>
          )}
        </h3>

        <div className="read-only">
          <strong>Full Name</strong>
          <p>{contactName}</p>
          <div className="row">
            <div className="col-6">
              <strong>Email</strong>
              <p>{email}</p>
            </div>
            <div className="col-6">
              <strong>Mobile Number</strong>
              <p>{formatPhoneNumber(mobilephone)}</p>
            </div>
          </div>
        </div>
        <div className="group">
          <Field
            name="smsOptIn"
            component={Checkbox}
            labelComponent={(props) => <SmsNotificationLabel {...props} />}
          />
        </div>
      </div>

      {canEditBoat && (
        <div className="group-block">
          <h3 className="group-title">
            Primary Boat Information{' '}
            <Button
              className="link-primary"
              onClick={() =>
                history.push(makeMemberApplicationApplyPath(PATH.BOATS))
              }
              aria-label="Edit Boat"
            >
              (Edit)
            </Button>
          </h3>

          <p>
            <strong>Home Port:</strong>
          </p>
          <HomePortAddress {...{ address, city, state, country, name }} />
        </div>
      )}

      {referredByMemberNumber && (
        <div className="group-block medium-spacing">
          <h3 className="group-title">Referred By</h3>
          <p>
            {isReferralMatch && (
              <>
                {referralInfo.fetchedReferrerMemberName}
                <br />
              </>
            )}
            {referredByMemberNumber}
          </p>
        </div>
      )}

      <div className="group-block medium-spacing">
        <h3 className="group-title">
          Payment{' '}
          <Button
            className="link-primary"
            onClick={() => history.push(paymentPath)}
            aria-label="Edit Payment"
          >
            (Edit)
          </Button>
        </h3>
        {cardNumber ? (
          <div>
            <p>Credit Card Ending in: {getLastFourNumbers(cardNumber)}</p>
            <p>{postalCode}</p>
          </div>
        ) : (
          <p> No Credit Card information provided </p>
        )}
      </div>

      {/* Show this for for everything but trailer care upgrade-only scenario */}
      {showMembershipCost && (
        <div className="group-block">
          <p>
            By clicking confirm you are acknowledging you have read and agree to
            all terms and conditions of the{' '}
            <ExternalLink
              className="external-link-in-text"
              href={makePath(PATH.SEATOW, PATH.MEMBERSHIP_AGREEMENT)}
            >
              Sea Tow Membership Agreement.
            </ExternalLink>{' '}
            I understand this Sea Tow membership is effective 24 hours after
            payment and is non-refundable.
          </p>
        </div>
      )}

      {/* Only show this for trailer care upgrade. In that scenario the
            membership cost would not be shown.
        */}
      {showTrailerCareCost && trailerCareCost > 0 && !showMembershipCost && (
        <div className="group-block">
          <p>
            By clicking below you are acknowledging you have read and agree to
            all the terms and conditions of the{' '}
            <ExternalLink
              className="external-link-in-text"
              href={makePath(PATH.SEATOW, PATH.MEMBERSHIP_AGREEMENT)}
            >
              Sea Tow Trailer Care Agreement.
            </ExternalLink>{' '}
            I understand that this upgrade goes into effect in 24 hours and is
            non-refundable.
          </p>
        </div>
      )}

      <div className="button-group">
        <SubmitButton {...{ submitting }}>Confirm Membership</SubmitButton>
      </div>
    </form>
  )
}

function mapStateToProps(state) {
  return {
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
  }
}

ReviewOrderForm.propTypes = propTypes
ReviewOrderForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'ReviewOrderForm',
  }),
  connect(mapStateToProps, null),
)(ReviewOrderForm)

const getLastFourNumbers = (ccNumber) => {
  if (isNil(ccNumber)) return
  return ccNumber.slice(-4)
}

function calculateTotalCost({
  finalDonation,
  isTrialMembershipsActive,
  promoCodeIsTrial,
  referralCreditsUsed,
  subtotalWithoutDonations,
  trailerCareCost,
}) {
  let membershipCost = subtotalWithoutDonations - trailerCareCost

  if (referralCreditsUsed >= membershipCost) membershipCost = 0

  if (promoCodeIsTrial && isTrialMembershipsActive) membershipCost = 0

  return finalDonation + membershipCost + trailerCareCost - referralCreditsUsed
}
