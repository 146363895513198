import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PATH, makeGiftStepsPath } from 'config'
import { selectors as memberSelectors } from 'member-reducer'
import { BOAT_USE_TYPE, MEMBERSHIP_TYPE, ProductPrices } from 'types'
import { MembershipTypeForm } from 'member-portal/forms'
import * as actions from '../actions'

const propTypes = {
  membershipPrices: ProductPrices.isRequired,
  setSelectedMembershipType: PropTypes.func.isRequired,
}
const defaultProps = {}

function ProductSelection({ membershipPrices, setSelectedMembershipType }) {
  const history = useHistory()
  return (
    <section className="form-block-container">
      <header className="header-title">
        <h1>Sea Tow Member Gift Options</h1>
      </header>
      <div className="card card-medium">
        <div className="card-inner">
          <div className="form-block">
            <MembershipTypeForm
              headingText="What membership would you like to gift?"
              initialValues={{
                selectedMembershipType: MEMBERSHIP_TYPE.GOLD_CARD,
              }}
              membershipPrices={membershipPrices}
              onSubmit={({ selectedMembershipType }) =>
                setSelectedMembershipType(selectedMembershipType)
              }
              onSubmitSuccess={() =>
                history.push(makeGiftStepsPath(PATH.DELIVERY))
              }
              selectedUseTypes={[BOAT_USE_TYPE.RECREATIONAL]}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

ProductSelection.propTypes = propTypes
ProductSelection.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    membershipPrices: memberSelectors.membershipPrices(state),
  }
}

const mapDispatchToProps = {
  setSelectedMembershipType: actions.setSelectedMembershipType,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ProductSelection
)
