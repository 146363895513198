import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as membershipSelectors } from 'memberships/reducer'
import { MembershipSelection } from 'member-portal/components'
import {
  AllMembershipsType,
  MEMBER_APPLICATION_FLOW,
  MEMBERSHIP_TYPES_WITH_TRAILER_CARE,
  TRAILER_CARE_TYPE,
} from 'types'
import { makePath, PATH } from 'config'

const propTypes = {
  allMemberships: AllMembershipsType,
}

const defaultProps = {}

function TrailerCareUpgradesSelectYourMembership({ allMemberships }) {
  const { memberships } = allMemberships

  return (
    <>
      <MembershipSelection
        isUpgrade
        memberApplicationFlow={MEMBER_APPLICATION_FLOW.TRAILER_CARE_UPGRADE}
        memberships={filterNonTrailerCarePlusTypes(memberships)}
        nextStep={makePath(PATH.UPGRADES, PATH.TRAILER_CARE, PATH.SELECTION)}
      />
    </>
  )
}

TrailerCareUpgradesSelectYourMembership.propTypes = propTypes
TrailerCareUpgradesSelectYourMembership.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    allMemberships: membershipSelectors.allMemberships(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TrailerCareUpgradesSelectYourMembership
)

const filterNonTrailerCarePlusTypes = (memberships) => {
  return memberships.filter(
    ({ membership_type__c, trailer_care_type__c }) =>
      trailer_care_type__c !== TRAILER_CARE_TYPE.PLUS &&
      MEMBERSHIP_TYPES_WITH_TRAILER_CARE.includes(membership_type__c)
  )
}
