import React from 'react'
import PropTypes from 'prop-types'
import {
  AppliedDiscountCodeType,
  ProductPrices,
  MEMBERSHIP_TYPES_WITH_TRAILER_CARE,
} from 'types'
import exact from 'prop-types-exact'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { SectionHeader, ButtonLink } from 'components'
import { Link } from 'react-router-dom'
import { MembershipDetails, TrailerCareAddOn } from 'member-portal/components'
import * as memberActions from 'member-actions'
import { selectors as memberSelectors } from 'member-reducer'
import {
  PATH,
  makeMemberApplicationPath,
  makeMemberApplicationApplyPath,
} from 'config'

const propTypes = {
  clearDiscountCodeStateAndLS: PropTypes.func.isRequired,
  discountCodeDetails: AppliedDiscountCodeType,
  isTrialMembershipsActive: PropTypes.bool,
  membershipPrices: ProductPrices.isRequired,
  promoCodeIsTrial: PropTypes.bool.isRequired,
  selectedMembershipType: PropTypes.string.isRequired,
  selectedTrailerCareType: PropTypes.string,
  setSelectedTrailerCareType: PropTypes.func.isRequired,
  trailerCarePrices: ProductPrices.isRequired,
  trialDuration: PropTypes.number,
}

const defaultProps = {}

function NewMemberSelectedMembershipType({
  clearDiscountCodeStateAndLS,
  discountCodeDetails,
  isTrialMembershipsActive,
  membershipPrices,
  promoCodeIsTrial,
  selectedMembershipType,
  selectedTrailerCareType,
  setSelectedTrailerCareType,
  trailerCarePrices,
  trialDuration,
}) {
  return (
    <section className="form-block-container">
      <header className="header-title">
        <h1>
          {promoCodeIsTrial && isTrialMembershipsActive
            ? 'Activate Trial'
            : 'Membership Advisor'}
        </h1>
      </header>
      <div className="card card-medium">
        <div className="card-inner">
          <div className="form-block">
            <SectionHeader sectionHeaderName="Selected Sea Tow Membership" />
            {promoCodeIsTrial && isTrialMembershipsActive ? (
              ''
            ) : (
              <p>
                Based on your responses we'd recommend the{' '}
                {selectedMembershipType} membership. Interested in another
                membership?{' '}
                <Link to={makeMemberApplicationPath(PATH.MEMBERSHIP_TYPE_ALL)}>
                  Click here to choose a different membership
                </Link>
                .
              </p>
            )}
            <MembershipDetails
              basePath={makeMemberApplicationPath(
                PATH.MEMBERSHIP_TYPE_SELECTED
              )}
              {...{
                discountCodeDetails,
                selectedMembershipType,
                membershipPrices,
                clearDiscountCodeStateAndLS,
              }}
              {...(isTrialMembershipsActive && {
                promoCodeIsTrial,
                trialDuration,
              })}
            />
            {MEMBERSHIP_TYPES_WITH_TRAILER_CARE.includes(
              selectedMembershipType
            ) && (
              <>
                <hr />
                <TrailerCareAddOn
                  {...{
                    selectedTrailerCareType,
                    setSelectedTrailerCareType,
                    trailerCarePrices,
                  }}
                />
              </>
            )}
            {promoCodeIsTrial && isTrialMembershipsActive && (
              <div
                className="trial-legal-language"
                dangerouslySetInnerHTML={{
                  __html: discountCodeDetails?.promo_legal,
                }}
              />
            )}
          </div>
          <div className="button-group">
            <ButtonLink
              to={makeMemberApplicationApplyPath(PATH.CONTACT)}
              className="button-primary"
            >
              Continue
            </ButtonLink>
          </div>
        </div>
      </div>
    </section>
  )
}

NewMemberSelectedMembershipType.propTypes = exact(propTypes)
NewMemberSelectedMembershipType.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    discountCodeDetails: memberSelectors.discountCodeDetails(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
    membershipPrices: memberSelectors.membershipPrices(state),
    promoCodeIsTrial: memberSelectors.promoCodeIsTrial(state),
    selectedMembershipType: memberSelectors.selectedMembershipType(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
    trailerCarePrices: memberSelectors.trailerCarePrices(state),
    trialDuration: memberSelectors.trialDuration(state),
  }
}

const mapDispatchToProps = {
  clearDiscountCodeStateAndLS: memberActions.clearDiscountCodeStateAndLS,
  setSelectedTrailerCareType: memberActions.setSelectedTrailerCareType,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NewMemberSelectedMembershipType
)
