import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { ReviewStep } from 'member-portal/components'
import * as apiActions from 'api-actions'
import {
  AccountType,
  AppliedDiscountCodeType,
  BillingType,
  MembershipType,
  ProductPrices,
} from 'types'
import { makeMembershipIdPath, PATH } from 'config'
import {
  apiValuesWithPaymentDetails,
  calculateDiscountedCost,
  calculateReferralCreditsUsed,
} from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  billing: BillingType,
  addPaymentToOpportunity: PropTypes.func.isRequired,
  discountCodeDetails: AppliedDiscountCodeType,
  gclid: PropTypes.string,
  oppGuid: PropTypes.string.isRequired,
  membership: MembershipType.isRequired,
  membershipPrices: ProductPrices.isRequired,
  selectedMembershipType: PropTypes.string.isRequired,
  testVariant: PropTypes.string,
}
const defaultProps = {}

function RenewalReview({
  account,
  billing,
  addPaymentToOpportunity,
  discountCodeDetails,
  gclid,
  oppGuid,
  membership,
  membershipPrices,
  selectedMembershipType,
  testVariant,
}) {
  const { sfid: membershipId } = membership
  const { referral_credits__c: referralCredits } = account
  const originalMembershipCost = membershipPrices[selectedMembershipType]
  const discountedMembershipCost = calculateDiscountedCost(
    originalMembershipCost,
    discountCodeDetails,
  )
  const referralCreditsUsed = calculateReferralCreditsUsed(
    discountedMembershipCost,
    referralCredits,
  )

  return (
    <div>
      <ReviewStep
        account={account}
        confirmAndFinalizePurchase={(formValues) => {
          const {smsOptIn} = formValues
          
          return addPaymentToOpportunity(
            apiValuesWithPaymentDetails({
              account,
              billing,
              discountCodeDetails,
              discountedMembershipCost,
              gclid,
              membership,
              referralCreditsUsed,
              oppGuid,
              smsOptIn,
              testVariant,
            }),
          )
        }}
        membership={membership}
        paymentPath={makeMembershipIdPath(
          membershipId,
          PATH.RENEW_MEMBERSHIP,
          PATH.PAYMENT,
        )}
        nextStep={makeMembershipIdPath(
          membershipId,
          PATH.RENEW_MEMBERSHIP,
          PATH.CONFIRM_AND_LOGIN,
        )}
        canEditBoat
        canEditContactInfo
        canEditProductSelections
        referralCreditsUsed={referralCreditsUsed}
        showDonation
        showMembershipCost
        showTrailerCareCost
      />
    </div>
  )
}

RenewalReview.propTypes = propTypes
RenewalReview.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    account: memberSelectors.account(state),
    billing: memberSelectors.billing(state),
    discountCodeDetails: memberSelectors.discountCodeDetails(state),
    gclid: memberSelectors.gclid(state),
    membership: memberSelectors.membership(state),
    membershipPrices: memberSelectors.membershipPrices(state),
    selectedMembershipType: memberSelectors.selectedMembershipType(state),
    oppGuid: memberSelectors.oppGuid(state),
    testVariant: memberSelectors.testVariant(state),
  }
}

const mapDispatchToProps = {
  addPaymentToOpportunity: apiActions.addPaymentToOpportunity,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RenewalReview,
)
