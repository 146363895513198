import React from 'react'
import PropTypes from 'prop-types'
import { MailedRecipientContactType } from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useHistory } from 'react-router-dom'
import { MailedRecipientForm } from '../forms'
import { COUNTRY, PATH, SHIPPING_METHOD, makeGiftStepsPath } from 'config'

const propTypes = {
  autoCompleteAddress: PropTypes.func.isRequired,
  fetchPlaceDetails: PropTypes.func.isRequired,
  giftMessage: PropTypes.string,
  giftWrapped: PropTypes.bool.isRequired,
  mailedToGifter: PropTypes.bool.isRequired,
  recipientContact: MailedRecipientContactType,
  setGiftMessage: PropTypes.func.isRequired,
  setGiftWrapped: PropTypes.func.isRequired,
  setMailedToGifter: PropTypes.func.isRequired,
  setRecipientContact: PropTypes.func.isRequired,
  setShippingMethod: PropTypes.func.isRequired,
  shippingMethod: PropTypes.string,
}
const defaultProps = {}
const DEFAULT_RECIPIENT_CONTACT = {
  country: COUNTRY.UNITED_STATES,
}
function MailedRecipient({
  autoCompleteAddress,
  fetchPlaceDetails,
  giftMessage,
  giftWrapped,
  mailedToGifter,
  recipientContact,
  setGiftMessage,
  setGiftWrapped,
  setMailedToGifter,
  setRecipientContact,
  setShippingMethod,
  shippingMethod,
}) {
  const history = useHistory()

  return (
    <MailedRecipientForm
      {...{ autoCompleteAddress, fetchPlaceDetails }}
      enableReinitialize
      initialValues={{
        giftMessage,
        giftWrapped,
        mailedToGifter,
        recipientContact: recipientContact ?? DEFAULT_RECIPIENT_CONTACT,
        shippingMethod: shippingMethod ?? SHIPPING_METHOD.STANDARD,
      }}
      onSubmit={({
        giftMessage,
        giftWrapped,
        mailedToGifter,
        recipientContact,
        shippingMethod,
      }) => {
        setGiftMessage(giftMessage)
        setGiftWrapped(giftWrapped)
        setMailedToGifter(mailedToGifter)
        setRecipientContact(recipientContact)
        setShippingMethod(shippingMethod)
      }}
      onSubmitSuccess={() => history.push(makeGiftStepsPath(PATH.PAYMENT))}
    />
  )
}

MailedRecipient.propTypes = exact(propTypes)
MailedRecipient.defaultProps = defaultProps

export default pure(MailedRecipient)
