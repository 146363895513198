import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as apiActions from 'api-actions'
import { SectionHeader, PageHeader } from 'components'
import { OfferForm } from '../forms'
import { API_KEY_MAP } from 'types'
import { PATH, SEATOW_URL, makeProgramsPortalPath } from 'config'
import {
  apiValuesWithMappedKeys,
  formValuesWithMappedKeys,
  setObjectIdentifiers,
  withApiAuth,
} from 'utils'

const propTypes = {
  updateOffer: PropTypes.func.isRequired,
}

const defaultProps = {}

function EditOffer({ updateOffer }) {
  const history = useHistory()
  const location = useLocation()
  const { offer } = location.state

  return (
    <>
      <PageHeader
        headerText="Savings Club"
        backLinkTo={makeProgramsPortalPath(PATH.SAVINGS_CLUB_OFFERS)}
        backLinkText="Back to Savings Club"
      />
      <section className="form-block-container">
        <div className="card card-medium">
          <div className="card-inner">
            <div className="form-block">
              <SectionHeader sectionHeaderName="Edit Offer" />
              <p>
                When you edit an offer, it will go to us for another review.
                Once approved your updated offer will go live in the Sea Tow
                Savings Club listings on{' '}
                <a href={`http://${SEATOW_URL}`}>seatow.com</a>.
              </p>
              <OfferForm
                initialValues={formValuesWithMappedKeys(
                  offer,
                  API_KEY_MAP.OFFER_KEY_MAP
                )}
                onSubmit={(formValues) => {
                  const mappedApiValues = apiValuesWithMappedKeys(
                    formValues,
                    API_KEY_MAP.OFFER_KEY_MAP
                  )
                  return updateOffer(
                    setObjectIdentifiers(mappedApiValues, offer)
                  )
                }}
                onSubmitSuccess={async () => {
                  history.replace(
                    makeProgramsPortalPath(
                      PATH.SAVINGS_CLUB_OFFERS,
                      PATH.CONFIRM_OFFER
                    )
                  )
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

EditOffer.propTypes = propTypes
EditOffer.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  updateOffer: apiActions.updateOffer,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(EditOffer)
