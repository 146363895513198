import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import classnames from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import MembershipTypeDisplay from './MembershipTypeDisplay'
import { ExternalLink, LinkButton, StatusIndicator } from 'components'
import { UpgradeButton } from 'member-portal/components'
import { DigitalWalletPass } from 'memberships/components'
import { MEMBERSHIP_STATUS, MEMBERSHIP_TYPE, STATUS_CLASS } from 'types'
import {
  DIGITAL_WALLET_TYPE,
  makeMembershipIdPath,
  makePath,
  PATH,
} from 'config'

const propTypes = {
  applePassUrl: PropTypes.string,
  googlePassUrl: PropTypes.string,
  membershipSfid: PropTypes.string.isRequired,
  memberNumber: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}
const defaultProps = {}

function MembershipHeader({
  applePassUrl,
  googlePassUrl,
  membershipSfid,
  memberNumber,
  status,
  type,
}) {
  return (
    <header className="with-card-preview with-actions dashboard">
      <div className="icon-and-header">
        <img
          src="/assets/images/imagery/ST_Icon_membercard.svg"
          alt=""
          className="card-icon"
        />
        <div>
          <MembershipTypeDisplay type={type} />
          <div className="subheader">
            <p>
              Membership #{memberNumber} <span className="divider">|</span>{' '}
            </p>
            <StatusIndicator
              statusClass={membershipStatusToStatusClass(status)}
              statusText={`${status}`}
            />
          </div>
          {/* Upgrade ONLY if a Lake Type Membership */}
          {type === MEMBERSHIP_TYPE.LAKE_CARD && (
            <UpgradeButton
              to={makeMembershipIdPath(
                membershipSfid,
                PATH.UPGRADES,
                PATH.MEMBERSHIP,
                PATH.SELECTION,
              )}
              className="link-primary"
            >
              Upgrade to Gold Card Membership
            </UpgradeButton>
          )}
        </div>
      </div>
      <MembershipStatus
        {...{
          applePassUrl,
          googlePassUrl,
          membershipSfid,
          memberNumber,
          status,
        }}
      />
    </header>
  )
}

MembershipHeader.propTypes = exact(propTypes)
MembershipHeader.defaultProps = defaultProps

export default pure(MembershipHeader)

function MembershipStatus({
  applePassUrl,
  googlePassUrl,
  membershipSfid,
  memberNumber,
  status,
}) {
  const membershipActive =
    status === MEMBERSHIP_STATUS.ACTIVE ||
    status === MEMBERSHIP_STATUS.FUTURE_START
  const hasEitherDigitalPass = !!applePassUrl || !!googlePassUrl

  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)
  const closeMenu = () => setMenuOpen(false)

  return (
    <div className="actions">
      {membershipActive && (
        <>
          <ExternalLink
            href={makePath(
              PATH.SEATOW,
              PATH.MEMBERSHIP,
              PATH.MEMBERSHIP_AGREEMENT,
            )}
            className="link-small"
            showIcon={false}
          >
            <img
              src="/assets/images/icons/transparent/ST_Icon_M-Agreement.svg"
              alt=""
            />
            Membership Agreement
          </ExternalLink>
          <Link
            to={makeMembershipIdPath(membershipSfid, PATH.MEMBERSHIP_CARD)}
            aria-label={`View membership card for membership ${memberNumber}`}
            className="link-small"
          >
            <img
              src="/assets/images/icons/transparent/ST_Icon_M-Card.svg"
              alt=""
            />
            Membership Card
          </Link>
          {hasEitherDigitalPass && (
            <div className="digital-passes">
              <DigitalWalletPass
                type={DIGITAL_WALLET_TYPE.APPLE}
                url={applePassUrl}
              />
              <DigitalWalletPass
                type={DIGITAL_WALLET_TYPE.GOOGLE}
                url={googlePassUrl}
              />
            </div>
          )}
          {hasEitherDigitalPass && (
            <div className="dropdown-wrapper">
              <OutsideClickHandler
                disabled={menuOpen === false}
                onOutsideClick={closeMenu}
                className="dropdown-wrapper"
              >
                <LinkButton
                  onClick={toggleMenu}
                  className="link-secondary link-small external-link"
                >
                  <img
                    src="/assets/images/icons/transparent/ST_Icon_Wallet.svg"
                    alt=""
                  />
                  Add to Wallet
                </LinkButton>

                <div
                  className={classnames('dropdown-block', {
                    ['is-open']: menuOpen,
                  })}
                  onClick={closeMenu}
                  role="button"
                >
                  <div className="dropdown-block-inner">
                    <DigitalWalletPass
                      type={DIGITAL_WALLET_TYPE.APPLE}
                      url={applePassUrl}
                    />
                    <DigitalWalletPass
                      type={DIGITAL_WALLET_TYPE.GOOGLE}
                      url={googlePassUrl}
                    />
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const membershipStatusToStatusClass = (status) => {
  switch (status) {
    case MEMBERSHIP_STATUS.ACTIVE:
      return STATUS_CLASS.OK
    default:
      return STATUS_CLASS.NOT_OK
  }
}
