import React from 'react'
import { compose } from 'recompose'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { lpForm } from 'lp-form'
import { SubmitButton, Select } from 'lp-components'
import { HOME_PORT_TYPE } from 'types'

const propTypes = {
  ...formPropTypes,
}
const defaultProps = {}

function HomePortTypeForm({ handleSubmit, pristine, submitting }) {
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Field
        name="homePortType"
        label="Where do you typically keep your boat?"
        component={Select}
        options={Object.values(HOME_PORT_TYPE)}
        placeholder="Select"
        required
        requiredIndicator="*"
      />
      <div className="button-navigation">
        <SubmitButton
          {...{ pristine, submitting }}
        >
          Continue
        </SubmitButton>
      </div>
    </form>
  )
}

HomePortTypeForm.propTypes = propTypes
HomePortTypeForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'HomePortTypeForm',
    constraints: {
      homePortType: { presence: true },
    },
  })
)(HomePortTypeForm)
