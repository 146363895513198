import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Routes as BillingRoutes } from './billing'
import { Routes as MembershipCardRoutes } from './membership_card'
import { Routes as BoatRoutes } from './boats'
import { Routes as RenewalRoutes, Layout as RenewalLayout } from './renewal'
import { Routes as UpgradeRoutes } from './upgrades'
import * as Views from './views'
import { MarketingCodeProvider } from 'member-portal/components'
import {
  AllMembershipsProvider,
  MembershipProvider,
  MemberApplicationReset,
} from './components'
import { PATH, makePath } from 'config'

const propTypes = {}
const defaultProps = {}

// --[Memberships Routes]---------------------------------------------------------------
// All of the "memberships" routes require that the membership data for an account (which
// can have *multiple* memberships) be available in the Redux store ("allMemberships").
// The AllMembershipsProvider makes the API request to obtain this data and store it in
// Redux.
//
// Membership-specific views, like MembershipCard and Billing, require the data for
// a *specific* membership be available in the Redux store ("membership"). The
// MembershipProvider obtains the specific membership data from "allMemberships" and
// stores it in Redux.

function Routes() {
  const { path } = useRouteMatch()

  return (
    <AllMembershipsProvider>
      <Switch>
        <Route exact path={makePath(path, PATH.ROOT)}>
          <Views.Dashboard />
        </Route>
        <Route path={makePath(path, PATH.SELECT_YOUR_MEMBERSHIP)}>
          <SelectYourMembershipRoutes />
        </Route>
        <Route path={makePath(path, PATH.SFID)}>
          <MembershipProvider>
            <MembershipRoutes />
          </MembershipProvider>
        </Route>
      </Switch>
    </AllMembershipsProvider>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes

function MembershipRoutes() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={makePath(path, PATH.BILLING)}>
        <BillingRoutes />
      </Route>
      <Route path={makePath(path, PATH.BOATS)}>
        <BoatRoutes />
      </Route>
      <Route path={makePath(path, PATH.MEMBERSHIP_CARD)}>
        <MembershipCardRoutes />
      </Route>
      <Route path={makePath(path, PATH.RENEW_MEMBERSHIP)}>
        <RenewalRoutes />
      </Route>
      <Route path={makePath(path, PATH.UPGRADES)}>
        <UpgradeRoutes />
      </Route>
    </Switch>
  )
}

function SelectYourMembershipRoutes() {
  const { path } = useRouteMatch()

  return (
    <MemberApplicationReset>
      <Switch>
        <Route exact path={makePath(path, PATH.RENEW_MEMBERSHIP)}>
          <MarketingCodeProvider isRenewal>
            <RenewalLayout>
              <Views.RenewalSelectYourMembership />
            </RenewalLayout>
          </MarketingCodeProvider>
        </Route>
        <Route exact path={makePath(path, PATH.UPGRADES, PATH.MEMBERSHIP)}>
          <Views.MembershipUpgradesSelectYourMembership />
        </Route>
        <Route exact path={makePath(path, PATH.UPGRADES, PATH.TRAILER_CARE)}>
          <Views.TrailerCareUpgradesSelectYourMembership />
        </Route>
      </Switch>
    </MemberApplicationReset>
  )
}
