import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { flashErrorMessage, flashSuccessMessage } from 'redux-flash'

const propTypes = {
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
}

const defaultProps = {}

function NetworkErrorReporter({ flashErrorMessage, flashSuccessMessage }) {
  useEffect(() => {
    window.addEventListener('online', () =>
      flashSuccessMessage('Network is available', { push: true, timeout: 3000 })
    )
    window.addEventListener('offline', () =>
      flashErrorMessage(
        "Network is unavailable. Are you sure you're connected to the network?"
      )
    )
  }, [])

  // This page renders no content but exists merely to set up the network status
  // listeners...
  return null
}

NetworkErrorReporter.propTypes = propTypes
NetworkErrorReporter.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  flashErrorMessage: flashErrorMessage,
  flashSuccessMessage: flashSuccessMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NetworkErrorReporter
)
