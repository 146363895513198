import { useEffect } from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useAuth0 } from '@auth0/auth0-react'
import { PATH, makeMarketingSitePath } from 'config'

const propTypes = {}
const defaultProps = {}

function Logout() {
  const { logout } = useAuth0()

  useEffect(
    () =>
      logout({
        logoutParams: { returnTo: makeMarketingSitePath(PATH.SEATOW_LOGOUT) },
      }),
    [],
  )

  return null
}

Logout.propTypes = exact(propTypes)
Logout.defaultProps = defaultProps

export default pure(Logout)
