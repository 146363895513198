import React from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  fieldPropTypes,
  fieldOptionsType,
  fieldOptionGroupsType,
  omitLabelProps,
  LabeledField,
  serializeOptions,
  serializeOptionGroups,
  convertNameToLabel,
} from 'lp-components'
import { size } from 'lodash'
import classnames from 'classnames'

/**
 * Override of lp-components Radio Group to support the concept of "option groups".
 * Similar to a select input's "optgroup" and replicated here via
 * fieldset and legends.
 */

const propTypes = {
  ...fieldPropTypes,
  options: fieldOptionsType,
  optionGroups: fieldOptionGroupsType,
  // Only use ariaLabelledby OR ariaLabel, not both
  ariaLabelledby: PropTypes.string,
  ariaLabel: PropTypes.string,
}

const defaultProps = {
  options: [],
  optionGroups: [],
  ariaLabelledby: null,
  ariaLabel: null,
}

const uniqueId = ({ name, option, id = '' }) => {
  if (!id) return `${name}.${option.value}`
  return `${id}.${option.value}`
}

function RadioGroupInput({
  id,
  name,
  value,
  onChange,
  option,
  inputLabelComponent,
  ...rest
}) {
  return (
    <Input
      {...{
        type: 'radio',
        input: {
          name, // all radio inputs must share the same name
          value: '',
          onChange: () => onChange(option.value),
        },
        id: uniqueId({ name, option, id }), // override Input default behavior to assign id to input: { name }
        meta: {},
        checked: value === option.value,
        label: option.key,
        labelComponent: inputLabelComponent,
        ...rest,
      }}
    />
  )
}

function RadioGroup(props) {
  const {
    input: { value, onChange, name },
    meta, // eslint-disable-line no-unused-vars
    options,
    optionGroups,
    ariaLabelledby,
    ariaLabel,
    ...rest
  } = omitLabelProps(props)
  const optionObjects = serializeOptions(options)
  const optionGroupObjects = serializeOptionGroups(optionGroups)
  const hideOptionGroupLabels = size(optionGroupObjects) <= 1
  return (
    <LabeledField className="RadioGroup" {...props}>
      <div
        role="radiogroup"
        aria-labelledby={ariaLabelledby}
        aria-label={ariaLabel}
      >
        {optionGroupObjects.length
          ? optionGroupObjects.map((group) => {
              return (
                <fieldset key={group.name}>
                  <legend
                    className={classnames({
                      'visually-hidden': hideOptionGroupLabels,
                    })}
                  >
                    {convertNameToLabel(group.name)}
                  </legend>
                  {group.options.map((option) => (
                    <RadioGroupInput
                      key={option.value}
                      {...{
                        name,
                        value,
                        onChange,
                        option,
                        ...rest,
                      }}
                    />
                  ))}
                </fieldset>
              )
            })
          : optionObjects.map((option) => {
              return (
                <RadioGroupInput
                  key={option.value}
                  {...{
                    name,
                    value,
                    onChange,
                    option,
                    ...rest,
                  }}
                />
              )
            })}
      </div>
    </LabeledField>
  )
}

RadioGroup.propTypes = propTypes
RadioGroup.defaultProps = defaultProps

export default RadioGroup
