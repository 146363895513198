import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Button } from 'lp-components'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { IMAGE_PATH, PATH } from 'config'
import { GIFT_DELIVERY_METHOD } from 'types'

const propTypes = {
  clearAllGiftState: PropTypes.func.isRequired,
  deliveryMethod: PropTypes.string.isRequired,
}
const defaultProps = {}

function ConfirmGift({ clearAllGiftState, deliveryMethod }) {
  const confirmationText =
    deliveryMethod === GIFT_DELIVERY_METHOD.MAILED
      ? 'Mailed gift memberships are generally shipped the next business day (M-F) after purchase and standard mailing can take 2-5 days. Gift membership recipients will receive an activation code which they will use when joining or renewing.'
      : 'Virtual gift memberships will be emailed within 1 hour of purchase. Gift membership recipients will receive an activation code which they will use when joining or renewing.'

  useEffect(() => {
    return () => clearAllGiftState()
  }, [])

  return (
    <section className="form-block-container confirmation">
      <div className="card card-medium">
        <header>
          <img alt="" src={IMAGE_PATH.GIFT_CONFIRMATION} className="confirmation-icon" />
        </header>
        <h2>Your Gift Has Set Sail</h2>
        <p>{confirmationText}</p>
        <Button
          className="button-primary"
          onClick={() => window.location.replace(PATH.SEATOW)}
        >
          Return to Website
        </Button>
      </div>
    </section>
  )
}

ConfirmGift.propTypes = propTypes
ConfirmGift.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    deliveryMethod: selectors.deliveryMethod(state),
  }
}

const mapDispatchToProps = {
  clearAllGiftState: actions.clearAllGiftState,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ConfirmGift
)
