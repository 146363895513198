import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'

const propTypes = {
  alt: PropTypes.string,
  path: PropTypes.string.isRequired,
  image_uri: PropTypes.string.isRequired,
}
const defaultProps = {
  alt: '',
}

function ImageLink({ alt, path, image_uri }) {
  return (
    <a href={path}>
      <img src={image_uri} alt={alt} />
    </a>
  )
}

ImageLink.propTypes = exact(propTypes)
ImageLink.defaultProps = defaultProps

export default pure(ImageLink)
