import React from 'react'
import PropTypes from 'prop-types'
import { AppliedDiscountCodeType, BillingType, ProductPrices } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ApplicationSteps, MembershipSummary } from 'member-portal/components'
import { selectors as memberSelectors } from 'member-reducer'
import {
  MEMBER_APPLICATION_STEP_LINKS,
  PATH,
  makeMemberApplicationPath,
  makeMemberApplicationApplyPath
} from 'config'

const propTypes = {
  billing: BillingType,
  children: PropTypes.node.isRequired,
  discountCodeDetails: AppliedDiscountCodeType,
  isTrialMembershipsActive: PropTypes.bool,
  membershipPrices: ProductPrices.isRequired,
  promoCodeIsTrial: PropTypes.bool.isRequired,
  selectedMembershipType: PropTypes.string.isRequired,
  selectedTrailerCareType: PropTypes.string,
  trailerCarePrices: ProductPrices.isRequired,
  trialDuration: PropTypes.number,
}

const defaultProps = {}

function MemberApplicationLayout({
  billing,
  children,
  discountCodeDetails,
  isTrialMembershipsActive,
  membershipPrices,
  promoCodeIsTrial,
  selectedMembershipType,
  selectedTrailerCareType,
  trailerCarePrices,
  trialDuration,
}) {
  const { pathname } = useLocation()

  // The following determines the Step Links in the Member Application.
  // This will be removed when once we finish A/A and A/B testing on the new
  // Member Contact Form.
  const contactStepPath = makeMemberApplicationApplyPath(
    pathname.endsWith(PATH.CONTACT) ? PATH.CONTACT : PATH.CONTACT_2
  );

  const modifiedStepLinks = MEMBER_APPLICATION_STEP_LINKS.map(step => {
    if (step.stepName === 'Contact Info') {
      return {
        ...step,
        path: contactStepPath
      };
    }
    return step;
  });


  return (
    <div className="form-with-aside-wrapper">
      <header className="header-title">
        <h1>Become a Sea Tow Member</h1>
      </header>
      <div className="form-with-aside-block">
        <div className="form-with-aside">
          <ApplicationSteps
            pathname={pathname}
            stepLinks={modifiedStepLinks}
          >
            {children}
          </ApplicationSteps>
        </div>
        <aside>
          <MembershipSummary
            donation={billing && billing.finalDonation}
            membershipType={selectedMembershipType}
            trailerCareType={selectedTrailerCareType}
            updateLink={makeMemberApplicationPath(
              PATH.MEMBERSHIP_TYPE_SELECTED
            )}
            {...{
              discountCodeDetails,
              membershipPrices,
              trailerCarePrices,
            }}
            {...(isTrialMembershipsActive && {
              promoCodeIsTrial,
              trialDuration,
            })}
          />
        </aside>
      </div>
    </div>
  )
}

MemberApplicationLayout.propTypes = propTypes
MemberApplicationLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    billing: memberSelectors.billing(state),
    discountCodeDetails: memberSelectors.discountCodeDetails(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
    membershipPrices: memberSelectors.membershipPrices(state),
    promoCodeIsTrial: memberSelectors.promoCodeIsTrial(state),
    selectedMembershipType: memberSelectors.selectedMembershipType(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
    trailerCarePrices: memberSelectors.trailerCarePrices(state),
    trialDuration: memberSelectors.trialDuration(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MemberApplicationLayout
)
