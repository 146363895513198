import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { isEmpty } from 'lodash'
import ProgramsSignup from './ProgramsSignup'
import { AllSavingsClubTypes } from 'types'
import { PATH, makeProgramsPortalPath } from 'config'

const propTypes = {
  programs: AllSavingsClubTypes.isRequired,
}
const defaultProps = {}

function AdditionalPrograms({ programs }) {
  const { membership_kits, savings_club, sea_care } = programs
  const membershipKitInactive = isEmpty(membership_kits)
  const savingsClubInactive = isEmpty(savings_club)
  const seaCareInactive = isEmpty(sea_care)

  // Note that additional programs are offered only if at least one program is inactive...
  const anyAdditionalPrograms =
    membershipKitInactive || savingsClubInactive || seaCareInactive

  return (
    anyAdditionalPrograms && (
      <section>
        <h2>Additional Programs</h2>
        {/* {savingsClubInactive && <SavingsClubSignUp />} */}
        {seaCareInactive && (
          <ProgramsSignup
            image="/assets/images/imagery/programs-portal/seacare.jpg"
            programName="Sea Care"
            programDescription="Include Sea Tow memberships with the purchase of a boat, trailer, engine, or other product or service to ensure your customers have complete peace of mind while boating."
            infoLink={makeProgramsPortalPath(PATH.SEA_CARE, PATH.APPLY)}
          />
        )}
        {membershipKitInactive && (
          <ProgramsSignup
            image="/assets/images/imagery/programs-portal/membership-kits.jpg"
            programName="Membership Kits"
            programDescription="Sell Sea Tow memberships at your establishment by stocking Sea Tow membership kits. Wholesale rates on memberships to vendors allow you to earn with each sale."
            infoLink={makeProgramsPortalPath(PATH.MEMBERSHIP_KIT, PATH.APPLY)}
          />
        )}
      </section>
    )
  )
}

AdditionalPrograms.propTypes = exact(propTypes)
AdditionalPrograms.defaultProps = defaultProps

export default pure(AdditionalPrograms)
