import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Spinner } from 'lp-components'
import { AccountType, ContactArrayType } from 'types'
import { selectors as globalSelectors } from 'global-reducer'
import * as globalActions from 'global-actions'
import * as apiActions from 'api-actions'
import { withApiAuth } from 'utils'

const propTypes = {
  account: AccountType,
  accountId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  clearAccount: PropTypes.func.isRequired,
  clearContacts: PropTypes.func.isRequired,
  contacts: ContactArrayType,
  fetchAccount: PropTypes.func.isRequired,
  fetchContacts: PropTypes.func.isRequired,
}
const defaultProps = {}

function AccountProvider({
  account,
  accountId,
  children,
  clearAccount,
  clearContacts,
  contacts,
  fetchAccount,
  fetchContacts,
}) {
  useEffect(() => {
    fetchAccount(accountId)
    fetchContacts(accountId)

    return () => {
      clearAccount()
      clearContacts()
    }
  }, [])

  if (!account || !contacts) return <Spinner />

  return children
}

AccountProvider.propTypes = propTypes
AccountProvider.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    account: globalSelectors.account(state),
    contacts: globalSelectors.contacts(state),
  }
}

const mapDispatchToProps = {
  clearAccount: globalActions.clearAccount,
  clearContacts: globalActions.clearContacts,
}

const mapApiAuthToProps = {
  fetchAccount: apiActions.fetchAccount,
  fetchContacts: apiActions.fetchContacts,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(AccountProvider)
