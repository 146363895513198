import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { sortBy } from 'lodash'
import Membership from './Membership'
import { selectors as memberSelectors } from 'member-reducer'
import { selectors } from '../reducer'
import { AllMembershipsType, ProductPrices } from 'types'

const propTypes = {
  allMemberships: AllMembershipsType.isRequired,
  membershipPrices: ProductPrices.isRequired,
}
const defaultProps = {}

function Memberships({ allMemberships, membershipPrices }) {
  const { account, memberships } = allMemberships
  const sortedMemberships = sortBy(memberships, ['membership_number__c'])

  return (
    <section>
      <h2>My Membership(s)</h2>
      {sortedMemberships.length > 0 ? (
        sortedMemberships.map((membership) => (
          <Membership
            key={membership.id}
            account={account}
            membership={membership}
            membershipPrices={membershipPrices}
          />
        ))
      ) : (
        <p>No memberships for this account</p>
      )}
    </section>
  )
}

Memberships.propTypes = propTypes
Memberships.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    allMemberships: selectors.allMemberships(state),
    membershipPrices: memberSelectors.membershipPrices(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Memberships
)
