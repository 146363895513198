import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectors as memberSelectors } from 'member-reducer'
import * as memberActions from 'member-actions'
import { SectionHeader } from 'components'
import { Button } from 'lp-components'
import { PATH, makeMembershipIdPath } from 'config'
import {
  MEMBERSHIP_DETAIL,
  MEMBERSHIP_LAKE_TO_GOLD_UPGRADE,
  MEMBERSHIP_TYPE,
  MembershipType,
  ProductPrices,
} from 'types'
import { formatCurrency } from 'utils'

const propTypes = {
  membership: MembershipType.isRequired,
  membershipPrices: ProductPrices.isRequired,
  setSelectedMembershipType: PropTypes.func.isRequired,
}
const defaultProps = {}

function GoldUpgradeSelected({
  membership,
  membershipPrices,
  setSelectedMembershipType,
}) {
  const history = useHistory()
  const goldCardCost = membershipPrices[MEMBERSHIP_TYPE.GOLD_CARD]
  const lakeCardCost = membershipPrices[MEMBERSHIP_TYPE.LAKE_CARD]
  const upgradeDescription =
    MEMBERSHIP_DETAIL[MEMBERSHIP_LAKE_TO_GOLD_UPGRADE].description
  const { sfid: membershipId } = membership

  return (
    <>
      <section className="form-block-container">
        <div className="card card-medium">
          <div className="card-inner">
            <div className="form-block">
              <SectionHeader sectionHeaderName="Gold Card Membership" />
              <div className="group-block">
                <h3 className="group-title">
                  Upgrade to service on all salt + fresh water nationwide for only {formatCurrency(goldCardCost - lakeCardCost)} more per year.
                </h3>
                <p>{upgradeDescription}</p>
              </div>
              <hr className="divider" />
              <div className="invoice-summary">
                <div className="invoice-item">
                  <p className="title">Gold Card membership</p>
                  <div className="cost">
                    <p>{formatCurrency(goldCardCost)} / year</p>
                  </div>
                </div>
                <div className="invoice-item">
                  <p className="title">Lake Card Membership</p>
                  <div className="cost">
                    <p>{formatCurrency(lakeCardCost)} / year</p>
                  </div>
                </div>
                <div className="invoice-item total">
                  <p className="title">Lake to Gold Card Upgrade</p>
                  <div className="cost">
                    <p>{formatCurrency(goldCardCost - lakeCardCost)}</p>
                  </div>
                </div>
              </div>
              <div className="button-group">
                <Button
                  onClick={() => {
                    setSelectedMembershipType(MEMBERSHIP_LAKE_TO_GOLD_UPGRADE)
                    history.push(
                      makeMembershipIdPath(
                        membershipId,
                        PATH.UPGRADES,
                        PATH.MEMBERSHIP,
                        PATH.PAYMENT
                      )
                    )
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

GoldUpgradeSelected.propTypes = propTypes
GoldUpgradeSelected.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    membership: memberSelectors.membership(state),
    membershipPrices: memberSelectors.membershipPrices(state),
  }
}

const mapDispatchToProps = {
  setSelectedMembershipType: memberActions.setSelectedMembershipType,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  GoldUpgradeSelected
)
