import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { SectionHeader } from 'components'
import { ProgressSteps } from '../components'
import { HomePortTypeForm } from '../forms'

const propTypes = {
  nextStep: PropTypes.string.isRequired,
}

const defaultProps = {}

function HomePortTypeSection({ nextStep }) {
  const history = useHistory()
  const location = useLocation()
  return (
    <section className="form-block-container">
      <div className="card card-medium">
        <div className="card-inner">
          <div className="form-block">
            <ProgressSteps activeStep={1} totalSteps={3} />
            <SectionHeader
              sectionHeaderName="Set Home Port Location"
              sectionDescription="Understanding where you boat most often enables us to assign you to a home service area and a local Sea Tow Captain."
            />
            <p>
              <em>
                Note:  You are still covered 100% outside of this location in your designated service area of inland freshwaters or all salt + freshwater nationwide.
              </em>
            </p>
            <HomePortTypeForm
              onSubmit={(homePortTypeFormData) => {
                const newLocation = {
                  pathname: nextStep,
                  state: {
                    portWizardData: {
                      ...location.state.portWizardData,
                      ...homePortTypeFormData,
                    },
                    formData: location.state.formData,
                    returnUrl: location.state.returnUrl,
                    boat: location.state.boat,
                  },
                }
                history.push(newLocation)
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

HomePortTypeSection.propTypes = propTypes
HomePortTypeSection.defaultProps = defaultProps

export default HomePortTypeSection
