import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useHistory } from 'react-router-dom'
import { Button } from 'lp-components'
import { LocationSelectorForm } from '../forms'
import { QUERY_KEY, SAVINGS_CLUB_SORT } from 'config'
import { SearchCriteriaType } from 'types'
import { useToggle } from 'utils'

const propTypes = {
  makeSearchQuery: PropTypes.func.isRequired,
  searchCriteria: SearchCriteriaType.isRequired,
}
const defaultProps = {}

function LocationSelector({ makeSearchQuery, searchCriteria }) {
  const history = useHistory()
  const [editLocation, toggleEditLocation] = useToggle(false)
  const zip = searchCriteria[QUERY_KEY.ZIP]

  return (
    <div className="filter-block">
      <h3>Deals Near Me</h3>
      {editLocation ? (
        <LocationSelectorForm
          close={toggleEditLocation}
          enableReinitialize
          initialValues={{ zip }}
          onSubmit={({ zip: newZip }) =>
            history.push(
              makeSearchQuery(searchCriteria, {
                [QUERY_KEY.ZIP]: newZip,
                [QUERY_KEY.SORT]: SAVINGS_CLUB_SORT.DISTANCE_FROM_ME,
              })
            )
          }
        />
      ) : (
        <div>
          <p>
            {zip}{' '}
            <Button onClick={toggleEditLocation} className="link-primary">
              (Edit)
            </Button>
          </p>
        </div>
      )}
    </div>
  )
}

LocationSelector.propTypes = exact(propTypes)
LocationSelector.defaultProps = defaultProps

export default pure(LocationSelector)
