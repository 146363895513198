import React from 'react'
import PropTypes from 'prop-types'
import {
  AccountType,
  AppliedDiscountCodeType,
  BillingType,
  ProductPrices,
  MembershipType,
} from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ApplicationSteps, MembershipSummary } from 'member-portal/components'
import { selectors as memberSelectors } from 'member-reducer'
import { makeMembershipIdPath, makePath, PATH } from 'config'

const propTypes = {
  account: AccountType.isRequired,
  billing: BillingType,
  children: PropTypes.node.isRequired,
  discountCodeDetails: AppliedDiscountCodeType,
  membership: MembershipType.isRequired,
  selectedMembershipType: PropTypes.string.isRequired,
  selectedTrailerCareType: PropTypes.string.isRequired,
  membershipPrices: ProductPrices.isRequired,
  trailerCarePrices: ProductPrices.isRequired,
}

const defaultProps = {}

function RenewalApplicationLayout({
  account,
  billing,
  children,
  discountCodeDetails,
  membership,
  membershipPrices,
  selectedMembershipType,
  selectedTrailerCareType,
  trailerCarePrices,
}) {
  const { pathname } = useLocation()
  const { sfid: membershipId } = membership
  const basePath = makeMembershipIdPath(membershipId, PATH.RENEW_MEMBERSHIP)
  const { referral_credits__c } = account

  return (
    <div className="form-with-aside-wrapper">
      <header className="header-title">
        <h1>Renew Membership</h1>
      </header>
      <div className="form-with-aside-block">
        <div className="form-with-aside">
          <ApplicationSteps
            pathname={pathname}
            stepLinks={renewalStepLinks(basePath)}
          >
            {children}
          </ApplicationSteps>
        </div>
        <aside>
          <MembershipSummary
            donation={billing && billing.finalDonation}
            membershipToRenew={membership}
            membershipType={selectedMembershipType}
            referralCredits={referral_credits__c}
            trailerCareType={selectedTrailerCareType}
            updateLink={makePath(basePath, PATH.SELECTION)}
            {...{
              discountCodeDetails,
              membershipPrices,
              trailerCarePrices,
            }}
          />
        </aside>
      </div>
    </div>
  )
}

RenewalApplicationLayout.propTypes = propTypes
RenewalApplicationLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    account: memberSelectors.account(state),
    billing: memberSelectors.billing(state),
    discountCodeDetails: memberSelectors.discountCodeDetails(state),
    membership: memberSelectors.membership(state),
    selectedMembershipType: memberSelectors.selectedMembershipType(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
    membershipPrices: memberSelectors.membershipPrices(state),
    trailerCarePrices: memberSelectors.trailerCarePrices(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RenewalApplicationLayout
)

const renewalStepLinks = (basePath) => {
  return [
    {
      stepName: 'Contact Info',
      path: makePath(basePath, PATH.CONTACT),
      stepIcon: '/assets/images/icons/with-background/ST_YIcon_Account.svg',
    },
    {
      stepName: 'Primary Boat Information',
      path: makePath(basePath, PATH.BOATS),
      stepIcon: '/assets/images/icons/with-background/ST_YIcon_Prim-Boat.svg',
    },
    {
      stepName: 'Payment',
      path: makePath(basePath, PATH.PAYMENT),
      stepIcon: '/assets/images/icons/with-background/ST_YIcon_Payment.svg',
    },
    {
      stepName: 'Review Your Order',
      path: makePath(basePath, PATH.REVIEW_ORDER),
      stepIcon: '/assets/images/icons/with-background/ST_YIcon_Dashboard.svg',
    },
  ]
}
