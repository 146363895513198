import { isEmpty } from 'lodash'
import { API_ERROR_MAP } from 'types'

export default function apiErrorToErrorMessage(error) {
  if (isEmpty(error)) return API_ERROR_MAP.UNKNOWN_ERROR

  const errorMessage = API_ERROR_MAP[error] ?? API_ERROR_MAP.UNKNOWN_ERROR

  return `${errorMessage}<br/><br/>[${error}]`
}
