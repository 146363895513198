import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Header, Footer, SkipNavLink, SiteMetadata } from 'components'
import { selectors as globalSelectors } from 'global-reducer'
import { ContactType } from 'types'
import { MEMBER_PORTAL_NAVIGATION_LINKS, SITE_METADATA } from 'config'
import { scrollToTop } from 'utils'

const propTypes = {
  primaryContact: ContactType.isRequired,
  children: PropTypes.node.isRequired,
}
const defaultProps = {}

function Layout({ primaryContact, children }) {
  const { pathname } = useLocation()
  const { metaTitle, description, keywords } = SITE_METADATA.MEMBERS
  // Scroll to top of page when route changes
  useEffect(() => {
    scrollToTop()
  }, [pathname])
  return (
    <div>
      <SiteMetadata
        metaTitle={metaTitle}
        description={description}
        keywords={keywords}
      />
      <SkipNavLink targetId="main-content">Skip to main content</SkipNavLink>
      <Header
        applicationLinks={MEMBER_PORTAL_NAVIGATION_LINKS}
        primaryContact={primaryContact}
      />
      <main id="main-content">{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    primaryContact: globalSelectors.primaryContact(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
