import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'lp-components'

const propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const defaultProps = {}

function UnEnrollAutoRenewalWarningModal({ handleClose }) {
  return (
    <Modal onClose={handleClose}>
      <p>
        The promotional code applied requires automatic renewal. Please check
        automatic renewal or remove the promotional code to continue.
      </p>
      <div>
        <Button onClick={handleClose}>Close</Button>
      </div>
    </Modal>
  )
}

UnEnrollAutoRenewalWarningModal.propTypes = propTypes
UnEnrollAutoRenewalWarningModal.defaultProps = defaultProps

export default UnEnrollAutoRenewalWarningModal
