import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { PageHeader } from 'components'
import {
  PATH,
  makeMemberPortalPath,
  makeMembershipsPath,
  makeProgramsPortalPath,
} from 'config'

const propTypes = {}
const defaultProps = {}

function SeaTow() {
  return (
    <>
      <PageHeader headerText="Sea Tow.com" />
      <div className="dashboard-block-container">
        <Link to={PATH.MEMBER_PORTAL}>Member Portal</Link>
        <Link to={PATH.PROGRAMS_PORTAL}>Programs Portal</Link>
        <Link to={makeMemberPortalPath(PATH.MEMBER_APPLICATION)}>
          Apply As A Member
        </Link>
        <Link to={makeProgramsPortalPath(PATH.APPLY)}>
          Apply As A Programs Participant
        </Link>
        <Link
          to={makeMembershipsPath(
            PATH.SELECT_YOUR_MEMBERSHIP,
            PATH.UPGRADES,
            PATH.MEMBERSHIP
          )}
        >
          Upgrade from Lake to Gold Membership
        </Link>
        <Link
          to={makeMembershipsPath(
            PATH.SELECT_YOUR_MEMBERSHIP,
            PATH.UPGRADES,
            PATH.TRAILER_CARE
          )}
        >
          Upgrade Trailer Care Package
        </Link>
        <Link
          to={makeMembershipsPath(
            PATH.SELECT_YOUR_MEMBERSHIP,
            PATH.RENEW_MEMBERSHIP
          )}
        >
          Renew Membership (with Membership Selection)
        </Link>
        <Link to={makeMemberPortalPath(PATH.SEA_TOW_AS_A_GIFT)}>
          Sea Tow as a Gift
        </Link>
      </div>
    </>
  )
}

SeaTow.propTypes = propTypes
SeaTow.defaultProps = defaultProps

function mapStateToProps(/* state */) {
  return {}
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SeaTow)
