import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import {
  Field,
  FormSection,
  SubmissionError,
  formValues,
  propTypes as formPropTypes,
} from 'redux-form'
import { pick } from 'lodash'
import { lpForm } from 'lp-form'
import { Input, Checkbox, MaskedInput, SubmitButton } from 'lp-components'
import { PartnerAccountFields } from 'programs-portal/forms'
import { ADDRESS_FORM_VALIDATOR, COUNTRY, PHONE_REGION_CODE } from 'config'
import { PARTNER_PROGRAM_FIELDS } from 'types'
import { parsePhoneNumber } from 'utils'

const propTypes = {
  mailingAddressSameAsPublic: PropTypes.bool,
  mailingCountry: PropTypes.string,
  multipleLocation: PropTypes.bool,
  publicCountry: PropTypes.string,
  savingsClub: PropTypes.bool,
  ...formPropTypes,
}
const defaultProps = {
  publicCountry: COUNTRY.UNITED_STATES,
}

function NewParticipantApplicationForm({
  change,
  handleSubmit,
  mailingAddressSameAsPublic,
  mailingCountry,
  multipleLocation,
  pristine,
  publicCountry,
  savingsClub,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="group-block">
        <h3 className="group-title">
          I would like to apply to participate in:
        </h3>
        <div className="group options-with-image">
          <Service
            description="Promote your business to Sea Tow members by providing deals or discounts on your products and services that are exclusive to active Sea Tow members."
            formFieldName="savingsClub"
            heading="Savings Club"
            image="/assets/images/imagery/programs-portal/savings-club.jpg"
          />
          <Service
            description="Include Sea Tow memberships with the purchase of a boat, trailer, engine, or other product or service to ensure your customers have complete peace of mind while boating."
            formFieldName="seaCare"
            heading="Sea Care"
            image="/assets/images/imagery/programs-portal/seacare.jpg"
          />
          <Service
            description="Sell Sea Tow memberships at your establishment by stocking Sea Tow membership kits. Wholesale rates on memberships to vendors allow you to earn with each sale."
            formFieldName="membershipKits"
            heading="Membership Kits"
            image="/assets/images/imagery/programs-portal/membership-kits.jpg"
          />
        </div>
      </div>
      <hr />
      <div className="group-block">
        <h3 className="group-title">Primary Contact Information</h3>
        <FormSection name="contact">
          <div className="row">
            <div className="col-6">
              <Field
                component={Input}
                label="First Name"
                name="firstName"
                required
                requiredIndicator="*"
              />
            </div>
            <div className="col-6">
              <Field
                component={Input}
                label="Last Name"
                name="lastName"
                required
                requiredIndicator="*"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Field
                component={Input}
                label="Email"
                name="email"
                required
                requiredIndicator="*"
              />
            </div>
            <div className="col-6">
              <Field
                component={MaskedInput}
                label="Phone"
                maskOptions={{
                  numericOnly: true,
                  blocks: [0, 3, 0, 3, 4],
                  delimiters: ['(', ')', ' ', '-'],
                  phoneRegionCode: PHONE_REGION_CODE[publicCountry],
                }}
                name="phone"
                parse={parsePhoneNumber}
                placeholder="(800) 555-1212"
                required
                requiredIndicator="*"
              />
            </div>
          </div>
        </FormSection>
      </div>

      {savingsClub && (
        <>
          <hr />
          <div className="group-block">
            <h3 className="group-title">User Information</h3>
            <div className="row">
              <div className="col-6">
                <Field
                  component={Input}
                  label="Username (Email)"
                  name="userName"
                  placeholder="user@example.com"
                  required
                  requiredIndicator="*"
                />
              </div>
            </div>
          </div>
        </>
      )}
      <hr />
      <div className="group-block">
        <h3 className="group-title">Business Information</h3>
        <FormSection name="account">
          <PartnerAccountFields
            {...{
              change,
              mailingAddressSameAsPublic,
              mailingCountry,
              multipleLocation,
              publicCountry,
            }}
          />
        </FormSection>
      </div>
      <hr />
      <div className="group-block">
        <h3 className="group-title">Terms & Conditions – Sea Tow Programs</h3>
        <div className="scrollable-terms">
          <h4>Savings Club</h4>
          <p>
            Sea Tow and Participant, for their mutual benefit, agree as follows:
            Sea Tow agrees review application for participation in the program.
            If application is approved, Sea Tow agrees to include Participant in
            its Sea Tow Savings Club. Participant agrees to offer Sea Tow
            members in good standing the mutually agreed upon and approved sales
            incentive(s) for a minimum period of one (1) year from the date of
            this Agreement. Participant agrees to manage their sales incentive
            listings via the online Sea Tow Program Portal and to review them at
            least quarterly for accuracy. Participant agrees to display Sea Tow
            Savings Club promotional materials, as supplied or approved by Sea
            Tow. Participant agrees that it will only use the Sea Tow name, logo
            or trademarks as supplied or approved by Sea Tow. Participant agrees
            that Sea Tow may use its name, logo or trademark only in connection
            with the Sea Tow Savings Club on seatow.com, in Sea Tow member
            communications and similar. Participant agrees to indemnify Sea Tow
            for any loss, cost or expense resulting from this affiliation.
            Participant acknowledges that it has a full and complete
            understanding of the terms of this Agreement and that it has not
            relied on any promise, statement or representation other than those
            contained herein. Jurisdiction and venue for actions arising under
            this Agreement shall be in the State of New York. This Agreement
            will automatically renew unless one party notifies the other in
            writing within thirty days of the end of the first year. After one
            year, either party can terminate this Agreement by giving
            thirty-days notice in writing via email with confirmation of receipt
            to{' '}
            <a href="mailto:workwithseatow@seatow.com">
              workwithseatow@seatow.com
            </a>{' '}
            or via certified mail to Sea Tow Services International Inc., PO Box
            1178, Southold, NY 11971.
          </p>
          <h4>Sea Care</h4>
          <p>
            Sea Tow agrees to review application for participation in the
            program. If application is approved, and both Sea Tow and
            Participant come to terms on program participation, Sea Tow will
            provide a separate agreement specifying details for both parties to
            execute.
          </p>
          <h4>Membership Kits</h4>
          <p>
            Sea Tow agrees to review application for participation in the
            program. If application is approved, and both Sea Tow and
            Participant come to terms on program participation, Sea Tow will
            provide a separate agreement specifying details for both parties to
            execute.
          </p>
          <h4>All Programs</h4>
          <p>Terms and Conditions are subject to change.</p>
        </div>
        <Field
          component={Checkbox}
          label="I agree to the terms above"
          name="termsAgreement"
        />
      </div>

      <SubmitButton {...{ pristine, submitting }}>
        Submit Application
      </SubmitButton>
    </form>
  )
}

NewParticipantApplicationForm.propTypes = propTypes
NewParticipantApplicationForm.defaultProps = defaultProps

const beforeSubmit = (formValues) => {
  const {
    account: { mailingAddressSameAsPublic },
  } = formValues

  // If the mailing address was set to be the same as the public address,
  // set all of the mailing address-related fields to null.
  if (mailingAddressSameAsPublic) {
    formValues.mailingAddress = null
    formValues.mailingCity = null
    formValues.mailingState = null
    formValues.mailingPostalCode = null
    formValues.mailingCountry = null
  }

  // At least one of Savings Club, Sea Care, or Membership Kits must be
  // selected...
  const { savingsClub, seaCare, membershipKits } = formValues
  if (!savingsClub && !seaCare && !membershipKits) {
    throw new SubmissionError({
      _error: 'You must select at least one service',
    })
  }

  // Create account.servicesRequested from the set of selected services...
  // First, pick out the program-related values from the form values
  const formProgramsSelected = pick(
    formValues,
    Object.keys(PARTNER_PROGRAM_FIELDS)
  )

  // Then, map the truthy program values (programs selected) to their API values...
  const mappedProgramSelections = Object.entries(formProgramsSelected).reduce(
    (programs, [programKey, programValue]) => {
      // Consider only selected (truthy) program values
      if (programValue) {
        programs.push(PARTNER_PROGRAM_FIELDS[programKey])
      }

      return programs
    },
    []
  )
  const servicesRequested = mappedProgramSelections.join(';')

  formValues.account.servicesRequested = servicesRequested

  return formValues
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'NewParticipantApplicationForm',
    constraints: {
      ['account.description']: { presence: { allowEmpty: false } },
      ['account.businessName']: { presence: { allowEmpty: false } },
      ['contact.email']: { email: true, presence: true },
      ['contact.firstName']: { presence: { allowEmpty: false } },
      ['contact.lastName']: { presence: { allowEmpty: false } },
      ['account.mailingAddress']: ADDRESS_FORM_VALIDATOR,
      ['account.mailingCity']: { presence: { allowEmpty: false } },
      ['account.mailingCountry']: { presence: { allowEmpty: false } },
      ['account.mailingPostalCode']: { presence: { allowEmpty: false } },
      ['account.mailingState']: { presence: { allowEmpty: false } },
      ['account.phone']: { presence: { allowEmpty: false } },
      ['contact.phone']: { presence: { allowEmpty: false } },
      ['account.publicAddress']: ADDRESS_FORM_VALIDATOR,
      ['account.publicCity']: { presence: { allowEmpty: false } },
      ['account.publicCountry']: { presence: { allowEmpty: false } },
      ['account.publicEmail']: { email: true, presence: true },
      ['account.publicPostalCode']: { presence: { allowEmpty: false } },
      ['account.publicState']: { presence: { allowEmpty: false } },
      ['account.websiteUrl']: {
        presence: false,
        url: {
          message: 'is not valid, must contain "https://" or "http://"',
        },
      },
      termsAgreement: {
        presence: {
          message: 'must be accepted to apply as a participant',
        },
      },
      userName: { email: true, presence: true },
    },
    submitFilters: {
      reject: [
        'account.mailingAddressSameAsPublic',
        'membershipKits',
        'savingsClub',
        'seaCare',
        'termsAgreement',
      ],
    },
  }),
  formValues({ publicCountry: 'account.publicCountry' }),
  formValues({
    mailingAddressSameAsPublic: 'account.mailingAddressSameAsPublic',
  }),
  formValues({ mailingCountry: 'account.mailingCountry' }),
  formValues({ multipleLocation: 'account.multipleLocation' }),
  formValues('savingsClub')
)(NewParticipantApplicationForm)

function Service({ description, formFieldName, heading, image }) {
  return (
    <div className="option-with-image">
      <img alt="" src={image} />
      <div className="text-block">
        <h4>{heading}</h4>
        <p>{description}</p>
        <Field
          aria-label={heading}
          component={Checkbox}
          label=" "
          name={formFieldName}
        />
      </div>
    </div>
  )
}
