import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
  Field,
  change,
  formValues,
  propTypes as formPropTypes,
} from 'redux-form'
import { debounce, isNil, deburr } from 'lodash'
import { lpForm } from 'lp-form'
import { SubmitButton, Input } from 'lp-components'
import { BackLink, SearchResultsPanel } from 'components'
import { formatDisplayFromAddressSearchResult } from 'utils'

const propTypes = {
  autoCompleteAddress: PropTypes.func.isRequired,
  homePortLocation: PropTypes.object,
  previousFormStep: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
  ...formPropTypes,
}
const defaultProps = {}

function SearchPortAddressForm({
  autoCompleteAddress,
  handleSubmit,
  homePortLocation,
  previousFormStep,
  pristine,
  submitting,
  updateFormValues,
}) {
  const [searchResults, updateSearchResults] = useState(null)
  const [resultsPanelOpen, setResultsPanelOpen] = useState(false)
  const closeResultsPanel = () => setResultsPanelOpen(false)
  const openResultsPanel = () => setResultsPanelOpen(true)

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Field
        autoComplete="off"
        component={Input}
        id="address-search-input"
        label="Search Name, Address, or City, State"
        name="searchQuery"
        onChange={debounce(async (_, newSearchQuery) => {
          const normalizedSearchQuery = newSearchQuery.split(' ').join('+')
          const placeSearchResult = await autoCompleteAddress(
            normalizedSearchQuery
          )

          updateSearchResults(placeSearchResult.results)
          openResultsPanel()
        }, 350)}
        required
        requiredIndicator="*"
      />

      <SearchResultsPanel
        closePanel={closeResultsPanel}
        isPanelOpen={resultsPanelOpen}
        searchResults={searchResults}
        updateFormValues={updateFormValues}
      />

      <div className="button-navigation">
        <BackLink linkText="Back" to={previousFormStep} />
        <SubmitButton
          invalid={isNil(homePortLocation)}
          {...{ pristine, submitting }}
        >
          Continue
        </SubmitButton>
      </div>
    </form>
  )
}

// Omit the name for localities. If the user queries a locality they will be
// required to specify a location name in a subsequent wizard step...
function formatNameFromResult(searchResult) {
  const { name, types } = searchResult

  return types.includes('locality') ||
    types.includes('street_address') ||
    types.includes('premise')
    ? ''
    : deburr(name)
}

function mapDispatchToProps(dispatch) {
  return {
    updateFormValues: function(searchResult) {
      const formattedSearchResult = {
        ...searchResult,
        formatted_address: deburr(searchResult.formatted_address),
      }
      dispatch(
        change(
          'SearchPortAddressForm',
          'searchQuery',
          formatDisplayFromAddressSearchResult(formattedSearchResult)
        )
      )
      dispatch(
        change(
          'SearchPortAddressForm',
          'homePortLocation',
          formattedSearchResult
        )
      )
      dispatch(
        change(
          'SearchPortAddressForm',
          'homePortName',
          formatNameFromResult(formattedSearchResult)
        )
      )
    },
  }
}

SearchPortAddressForm.propTypes = propTypes
SearchPortAddressForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'SearchPortAddressForm',
    constraints: {
      searchQuery: { presence: { allowEmpty: false } },
    },
    submitFilters: { reject: ['searchQuery'] },
  }),
  formValues('homePortLocation'),
  connect(null, mapDispatchToProps)
)(SearchPortAddressForm)
