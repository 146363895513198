import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { isNil } from 'lodash'
import { useAuth0 } from '@auth0/auth0-react'
import { Spinner } from 'lp-components'
import {
  LP_API_STATUS_LOADING,
  LP_API_STATUS_SUCCESS,
  selectors as apiSelectors,
} from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { API_KEY_MAP, HEROKU_ID_KEY } from 'types'
import { PATH, makeMemberPortalPath } from 'config'
import {
  apiValuesWithMappedKeys,
  forceMemberPortalReload,
  withApiAuth,
} from 'utils'

const propTypes = {
  registrationStatus: PropTypes.string,
  verifyMembership: PropTypes.func.isRequired,
}
const defaultProps = {}

function NewMemberRegistration({ registrationStatus, verifyMembership }) {
  const { user } = useAuth0()
  const { sub: auth0UserId } = user
  const { state } = useLocation()
  const externalMembershipId = state?.externalMembershipId
  const membershipExpirationDate = state?.membershipExpirationDate
  const membershipNumber = state?.membershipNumber

  useEffect(() => {
    const registrationApiValues = apiValuesWithMappedKeys(
      {
        membershipExpirationDate,
        membershipNumber,
      },
      API_KEY_MAP.REGISTRATION_KEY_MAP
    )

    verifyMembership({
      ...registrationApiValues,
      auth0_user_id: auth0UserId,
      [HEROKU_ID_KEY]: externalMembershipId,
    })
  }, [])

  if (
    isNil(registrationStatus) ||
    registrationStatus === LP_API_STATUS_LOADING
  ) {
    return <Spinner />
  }

  if (registrationStatus === LP_API_STATUS_SUCCESS) {
    forceMemberPortalReload()
    return null
  }

  return <Redirect to={makeMemberPortalPath(PATH.REGISTRATION, PATH.ERROR)} />
}

NewMemberRegistration.propTypes = propTypes
NewMemberRegistration.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    registrationStatus: apiSelectors.status(state, apiActions.verifyMembership),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  verifyMembership: apiActions.verifyMembership,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(NewMemberRegistration)
