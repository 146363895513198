import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { Link } from 'react-router-dom'
import { lpForm } from 'lp-form'
import { Select, SubmitButton } from 'lp-components'
import { MEMBERSHIP_CARD_REASONS } from 'types'
import { PATH, QUERY_KEY, makeMemberPortalPath } from 'config'
import { makeQueryPath } from 'utils'

const propTypes = {
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  postalcode: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  ...formPropTypes,
}
const defaultProps = {}

function NewMembershipCardForm({
  address,
  city,
  country,
  handleSubmit,
  postalcode,
  pristine,
  state,
  submitting,
}) {
  return (
    <div className="card">
      <header>
        <img
          src="/assets/images/icons/with-background/ST_YIcon_Mail-Card.svg"
          alt=""
          className="icon"
        />
        <h2>Request a new card in the mail</h2>
      </header>
      <div className="card-inner">
        <form onSubmit={handleSubmit} noValidate>
          <p>
            It usually takes about 2 weeks to receive your requested membership
            card in the mail.
          </p>
          <Field
            component={Select}
            label="Reason for a new card"
            options={MEMBERSHIP_CARD_REASONS}
            placeholder="Select"
            name="cardReason"
            required
            requiredIndicator="*"
          />
          <h3>Mailing Address</h3>
          <Link
            to={makeQueryPath(makeMemberPortalPath(PATH.ACCOUNT), {
              [QUERY_KEY.FORM_OPEN]: true,
            })}
            className="link-primary"
          >
            (Edit)
          </Link>
          <p>
            {address}, {city}, {state} {postalcode} <br /> {country}
          </p>

          <SubmitButton
            {...{ pristine, submitting }}
          >
            Submit
          </SubmitButton>
        </form>
      </div>
    </div>
  )
}

NewMembershipCardForm.propTypes = propTypes
NewMembershipCardForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'NewMembershipCardForm',
    constraints: {
      cardReason: { presence: true },
    },
  })
)(NewMembershipCardForm)
