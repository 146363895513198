import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Button } from 'lp-components'
import { BillingAddressEditForm } from '../forms'
import { API_KEY_MAP, AccountType } from 'types'
import {
  apiValuesWithMappedKeys,
  formValuesWithMappedKeys,
  setObjectIdentifiers,
  useToggle,
} from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  refresh: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
}
const defaultProps = {}

function BillingAddress({ account, refresh, update }) {
  const [editView, toggleEditView] = useToggle()
  return (
    <div className="card">
      <BillingAddressHeader />

      {editView ? (
        <BillingAddressEditForm
          closeEditForm={toggleEditView}
          initialValues={formValuesWithMappedKeys(
            account,
            API_KEY_MAP.BILLING_ADDRESS_KEYMAP
          )}
          onSubmit={(billingAddressFormValues) => {
            const billingAddressApiValues = apiValuesWithMappedKeys(
              billingAddressFormValues,
              API_KEY_MAP.BILLING_ADDRESS_KEYMAP
            )

            return update(
              setObjectIdentifiers(billingAddressApiValues, account)
            )
          }}
          onSubmitSuccess={() => {
            refresh()
            toggleEditView()
          }}
        />
      ) : (
        <BillingAddressView account={account} openEditView={toggleEditView} />
      )}
    </div>
  )
}

BillingAddress.propTypes = exact(propTypes)
BillingAddress.defaultProps = defaultProps

export default pure(BillingAddress)

function BillingAddressHeader() {
  return (
    <header>
      <img
        src="/assets/images/icons/with-background/ST_YIcon_BusAddress.svg"
        alt=""
        className="icon"
      />
      <h2>Billing Address</h2>
    </header>
  )
}

function BillingAddressView({ account, openEditView }) {
  const {
    billingcity,
    billingcountry,
    billingpostalcode,
    billingstate,
    billingstreet,
  } = account
  const billingStreetCityState = [billingstreet, billingcity, billingstate]
  const addressLine = `${billingStreetCityState.join(
    ', '
  )} ${billingpostalcode}`

  return (
    <div className="card-inner">
      <h3>Billing Address</h3>
      <Button onClick={openEditView} className="link-primary">
        (Edit)
      </Button>
      <p>
        {addressLine} <br /> {billingcountry}
      </p>
    </div>
  )
}
