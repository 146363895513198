import React from 'react'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { RadioGroup } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { ALL_OFFER_CATEGORIES, OFFER_CATEGORY } from 'types'

const propTypes = {
  ...formPropTypes,
}
const defaultProps = {}

function CategorySelectorForm() {
  return (
    <div className="filter-block">
      <h3 id="category-header">Category</h3>
      <form noValidate>
        <div aria-labelledby="category-header" role="radiogroup">
          <Field
            component={RadioGroup}
            label={false}
            name="category"
            options={[ALL_OFFER_CATEGORIES, ...Object.values(OFFER_CATEGORY)]}
          />
        </div>
      </form>
    </div>
  )
}

CategorySelectorForm.propTypes = propTypes
CategorySelectorForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'CategorySelectorForm',
    constraints: {},
  })
)(CategorySelectorForm)
