import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { ReviewStep } from 'member-portal/components'
import * as apiActions from 'api-actions'
import {
  AccountType,
  BillingType,
  HEROKU_ID_KEY,
  MEMBERSHIP_TYPE,
  MembershipType,
} from 'types'
import { makeMembershipIdPath, PATH } from 'config'
import { parseCreditCardAndBillingAddress, withApiAuth } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  billing: BillingType,
  chargeMembershipUpgrade: PropTypes.func.isRequired,
  membership: MembershipType.isRequired,
}
const defaultProps = {}

function GoldUpgradeReview({
  account,
  billing,
  chargeMembershipUpgrade,
  membership,
}) {
  const { sfid: membershipId } = membership
  return (
    <div>
      <ReviewStep
        account={account}
        confirmAndFinalizePurchase={() => {
          return chargeMembershipUpgrade(
            membershipUpgradeApiValues({
              billing,
              membership,
            })
          )
        }}
        membership={membership}
        showMembershipCost
        paymentPath={makeMembershipIdPath(
          membershipId,
          PATH.UPGRADES,
          PATH.MEMBERSHIP,
          PATH.PAYMENT
        )}
        nextStep={makeMembershipIdPath(
          membershipId,
          PATH.UPGRADES,
          PATH.MEMBERSHIP,
          PATH.CONFIRM_AND_LOGIN
        )}
      />
    </div>
  )
}

GoldUpgradeReview.propTypes = propTypes
GoldUpgradeReview.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    account: memberSelectors.account(state),
    billing: memberSelectors.billing(state),
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  chargeMembershipUpgrade: apiActions.chargeMembershipUpgrade,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(GoldUpgradeReview)

function membershipUpgradeApiValues({ billing, membership }) {
  const { subtotalWithoutDonations: total_cost } = billing
  const cc_details = parseCreditCardAndBillingAddress(billing)

  return {
    cc_details,
    total_cost,
    upgrading_from: MEMBERSHIP_TYPE.LAKE_CARD,
    upgrading_to: MEMBERSHIP_TYPE.GOLD_CARD,
    membership__c: {
      [HEROKU_ID_KEY]: membership[HEROKU_ID_KEY],
    },
  }
}
