import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ButtonLink, NotificationCenter } from 'components'
import { AccountType, NOTIFICATION_CLASS } from 'types'
import { isNil, pick, transform } from 'lodash'
import { PATH, QUERY_KEY, makeProgramsPortalPath } from 'config'
import { makeQueryPath } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
}
const defaultProps = {}

function ProgramsNotificationCenter({ account }) {
  const accountNotifications = generateMissingPartnerAccountNotifications(
    account
  )
  const notificationCount = accountNotifications.length

  return (
    <NotificationCenter notificationCount={notificationCount}>
      <ProgramsNotificationMessages
        accountNotifications={accountNotifications}
      />
    </NotificationCenter>
  )
}

ProgramsNotificationCenter.propTypes = exact(propTypes)
ProgramsNotificationCenter.defaultProps = defaultProps

export default pure(ProgramsNotificationCenter)

function ProgramsNotificationMessages({ accountNotifications }) {
  return (
    <>
      {accountNotifications.length > 0 && (
        <>
          <h3>Complete your registration</h3>
          {accountNotifications.map(({ message, notificationClass }, index) => (
            <div className={notificationClass} key={index}>
              <p>{message}</p>
              <ButtonLink
                className="link-primary"
                to={makeQueryPath(makeProgramsPortalPath(PATH.ACCOUNT), {
                  [QUERY_KEY.FORM_OPEN]: true,
                })}
              >
                Add Account Information
              </ButtonLink>
            </div>
          ))}
        </>
      )}
    </>
  )
}

const generateMissingPartnerAccountNotifications = (account) => {
  const requiredPartnerAccountPreferences = pick(account, [
    'name',
    'partner_description__c',
    'phone',
    'public_facing_email_address__c',
    'shippingcity',
    'shippingcountry',
    'shippingpostalcode',
    'shippingstate',
    'shippingstreet',
    'company_logo__c',
  ])

  return transform(
    Object.values(requiredPartnerAccountPreferences),
    (notifications, accountPreference) => {
      if (isNil(accountPreference)) {
        notifications.push({
          message:
            'We need some more information about your company. Click on the link on the right to complete this task.',
          notificationClass: NOTIFICATION_CLASS.DEFAULT,
        })
        return false
      }
    },
    []
  )
}
