import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Link } from 'react-router-dom'
import { BoatType } from 'types'
import { PATH, makeMembershipIdPath } from 'config'
import { boatMake } from 'utils'

const propTypes = {
  boats: PropTypes.arrayOf(BoatType),
  canAddMultipleBoats: PropTypes.bool.isRequired,
  membershipSfid: PropTypes.string.isRequired,
  memberNumber: PropTypes.string.isRequired,
}
const defaultProps = {}

function BoatAndHomePort({
  boats,
  canAddMultipleBoats,
  membershipSfid,
  memberNumber,
}) {
  return (
    <div className="detail-block">
      <BoatAndHomePortHeader
        membershipSfid={membershipSfid}
        memberNumber={memberNumber}
      />
      {boats.length > 0 ? (
        <>
          <PrimaryBoatAndHomePort boats={boats} />
          {canAddMultipleBoats && (
            <AdditionalBoats
              membershipSfid={membershipSfid}
              additionalBoats={
                boats.filter((boat) => boat.boat_status__c === 'Active')
                  .length - 1
              }
              memberNumber={memberNumber}
            />
          )}
        </>
      ) : (
        <p>No boats associated with this membership</p>
      )}
    </div>
  )
}

BoatAndHomePort.propTypes = exact(propTypes)
BoatAndHomePort.defaultProps = defaultProps

export default pure(BoatAndHomePort)

function BoatAndHomePortHeader({ membershipSfid, memberNumber }) {
  return (
    <div>
      <h4>Boat & Home Port Information</h4>
      <Link
        to={makeMembershipIdPath(membershipSfid, PATH.BOATS)}
        className="link-primary"
        aria-label={`Edit boat and homeport information for membership ${memberNumber}`}
      >
        (Edit)
      </Link>
    </div>
  )
}

function PrimaryBoatAndHomePort({ boats }) {
  const primaryBoat = boats.find(
    ({ primary_boat__c }) => primary_boat__c === true
  )

  if (!primaryBoat) return <p>ERROR: No primary boat for membership</p>

  return (
    <>
      <p>{boatMake(primaryBoat)}</p>
      <HomePort boat={primaryBoat} />
    </>
  )
}

function HomePort({ boat }) {
  const cityState = [boat.home_port_city__c, boat.home_port_state__c]

  return (
    <p>
      {boat.marina_name__c} <span className="divider">|</span>{' '}
      {cityState.join(', ')}
    </p>
  )
}

function AdditionalBoats({ additionalBoats, membershipSfid, memberNumber }) {
  return (
    <p className="small">
      {additionalBoats > 0 && <>{additionalBoats} Additional Boat(s)</>}
      <Link
        to={makeMembershipIdPath(membershipSfid, PATH.BOATS)}
        className="link-small"
        aria-label={`Manage additional boats for membership ${memberNumber}`}
      >
        Manage
      </Link>
      <Link
        to={makeMembershipIdPath(membershipSfid, PATH.BOATS, PATH.NEW)}
        className="link-small"
        aria-label={`Add new boat to membership ${memberNumber}`}
      >
        Add
      </Link>
    </p>
  )
}
