import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { isNil } from 'lodash'
import classnames from 'classnames'
import { IMAGE_PATH } from 'config'

const propTypes = {
  companyLogo: PropTypes.string,
  companyName: PropTypes.string.isRequired,
}
const defaultProps = {}

function PartnerLogo({ companyLogo, companyName }) {
  return (
    <img
      alt={`${companyName} logo`}
      className={classnames({ placeholder: isNil(companyLogo) })}
      src={companyLogo ?? IMAGE_PATH.DEFAULT_PARTNER_LOGO}
    />
  )
}

PartnerLogo.propTypes = exact(propTypes)
PartnerLogo.defaultProps = defaultProps

export default pure(PartnerLogo)
