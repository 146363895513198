import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'lp-components'
import { Aor } from 'components'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'
import { MembershipType } from 'types'
import { IMAGE_PATH, PATH, makeMemberPortalPath } from 'config'
import { formatUSMonthDayYearDateString, withApiAuth } from 'utils'

const propTypes = {
  fetchAllMembershipsForAccount: PropTypes.func.isRequired,
  membership: MembershipType.isRequired,
}
const defaultProps = {}

function RenewalConfirmation({ fetchAllMembershipsForAccount, membership }) {
  const history = useHistory()
  const {
    aor,
    membership_number__c: membershipNumber,
    membership_expiration_date__c: membershipExpirationDate,
    account__c: accountId,
  } = membership
  const formattedExpirationDate = formatUSMonthDayYearDateString(
    membershipExpirationDate
  )

  const goToMemberPortal = async () => {
    await fetchAllMembershipsForAccount(accountId)
    history.push(makeMemberPortalPath(PATH.ROOT))
  }

  return (
    <>
      <div className="form-with-aside-wrapper form-block-container confirmation">
        <div className="form-with-aside-block">
          <div className="form-with-aside">
            <div className="card card-medium">
              {/* Image needs to be replaced with the actual image to be used... */}
              <header>
                <img
                  alt=""
                  src={IMAGE_PATH.MEMBER_APPLICATION_CONFIRMATION}
                  className="confirmation-icon"
                />
              </header>
              <h2>THANK YOU! We look forward to continuing to serve you as a member.</h2>
              <hr className="divider" />
              <div className="text-block">
                <p>If you haven’t done so already, be sure to visit your online Member Center where you can:</p>
                <ul className="bullets">
                  <li>Add or update boat information</li>
                  <li>Add additional contacts and manage your communications preferences</li>
                  <li>Access deals and discounts through the Sea Tow Savings Club</li>
                  <li>Manage your Automatic Renewal subscription preferences</li>
                  <li>Update your address and contact details</li>
                  <li>Refer-a-Boater to Sea Tow</li>
                  <li>And so much more!</li>
                </ul>
              </div>
              <div className="row">
                <div className="col-6">
                  <p className="label">Membership #:</p>
                  <p className="large">{membershipNumber}</p>
                </div>
                <div className="col-6">
                  <p className="label">Membership Expiration:</p>
                  <p className="large">{formattedExpirationDate}</p>
                </div>
              </div>
              <div className="icon-and-text-callout">
                <img
                  src="/assets/images/icons/transparent/ST_Icon_Mail-Card.svg"
                  alt=""
                />
                <p>
                  Your updated membership card will be shipping in 3-7 days.
                </p>
              </div>
              <Button onClick={goToMemberPortal}>
                Go to Member Center
              </Button>
            </div>
          </div>
          <aside>
            <div className="card image-card">
              <Aor aor={aor}/>
            </div>
          </aside>
        </div>
      </div>
    </>
  )
}

RenewalConfirmation.propTypes = propTypes
RenewalConfirmation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  fetchAllMembershipsForAccount: apiActions.fetchAllMembershipsForAccount,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(RenewalConfirmation)
