import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { AccountType } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Spinner } from 'lp-components'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'

const propTypes = {
  account: AccountType,
  applicationAccountId: PropTypes.string,
  applicationMembershipId: PropTypes.string,
  children: PropTypes.node.isRequired,
  fetchMembershipApplicationAccount: PropTypes.func.isRequired,
}

const defaultProps = {}

function NewMemberApplicationAccountProvider({
  account,
  applicationAccountId,
  applicationMembershipId,
  children,
  fetchMembershipApplicationAccount,
}) {
  const hasStoredApplicationIds = !!(
    applicationAccountId && applicationMembershipId
  )

  useEffect(() => {
    if (hasStoredApplicationIds)
      fetchMembershipApplicationAccount(
        applicationAccountId,
        applicationMembershipId
      )
  }, [applicationAccountId, applicationMembershipId])

  // If Account exists then Contact and Membership (not checked for here) should also exist
  if (hasStoredApplicationIds && !account) return <Spinner />

  return children
}

NewMemberApplicationAccountProvider.propTypes = propTypes
NewMemberApplicationAccountProvider.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    account: memberSelectors.account(state),
    applicationAccountId: memberSelectors.applicationAccountId(state),
    applicationMembershipId: memberSelectors.applicationMembershipId(state),
  }
}

const mapDispatchToProps = {
  fetchMembershipApplicationAccount:
    apiActions.fetchMembershipApplicationAccount,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NewMemberApplicationAccountProvider
)
