import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { formatCurrency, formatUSMonthDayYearDate, parseISODate } from 'utils'

const propTypes = {
  autorenews: PropTypes.bool,
  invoiceTermDate: PropTypes.string,
  renewalCost: PropTypes.number.isRequired,
  withInvoiceTerms: PropTypes.bool,
}
const defaultProps = {}

function NextInvoice({
  autorenews,
  invoiceTermDate,
  renewalCost,
  withInvoiceTerms,
}) {
  return (
    <div className="card-inner">
      <NextInvoiceHeader
        autorenews={autorenews}
        renewalCost={renewalCost}
        invoiceTermDate={invoiceTermDate}
        withInvoiceTerms={withInvoiceTerms}
      />
    </div>
  )
}

NextInvoice.propTypes = exact(propTypes)
NextInvoice.defaultProps = defaultProps

export default pure(NextInvoice)

function NextInvoiceHeader({
  autorenews,
  invoiceTermDate,
  renewalCost,
  withInvoiceTerms,
}) {
  return (
    <div className="row">
      <div className="col-6">
        <h3>Next Payment Amount</h3>
        <p className="large">{formatCurrency(renewalCost)}</p>
      </div>
      <div className="col-6">
        {withInvoiceTerms && (
          <>
            {autorenews ? (
              <h3>
                Automatically Renews <span>(10 days prior to expiration)</span>
              </h3>
            ) : (
              <h3>Membership Expires</h3>
            )}
            <p className="large">
              {formatUSMonthDayYearDate(parseISODate(invoiceTermDate))}
            </p>
          </>
        )}
      </div>
    </div>
  )
}
