import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Routes as LakeToGoldRoutes } from './lake-to-gold'
import { Routes as TrailerCareRoutes } from './trailer-care'
import { PATH, makePath } from 'config'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={makePath(path, PATH.MEMBERSHIP)}>
        <LakeToGoldRoutes />
      </Route>
      <Route path={makePath(path, PATH.TRAILER_CARE)}>
        <TrailerCareRoutes />
      </Route>
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
