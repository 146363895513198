import React from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import MemberApplicationLayout from './MemberApplicationLayout'
import {
  NewMemberApplicationAccountProvider,
  QuestionnaireRedirect,
} from './components'
import {
  MarketingCodeProvider,
  MembershipUpdater,
} from 'member-portal/components'
import { PATH, makePath, makeMemberApplicationApplyPath } from 'config'

const propTypes = {}

const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <QuestionnaireRedirect>
      <MarketingCodeProvider>
        <Layout>
          <Switch>
            {/* Pre-application Routes */}
            <Route exact path={makePath(path, PATH.ROOT)}>
              <Views.NewOrRenew />
            </Route>
            <Route path={makePath(path, PATH.NEW_MEMBER_QUESTIONNAIRE)}>
              <Views.NewMemberQuestionnaire />
            </Route>
            <Route path={makePath(path, PATH.MEMBERSHIP_TYPE_SELECTED)}>
              <Views.NewMemberSelectedMembershipType />
            </Route>
            <Route path={makePath(path, PATH.MEMBERSHIP_TYPE_ALL)}>
              <Views.AllMembershipTypes />
            </Route>
            {/* Boat Routes */}
            <Route exact path={makePath(path, PATH.BOATS, PATH.HOME_PORT_TYPE)}>
              <Views.NewMemberHomePortType />
            </Route>
            <Route
              exact
              path={makePath(path, PATH.BOATS, PATH.HOME_PORT_ADDRESS)}
            >
              <Views.NewMemberSearchAddress />
            </Route>
            <Route
              exact
              path={makePath(path, PATH.BOATS, PATH.HOME_PORT_LOCATION)}
            >
              <Views.NewMemberLocatePort />
            </Route>
            {/* Post-application Routes */}
            <Route path={makePath(path, PATH.CONFIRM_AND_LOGIN)}>
              <NewMemberApplicationAccountProvider>
                <Views.FinalConfirmationAndLogin />
              </NewMemberApplicationAccountProvider>
            </Route>
            {/* Application Routes */}
            <Route exact path={makeMemberApplicationApplyPath(PATH.ROOT)}>
              <Redirect to={makeMemberApplicationApplyPath(PATH.CONTACT)} />
            </Route>
            <Route>
              <NewMemberApplicationAccountProvider>
                <MembershipUpdater>
                  <MemberApplicationLayout>
                    <Switch>
                      <Route
                        exact
                        path={makeMemberApplicationApplyPath(PATH.CONTACT)}
                      >
                        <Views.MemberContactInfo />
                      </Route>
                      <Route
                        exact
                        path={makeMemberApplicationApplyPath(PATH.CONTACT_2)}
                      >
                        <Views.MemberContactInfo />
                      </Route>
                      <Route
                        exact
                        path={makeMemberApplicationApplyPath(PATH.BOATS)}
                      >
                        <Views.NewMemberBoatSelection />
                      </Route>
                      <Route
                        exact
                        path={makeMemberApplicationApplyPath(PATH.PAYMENT)}
                      >
                        <Views.NewMemberPayment />
                      </Route>
                      <Route
                        exact
                        path={makeMemberApplicationApplyPath(PATH.REVIEW_ORDER)}
                      >
                        <Views.NewMemberReviewOrder />
                      </Route>
                    </Switch>
                  </MemberApplicationLayout>
                </MembershipUpdater>
              </NewMemberApplicationAccountProvider>
            </Route>
          </Switch>
        </Layout>
      </MarketingCodeProvider>
    </QuestionnaireRedirect>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
