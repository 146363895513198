import React from 'react'
import PropTypes from 'prop-types'
import { ExternalLink } from 'components'

const propTypes = {
  type: PropTypes.string.isRequired,
  url: PropTypes.string,
}
const defaultProps = {}

function DigitalWalletPass({ type, url }) {
  return url ? (
    <div className="digital-pass">
      <ExternalLink showIcon={false} href={url}>
        <img
          src={`/assets/images/imagery/digital-wallet/add-to-${type.toLowerCase()}-wallet.svg`}
          alt={`Add to ${type} Wallet`}
        />
      </ExternalLink>
    </div>
  ) : null
}

DigitalWalletPass.propTypes = propTypes
DigitalWalletPass.defaultProps = defaultProps

export default React.memo(DigitalWalletPass)
