import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import {
  Select,
  CheckboxGroup,
  Input,
  Textarea,
  Checkbox,
  SubmitButton,
  MaskedInput,
} from 'lp-components'
import { ExternalLink } from 'components'
import {
  Field,
  formValues,
  propTypes as formPropTypes,
  SubmissionError,
} from 'redux-form'
import {
  OFFER_CATEGORY,
  OFFER_TYPE,
  OFFER_ONLINE_REDEMPTION_OPTION,
  OFFER_IN_PERSON_REDEMPTION_OPTION,
  REDEMPTION_OPTIONS_WITH_DETAILS,
} from 'types'
import { makePath, PATH } from 'config'
import {
  checkboxGroupSplit,
  checkboxGroupJoin,
  parseNumberFromString,
} from 'utils'
import { values } from 'lodash'

const propTypes = {
  primaryCategory: PropTypes.string,
  offerType: PropTypes.string,
  online: PropTypes.bool,
  inPerson: PropTypes.bool,
  onlineRedemptionOption: PropTypes.string,
  inPersonRedemptionOption: PropTypes.string,
  ...formPropTypes,
}

const defaultProps = {}

function checkForRedemptionDetails(redemptionOption) {
  return REDEMPTION_OPTIONS_WITH_DETAILS.includes(redemptionOption)
}

function OfferForm({
  handleSubmit,
  submitting,
  pristine,
  change,
  primaryCategory,
  offerType,
  online,
  inPerson,
  onlineRedemptionOption,
  inPersonRedemptionOption,
}) {
  const offerCategories = values(OFFER_CATEGORY)
  const additionalCategories = offerCategories.filter(
    (category) =>
      category !== primaryCategory && category !== OFFER_CATEGORY.OTHER
  )
  const hasOnlineRedemptionDetails = checkForRedemptionDetails(
    onlineRedemptionOption
  )
  const hasInPersonRedemptionDetails = checkForRedemptionDetails(
    inPersonRedemptionOption
  )

  // Clear nested fields when parent option is changed
  // to strip data that doesn't apply to parent selection
  const clearFormFields = (fieldNames) =>
    fieldNames.forEach((fieldName) => change(fieldName, null))

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="group-block">
        <h3 className="group-title">Category</h3>
        <p>
          By selecting the categories your offer falls into, you'll increase the
          visibility of your offer when members are searching Savings Club.
        </p>
        <div className="row">
          <div className="col-6">
            <Field
              component={Select}
              label="Primary Category of Offer"
              name="primaryCategory"
              options={offerCategories}
              placeholder="Select"
              required
              requiredIndicator="*"
            />
          </div>
        </div>
        {primaryCategory && primaryCategory !== OFFER_CATEGORY.OTHER && (
          <Field
            component={CheckboxGroup}
            label="Additional Offer Categories (Choose up to 3)"
            name="secondaryCategories"
            options={additionalCategories}
            format={checkboxGroupSplit}
            parse={checkboxGroupJoin}
          />
        )}
      </div>
      <hr />
      <div className="group-block">
        <h3 className="group-title">Details</h3>
        <div className="row">
          <div className="col-6">
            <Field
              component={Select}
              label="Type of Offer"
              name="offerType"
              options={values(OFFER_TYPE)}
              placeholder="Select"
              required
              requiredIndicator="*"
              onChange={() =>
                clearFormFields([
                  'dollarsOff',
                  'percentageOff',
                  'otherTypeDescription',
                ])
              }
            />
          </div>
          {offerType && (
            <div className="col-6">
              {offerType === OFFER_TYPE.DOLLARS_OFF && (
                <Field
                  component={MaskedInput}
                  name="dollarsOff"
                  normalize={parseNumberFromString}
                  maskOptions={{
                    noImmediatePrefix: true,
                    numeral: true,
                    numeralPositiveOnly: true,
                    prefix: '$',
                  }}
                  required
                  requiredIndicator="*"
                />
              )}
              {offerType === OFFER_TYPE.PERCENTAGE_OFF && (
                <Field
                  component={MaskedInput}
                  name="percentageOff"
                  normalize={parseNumberFromString}
                  maskOptions={{
                    noImmediatePrefix: true,
                    numeral: true,
                    numeralPositiveOnly: true,
                    numeralDecimalScale: 0,
                    prefix: '%',
                    tailPrefix: true,
                  }}
                  required
                  requiredIndicator="*"
                />
              )}
              {offerType === OFFER_TYPE.OTHER && (
                <Field
                  component={Input}
                  label="Describe Offer Type"
                  name="otherTypeDescription"
                  placeholder="Buy one; get one free"
                  required
                  requiredIndicator="*"
                />
              )}
            </div>
          )}
        </div>
        <Field
          component={Textarea}
          label="Offer Description"
          name="description"
          placeholder="Description of the services or products the offer is available for and any details like promotional period or limited time."
          required
          requiredIndicator="*"
        />
      </div>
      <hr />
      <div className="group-block">
        <h3 className="group-title">Where offer can be redeemed:</h3>
        <Field
          component={Checkbox}
          name="online"
          onChange={(_, checked) => {
            if (!checked)
              // Don't clear websiteUrl so that it is always pre-populated
              // with the account website (unless changed by the user)
              // even if this is checked/unchecked, or the redemption option changes
              clearFormFields([
                'onlineRedemptionOption',
                'promoCode',
                'onlineRedemptionInstructions',
              ])
          }}
        />
        {online && (
          <div className="row">
            <div className="col-5">
              <Field
                component={Select}
                label="Option for Online"
                name="onlineRedemptionOption"
                options={values(OFFER_ONLINE_REDEMPTION_OPTION)}
                placeholder="Option for Online"
                required
                requiredIndicator="*"
                onChange={() =>
                  clearFormFields(['promoCode', 'onlineRedemptionInstructions'])
                }
              />
            </div>
            {onlineRedemptionOption && (
              <>
                {hasOnlineRedemptionDetails && (
                  <div className="col-4">
                    {onlineRedemptionOption ===
                      OFFER_ONLINE_REDEMPTION_OPTION.PROMO_CODE && (
                      <Field
                        component={Input}
                        name="promoCode"
                        placeholder="save20"
                        required
                        requiredIndicator="*"
                      />
                    )}
                    {onlineRedemptionOption ===
                      OFFER_ONLINE_REDEMPTION_OPTION.OTHER && (
                      <Field
                        component={Input}
                        label="Other Instructions"
                        name="onlineRedemptionInstructions"
                        placeholder="Send email to"
                        required
                        requiredIndicator="*"
                      />
                    )}
                  </div>
                )}
                <div className="col-4">
                  <Field
                    component={Input}
                    label="Website URL"
                    name="websiteUrl"
                    required={!hasOnlineRedemptionDetails}
                    requiredIndicator={!hasOnlineRedemptionDetails ? '*' : null}
                  />
                </div>
              </>
            )}
          </div>
        )}
        <Field
          component={Checkbox}
          label="In-Person"
          name="inPerson"
          onChange={(_, checked) => {
            if (!checked)
              clearFormFields([
                'inPersonRedemptionOption',
                'inPersonRedemptionInstructions',
              ])
          }}
        />
        {inPerson && (
          <div className="row">
            <div className="col-6">
              <Field
                component={Select}
                label="Option for In-Person"
                name="inPersonRedemptionOption"
                options={values(OFFER_IN_PERSON_REDEMPTION_OPTION)}
                placeholder="Option for In-Person"
                required
                requiredIndicator="*"
                onChange={() =>
                  clearFormFields(['inPersonRedemptionInstructions'])
                }
              />
            </div>
            {inPersonRedemptionOption && hasInPersonRedemptionDetails && (
              <div className="col-6">
                <Field
                  component={Input}
                  label="Other Instructions"
                  name="inPersonRedemptionInstructions"
                  placeholder='Ask for "person" at checkout'
                  required
                  requiredIndicator="*"
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="group-block">
        <p>
          <strong>
            By clicking Submit we agree to abide by the Sea Tow Savings Club{' '}
            <ExternalLink
              className="external-link-in-text"
              href={makePath(
                PATH.SEATOW,
                PATH.MEMBERSHIP,
                PATH.MEMBERSHIP_AGREEMENT
              )}
            >
              Terms and Conditions
            </ExternalLink>
            .
          </strong>
        </p>
      </div>
      <div className="button-group">
        <SubmitButton
          {...{ submitting, pristine }}
        >
          Submit
        </SubmitButton>
      </div>
    </form>
  )
}

OfferForm.propTypes = propTypes
OfferForm.defaultProps = defaultProps

const beforeSubmit = (formValues) => {
  // Check to see if at least one redemption type is selected, otherwise
  // trigger an error message that a type has to be selected
  const { online, inPerson } = formValues
  if (!online && !inPerson) {
    throw new SubmissionError({
      _error: 'You must select at least one Redemption Type for your offer.',
    })
  }

  // Website URL isn't cleared earlier to allow for defaulting to account website,
  // but don't submit it if online redemption was not selected
  // since that field is only available when online is checked
  if (!formValues.online) return { ...formValues, websiteUrl: null }
  return formValues
}

export default compose(
  lpForm({
    name: 'OfferForm',
    beforeSubmit,
    constraints: {
      primaryCategory: { presence: true },
      secondaryCategories: {
        length: {
          maximum: 3,
          tooLong: '^You may only select up to 3 additional categories',
          tokenizer: checkboxGroupSplit,
        },
      },
      offerType: { presence: true },
      dollarsOff: { presence: { allowEmpty: false } },
      percentageOff: { presence: { allowEmpty: false } },
      otherTypeDescription: {
        presence: { allowEmpty: false },
        length: { maximum: 1000 },
      },
      description: {
        presence: { allowEmpty: false },
        length: { maximum: 1000 },
      },
      onlineRedemptionOption: { presence: true },
      promoCode: { presence: { allowEmpty: false }, length: { maximum: 32 } },
      onlineRedemptionInstructions: {
        presence: { allowEmpty: false },
        length: { maximum: 1000 },
      },
      websiteUrl: (_, attributes) => {
        if (checkForRedemptionDetails(attributes.onlineRedemptionOption))
          return null
        return {
          presence: { allowEmpty: false },
          url: true,
          length: { maximum: 255 },
        }
      },
      inPersonRedemptionOption: { presence: true },
      inPersonRedemptionInstructions: {
        presence: { allowEmpty: false },
        length: { maximum: 1000 },
      },
    },
  }),
  formValues(
    'primaryCategory',
    'offerType',
    'online',
    'inPerson',
    'onlineRedemptionOption',
    'inPersonRedemptionOption'
  )
)(OfferForm)
