import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { isNil } from 'lodash'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'lp-components'
import { Aor } from 'components'
import { DigitalWalletPass } from 'memberships/components'
import { selectors as memberSelectors } from 'member-reducer'
import * as memberActions from 'member-actions'
import { HEROKU_ID_KEY, MembershipType } from 'types'
import {
  DIGITAL_WALLET_TYPE,
  IMAGE_PATH,
  PATH,
  makeMemberApplicationApplyPath,
} from 'config'
import { formatUSMonthDayYearDateString } from 'utils'

const propTypes = {
  clearShoppingCart: PropTypes.func.isRequired,
  membership: MembershipType.isRequired,
}
const defaultProps = {}

function FinalConfirmationAndLogin({ clearShoppingCart, membership }) {
  const { loginWithRedirect } = useAuth0()

  const membershipExpirationDate = membership?.membership_expiration_date__c

  // It is possible that the membership and/or the expiration date are nil if
  // the user manually navigates to this page without first having either
  // created an account or providing payment. The redirect to the payment page
  // may send the user further back into the application flow until all
  // required information is provided.
  if (isNil(membership) || isNil(membershipExpirationDate)) {
    return <Redirect to={makeMemberApplicationApplyPath(PATH.PAYMENT)} />
  }

  const {
    aor,
    apple_pass__c: applePassUrl,
    google_pass__c: googlePassUrl,
    membership_number__c: membershipNumber,
    [HEROKU_ID_KEY]: externalMembershipId,
  } = membership
  const formattedExpirationDate = formatUSMonthDayYearDateString(
    membershipExpirationDate,
  )

  const setupAccount = () => {
    clearShoppingCart()
    loginWithRedirect({
      appState: {
        externalMembershipId,
        membershipExpirationDate,
        membershipNumber,
        returnTo: PATH.MEMBER_PORTAL,
      },
      authorizationParams: {
        prompt: 'login',
        screen_hint: 'signup',
      }
    })
  }

  return (
    <>
      <div className="form-with-aside-wrapper form-block-container confirmation">
        <div className="form-with-aside-block">
          <div className="form-with-aside">
            <div className="card card-medium">
              <h2>Thank you for choosing Sea Tow</h2>
              <p>
                Thank you for joining the Sea Tow boating family. As a member
                you can boat with complete peace of mind knowing your local Sea
                Tow captains and crew are standing by 24/7 to help.
              </p>
              <hr className="divider" />
              <div className="row">
                <div className="col-6">
                  <p className="label">Membership #:</p>
                  <p className="large">{membershipNumber}</p>
                </div>
                <div className="col-6">
                  <p className="label">Membership Expiration:</p>
                  <p className="large">{formattedExpirationDate}</p>
                </div>
              </div>
              <div className="icon-and-text-callout">
                <img
                  src="/assets/images/icons/transparent/ST_Icon_Mail-Card.svg"
                  alt=""
                />
                <p>
                  A confirmation email has been sent to you. Your membership
                  card will be on way in 1-2 weeks.
                </p>
              </div>
              <div className="digital-passes-block">
                <p>For quick and easy membership card access:</p>
                <div className="digital-passes">
                  <DigitalWalletPass
                    type={DIGITAL_WALLET_TYPE.APPLE}
                    url={applePassUrl}
                  />
                  <DigitalWalletPass
                    type={DIGITAL_WALLET_TYPE.GOOGLE}
                    url={googlePassUrl}
                  />
                </div>
              </div>
            </div>
            <div className="card card-medium">
              <img
                alt=""
                src={IMAGE_PATH.MEMBER_APPLICATION_CONFIRMATION}
                className="confirmation-icon"
              />
              <h2>Your Next Step: Create Your Online Account</h2>
              <p>
                It will only take a minute! Create your username and password to
                access your account online.
              </p>
              <hr className="divider" />
              <div className="text-block">
                <p>Then, so we can best service you quickly:</p>
                <ul className="bullets">
                  <li>Add any missing boat information or additional boats</li>
                  <li>
                    Add additional contacts and manage your communications
                    preferences
                  </li>
                  <li>
                    Access deals and discounts through the Sea Tow Savings Club
                  </li>
                </ul>
                <p>Moving ahead you will use this online account to:</p>
                <ul className="bullets">
                  <li>
                    Manage your Automatic Renewal subscription preferences
                  </li>
                  <li>Manage your membership details</li>
                  <li>Update your address and contact details</li>
                  <li>Browse Sea Tow Savings Club</li>
                  <li>Refer-a-Boater to Sea Tow</li>
                  <li>And so much more!</li>
                </ul>
              </div>
              <Button onClick={setupAccount}>Register & Login</Button>
            </div>
          </div>

          <aside>
            <div className="card image-card captain">
              <Aor aor={aor} />
            </div>
          </aside>
        </div>
      </div>
    </>
  )
}

FinalConfirmationAndLogin.propTypes = propTypes
FinalConfirmationAndLogin.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {
  clearShoppingCart: memberActions.clearShoppingCart,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FinalConfirmationAndLogin,
)
