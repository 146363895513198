import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function Layout({ children }) {
  return (
    <div className="image-and-form-layout purchase-flow">
      <div className="split-image-block">
        <img
          src="/assets/images/imagery/purchase-flow/gold-card.jpg"
          alt=""
        />
      </div>
      {children}
    </div>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default Layout
