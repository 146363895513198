import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectors as apiSelectors } from 'lp-redux-api'
import { Spinner } from 'lp-components'
import { GiftInvoice } from '../components'
import { ReviewOrderForm } from '../forms'
import { selectors as memberSelectors } from 'member-reducer'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { PATH, makeGiftPath } from 'config'
import {
  API_KEY_MAP,
  AppliedDiscountCodeType,
  CreditType,
  DigitalRecipientType,
  GIFT_DELIVERY_METHOD,
  GiftContactType,
  MailedRecipientContactType,
  ProductPrices,
} from 'types'
import {
  apiValuesWithMappedKeys,
  calculateTotalGiftCost,
  formatPhoneNumber,
  handleSubmitFailWithFlashMessage,
} from 'utils'

const propTypes = {
  billingContact: GiftContactType.isRequired,
  creditCard: CreditType.isRequired,
  deliveryMethod: PropTypes.string.isRequired,
  discountCodeDetails: AppliedDiscountCodeType,
  giftMessage: PropTypes.string,
  giftWrapped: PropTypes.bool.isRequired,
  membershipPrices: ProductPrices.isRequired,
  processingGiftCreation: PropTypes.bool,
  recipientContact: PropTypes.oneOfType([
    DigitalRecipientType,
    MailedRecipientContactType,
  ]).isRequired,
  sendSeaTowGift: PropTypes.func.isRequired,
  selectedMembershipType: PropTypes.string.isRequired,
  shippingMethod: PropTypes.string,
}
const defaultProps = {}

function ReviewOrder({
  billingContact,
  creditCard,
  deliveryMethod,
  discountCodeDetails,
  giftMessage,
  giftWrapped,
  membershipPrices,
  processingGiftCreation,
  recipientContact,
  selectedMembershipType,
  sendSeaTowGift,
  shippingMethod,
}) {
  const history = useHistory()

  if (processingGiftCreation) return <Spinner />
  return (
    <>
      <GiftInvoice
        {...{
          deliveryMethod,
          giftWrapped,
          membershipPrices,
          selectedMembershipType,
          shippingMethod,
        }}
        withDetail
      />
      <RecipientAndGiftMessage
        {...{ deliveryMethod, giftMessage, recipientContact }}
      />
      <CreditCardSummary {...{ billingContact, creditCard }} />
      <ReviewOrderForm
        onSubmit={() => {
          const billing = apiValuesWithMappedKeys(
            billingContact,
            API_KEY_MAP.GIFT_BILLING_KEY_MAP
          )
          const credit_card = apiValuesWithMappedKeys(
            creditCard,
            API_KEY_MAP.GIFT_CREDIT_CARD_KEY_MAP
          )
          const recipient = apiValuesWithMappedKeys(
            recipientContact,
            API_KEY_MAP.GIFT_RECIPIENT_KEY_MAP
          )
          const { totalCost } = calculateTotalGiftCost({
            deliveryMethod,
            discountCodeDetails,
            giftWrapped,
            membershipPrices,
            selectedMembershipType,
            shippingMethod,
          })
          const giftLogistics = apiValuesWithMappedKeys(
            {
              deliveryMethod,
              giftMessage,
              giftWrapped,
              shippingMethod,
              totalCost,
            },
            API_KEY_MAP.GIFT_LOGISTICS_KEY_MAP
          )

          return sendSeaTowGift({
            ...giftLogistics,
            billing,
            credit_card,
            recipient,
          })
        }}
        onSubmitFail={handleSubmitFailWithFlashMessage}
        onSubmitSuccess={() => history.push(makeGiftPath(PATH.CONFIRM_GIFT))}
      />
    </>
  )
}

ReviewOrder.propTypes = propTypes
ReviewOrder.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    billingContact: selectors.billingContact(state),
    creditCard: selectors.creditCard(state),
    deliveryMethod: selectors.deliveryMethod(state),
    giftMessage: selectors.giftMessage(state),
    giftWrapped: selectors.giftWrapped(state),
    membershipPrices: memberSelectors.membershipPrices(state),
    processingGiftCreation: apiSelectors.isLoading(
      state,
      apiActions.sendSeaTowGift
    ),
    recipientContact: selectors.recipientContact(state),
    selectedMembershipType: selectors.selectedMembershipType(state),
    shippingMethod: selectors.shippingMethod(state),
  }
}

const mapDispatchToProps = {
  sendSeaTowGift: apiActions.sendSeaTowGift,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReviewOrder
)

function RecipientAndGiftMessage({
  deliveryMethod,
  giftMessage,
  recipientContact,
}) {
  const {
    firstName,
    lastName,
    phone,
    address,
    city,
    state,
    postalCode,
    email,
  } = recipientContact
  return (
    <>
      <hr />
      <div className="group-block medium-spacing">
        <h3 className="group-title">Recipient Information</h3>
        <p>
          <strong>Full Name:</strong> {firstName} {lastName}
        </p>
      </div>
      {deliveryMethod === GIFT_DELIVERY_METHOD.MAILED && (
        <div className="group-block medium-spacing">
          <h3 className="group-title">Delivery Contact Information</h3>
          <p><strong>Phone Number:</strong> {formatPhoneNumber(phone)}</p>
          <p><strong>Delivery Address:</strong> {address}, {city}, {state} {postalCode}</p>
        </div>
      )}
      {deliveryMethod === GIFT_DELIVERY_METHOD.DIGITAL && ( 
        <div className="group-block medium-spacing">
          <h3 className="group-title">Delivery Contact Information</h3>
          <p><strong>Email:</strong> {email}</p>
        </div>
      )}
      {giftMessage && ( 
        <div className="group-block medium-spacing">
          <h3 className="group-title">Gift Message</h3>
          <p>{giftMessage}</p>
        </div>
      )}
    </>
  )
}

function CreditCardSummary({ billingContact, creditCard }) {
  const { postalCode } = billingContact
  const { cardNumber } = creditCard

  return (
    <div className="group-block medium-spacing">
      <h3 className="group-title">Delivery Contact Information</h3>
      <p>Credit card ending in {cardNumber.slice(-4)}</p>
      <p><strong>Billing Zip Code:</strong> {postalCode}</p>
    </div>
  )
}
