import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { pick } from 'lodash'
import { PageHeader, SectionHeader } from 'components'
import { ContactForm } from '../forms'
import * as apiActions from 'api-actions'
import { API_KEY_MAP, isMemberAccountType } from 'types'
import { QUERY_KEY } from 'config'
import {
  apiValuesWithMappedKeys,
  formatCommunicationPreferences,
  parseQuery,
  withApiAuth,
} from 'utils'

const propTypes = {
  createContact: PropTypes.func.isRequired,
  fetchContacts: PropTypes.func.isRequired,
}

const defaultProps = {}

// Note that, currently, new contacts are *never* primary contacts. The primary
// contact for an account is created when the member's account is created.
function NewContact({ createContact, fetchContacts }) {
  const history = useHistory()
  const { search } = useLocation()
  const query = parseQuery(search)
  const backLink = query[QUERY_KEY.BACK]
  const accountId = query[QUERY_KEY.ACCOUNT_ID]
  const country = query[QUERY_KEY.COUNTRY]
  const accountType = query[QUERY_KEY.ACCOUNT_TYPE]
  const isMemberContact = isMemberAccountType(accountType)

  return (
    <>
      <PageHeader
        backLinkText="Back to Account Details & Preferences"
        backLinkTo={backLink}
        headerText="Account Details & Preferences"
      />
      <div className="form-block-container">
        <div className="card card-medium">
          <div className="card-inner">
            <div className="form-block">
              <SectionHeader
                sectionHeaderName="Add Contact"
                sectionDescription={
                  isMemberContact
                    ? 'By adding a contact, the person listed below will be covered under your membership and receive any Sea Tow communication selected below.'
                    : 'By adding a contact, the person listed below will be an additional point of contact for your company.'
                }
              />
              <ContactForm
                country={country}
                isMemberContact={isMemberContact}
                isPrimaryContact={false}
                name="ContactForm"
                onSubmit={(contactFormValues) => {
                  const mappedFormValues = pick(
                    contactFormValues,
                    Object.values(API_KEY_MAP.CONTACT_KEY_MAP)
                  )
                  const mappedApiValues = apiValuesWithMappedKeys(
                    mappedFormValues,
                    API_KEY_MAP.CONTACT_KEY_MAP
                  )
                  const contactApiValues = {
                    ...mappedApiValues,
                    communication_preferences__c: formatCommunicationPreferences(
                      contactFormValues
                    ),
                    accountid: accountId,
                  }

                  return createContact(contactApiValues)
                }}
                onSubmitSuccess={async () => {
                  await fetchContacts(accountId)
                  history.push(backLink)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

NewContact.propTypes = propTypes
NewContact.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  createContact: apiActions.createContact,
  fetchContacts: apiActions.fetchContacts,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(NewContact)
