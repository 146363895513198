import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Field, formValues, propTypes as formPropTypes } from 'redux-form'
import { flashErrorMessage } from 'redux-flash'
import { lpForm } from 'lp-form'
import { isEmpty } from 'lodash'
import {
  Button,
  FileInput,
  Input,
  InputLabel,
  SubmitButton,
} from 'lp-components'
import { PartnerAccountFields } from 'programs-portal/forms'
import * as apiActions from 'api-actions'
import { IMAGE_PATH, MAXIMUM_LOGO_SIZE } from 'config'
import { withApiAuth } from 'utils'

const propTypes = {
  accountId: PropTypes.string.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  logoUrl: PropTypes.string,
  mailingAddressSameAsPublic: PropTypes.bool,
  mailingCountry: PropTypes.string,
  publicCountry: PropTypes.string,
  multipleLocation: PropTypes.bool,
  refreshAccount: PropTypes.func.isRequired,
  toggleBusinessInformationForm: PropTypes.func.isRequired,
  uploadLogo: PropTypes.func.isRequired,
  ...formPropTypes,
}
const defaultProps = {}

function BusinessInformationForm({
  accountId,
  change,
  flashErrorMessage,
  handleSubmit,
  logoUrl,
  mailingAddressSameAsPublic,
  mailingCountry,
  multipleLocation,
  pristine,
  publicCountry,
  refreshAccount,
  submitting,
  toggleBusinessInformationForm,
  uploadLogo,
}) {
  const [showPreview, setShowPreview] = useState(true)
  const onImageLoad = useCallback(
    async (fileData, { size }) => {
      if (size > MAXIMUM_LOGO_SIZE) {
        flashErrorMessage('Logo image size must be less than 500KB')
        setShowPreview(false)
        return
      }
      setShowPreview(true)
      await uploadLogo({
        accountid: accountId,
        base64encodedimage: fileData,
      })
      await refreshAccount()
    },
    [accountId]
  )

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="group-block medium-spacing">
        <PartnerAccountFields
          {...{
            change,
            mailingAddressSameAsPublic,
            mailingCountry,
            multipleLocation,
            publicCountry,
          }}
        />

        <Field
          accept=".png,.jpg,.jpeg,.svg"
          component={FileInput}
          labelComponent={LogoLabel}
          name="logoUrl"
          onLoad={onImageLoad}
          thumbnail={logoUrl ?? IMAGE_PATH.DEFAULT_PARTNER_LOGO}
          hidePreview={!showPreview}
        />

        <div className="row">
          <div className="col-6">
            <Field component={Input} label="Instagram URL" name="instagram" />
          </div>
          <div className="col-6">
            <Field component={Input} label="Facebook URL" name="facebook" />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Field component={Input} label="LinkedIn URL" name="linkedIn" />
          </div>
        </div>
      </div>
      <div className="button-group">
        <Button
          onClick={toggleBusinessInformationForm}
          className="button-primary-outline"
        >
          Cancel
        </Button>
        <SubmitButton {...{ pristine, submitting }}>
          Update Business Information
        </SubmitButton>
      </div>
    </form>
  )
}

BusinessInformationForm.propTypes = propTypes
BusinessInformationForm.defaultProps = defaultProps

const beforeSubmit = (formValues) => {
  const { mailingAddressSameAsPublic } = formValues

  // If the mailing address was set to be the same as the public address,
  // set all of the mailing address-related fields to null.
  if (mailingAddressSameAsPublic) {
    formValues.mailingAddress = null
    formValues.mailingCity = null
    formValues.mailingState = null
    formValues.mailingPostalCode = null
    formValues.mailingCountry = null
  }

  return formValues
}

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  flashErrorMessage,
}

const mapApiAuthToProps = {
  uploadLogo: apiActions.uploadLogo,
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'BusinessInformationForm',
    constraints: {
      businessDescription: { presence: { allowEmpty: false } },
      businessName: { presence: { allowEmpty: false } },
      facebook: (value) => (isEmpty(value) ? null : { url: true }),
      instagram: (value) => (isEmpty(value) ? null : { url: true }),
      linkedIn: (value) => (isEmpty(value) ? null : { url: true }),
      mailingAddress: { presence: { allowEmpty: false } },
      mailingCity: { presence: { allowEmpty: false } },
      mailingCountry: { presence: { allowEmpty: false } },
      mailingPostalCode: { presence: { allowEmpty: false } },
      mailingState: { presence: { allowEmpty: false } },
      phone: { presence: { allowEmpty: false } },
      publicAddress: { presence: { allowEmpty: false } },
      publicCity: { presence: { allowEmpty: false } },
      publicCountry: { presence: { allowEmpty: false } },
      publicEmail: { email: true, presence: true },
      publicPostalCode: { presence: { allowEmpty: false } },
      publicState: { presence: { allowEmpty: false } },
      websiteUrl: {
        presence: false,
        url: {
          message: 'is not valid, must contain "https://" or "http://"',
        },
      },
    },
    submitFilters: { reject: ['logoUrl', 'mailingAddressSameAsPublic'] },
  }),
  formValues('publicCountry'),
  formValues('mailingAddressSameAsPublic'),
  formValues('mailingCountry'),
  formValues('multipleLocation'),
  formValues('logoUrl'),
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(BusinessInformationForm)

function LogoLabel({ ...props }) {
  return (
    <InputLabel {...props}>
      Upload Logo
      <p className="input-instructions">
        PNG, JPG, SVG accepted: Recommended image dimensions should not exceed
        410 (width) x 140 (height). Total image size must be less than 500KB.
      </p>
    </InputLabel>
  )
}
