import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useAuth0 } from '@auth0/auth0-react'
import { isNil } from 'lodash'
import { Button } from 'lp-components'
import { ButtonLink, RedirectWithQueryParams } from 'components'
import {
  PATH,
  USER_METADATA,
  makePath,
  makeMemberApplicationPath,
  makeMembershipsPath,
} from 'config'

const propTypes = {}
const defaultProps = {}

function NewOrRenew() {
  const { isAuthenticated, user } = useAuth0()

  if (isAuthenticated) {
    const memberAccountId = user[USER_METADATA.MEMBER_ACCOUNT_ID]

    // Verify that the logged-in user has access to the member portal. If not,
    // (i.e., they are a programs participant) they are redirected to apply as a new member.
    if (!isNil(memberAccountId)) return <ExistingMembershipRouter />
  }

  return (
    <RedirectWithQueryParams
      to={makeMemberApplicationPath(PATH.NEW_MEMBER_QUESTIONNAIRE)}
    />
  )
}

NewOrRenew.propTypes = exact(propTypes)
NewOrRenew.defaultProps = defaultProps

export default pure(NewOrRenew)

function ExistingMembershipRouter() {
  return (
    <section className="form-block-container">
      <div className="card card-medium">
        <div className="card-inner">
          <div className="group-block">
            <h3 className="group-title">Existing Member</h3>
            <p>
              We see that you already have or had a Sea Tow membership. Would
              you like to renew your existing membership? If you would like to
              add a new membership to your existing account, please{' '}
              <a href={makePath(PATH.SEATOW, PATH.CONTACT)}>contact Sea Tow</a>{' '}
              for assistance.
            </p>
            <div className="button-group">
              <Button
                className="button-primary-outline"
                onClick={() => window.location.replace(makePath(PATH.SEATOW))}
              >
                Cancel
              </Button>
              <ButtonLink
                className="button-primary"
                to={makeMembershipsPath(
                  PATH.SELECT_YOUR_MEMBERSHIP,
                  PATH.RENEW_MEMBERSHIP
                )}
              >
                Renew Membership
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
