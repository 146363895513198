import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import LakeToGoldLayout from './LakeToGoldLayout'
import { ExistingMemberApplicationAccountProvider } from 'memberships/components'
import { makePath, makeMembershipsPath, PATH } from 'config'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <ExistingMemberApplicationAccountProvider>
      <Layout>
        <Switch>
          <Route exact path={makePath(path, PATH.SELECTION)}>
            <Views.GoldUpgradeSelected />
          </Route>
          <Route exact path={makePath(path, PATH.CONFIRM_AND_LOGIN)}>
            <Views.GoldUpgradeConfirmation />
          </Route>
          <Route>
            <ApplicationStepRoutes />
          </Route>
        </Switch>
      </Layout>
    </ExistingMemberApplicationAccountProvider>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes

function ApplicationStepRoutes() {
  return (
    <LakeToGoldLayout>
      <Switch>
        <Route
          exact
          path={makeMembershipsPath(
            PATH.SFID,
            PATH.UPGRADES,
            PATH.MEMBERSHIP,
            PATH.PAYMENT
          )}
        >
          <Views.GoldUpgradePayment isUpgrade={true} />
        </Route>
        <Route
          exact
          path={makeMembershipsPath(
            PATH.SFID,
            PATH.UPGRADES,
            PATH.MEMBERSHIP,
            PATH.REVIEW_ORDER
          )}
        >
          <Views.GoldUpgradeReview />
        </Route>
      </Switch>
    </LakeToGoldLayout>
  )
}
