import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import classnames from 'classnames'
import { STATUS_CLASS } from 'types'

const propTypes = {
  // statusAdditionalText: PropTypes.string,
  statusClass: PropTypes.string,
  statusText: PropTypes.string.isRequired,
}
const defaultProps = {
  statusClass: STATUS_CLASS.OK,
}

function StatusIndicator({
  statusClass,
  /*statusAdditionalText,*/ statusText,
}) {
  return (
    <div className={classnames('status-indicator', statusClass)}>
      <p>{statusText}</p>
    </div>
  )
}

StatusIndicator.propTypes = exact(propTypes)
StatusIndicator.defaultProps = defaultProps

export default pure(StatusIndicator)
