import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Input, MaskedInput, Select, SubmitButton } from 'lp-components'
import { Field, formValues, propTypes as formPropTypes } from 'redux-form'
import { ADDRESS_FORM_VALIDATOR, COUNTRY, PHONE_REGION_CODE } from 'config'
import { parsePhoneNumber, stateListFromCountry } from 'utils'

const propTypes = {
  publicCountry: PropTypes.string,
  ...formPropTypes,
}

const defaultProps = {}

function ProgramApplicationForm({
  change,
  handleSubmit,
  publicCountry,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="group-block">
        <h3 className="group-title">Primary Contact Information</h3>
        <div className="row">
          <div className="col-6">
            <Field
              component={Input}
              label="First Name"
              name="firstName"
              required
              requiredIndicator="*"
            />
          </div>
          <div className="col-6">
            <Field
              component={Input}
              label="Last Name"
              name="lastName"
              required
              requiredIndicator="*"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Field
              component={Input}
              label="Email"
              name="email"
              required
              requiredIndicator="*"
            />
          </div>
          <div className="col-6">
            <Field
              component={MaskedInput}
              label="Phone Number"
              maskOptions={{
                numericOnly: true,
                blocks: [0, 3, 0, 3, 4],
                delimiters: ['(', ')', ' ', '-'],
                phoneRegionCode: PHONE_REGION_CODE[publicCountry],
              }}
              name="phone"
              parse={parsePhoneNumber}
              placeholder="(800) 555-1212"
              required
              requiredIndicator="*"
            />
          </div>
        </div>
      </div>

      <div className="group-block">
        <h3 className="group-title">Business Information</h3>
        <div className="row">
          <Field
            component={Input}
            label="Business Name"
            name="businessName"
            required
            requiredIndicator="*"
          />
        </div>

        <div className="row">
          <Field
            component={Input}
            label="Address"
            name="publicAddress"
            required
            requiredIndicator="*"
          />
        </div>

        <div className="row">
          <div className="col-6">
            <Field
              component={Select}
              label="Country"
              name="publicCountry"
              onChange={() => change('publicState', '')}
              options={Object.values(COUNTRY)}
              placeholder="Country"
              required
              requiredIndicator="*"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Field
              component={Input}
              label="City"
              name="publicCity"
              required
              requiredIndicator="*"
            />
          </div>
          <div className="col-3">
            <Field
              component={Select}
              label="State"
              name="publicState"
              options={stateListFromCountry(publicCountry)}
              placeholder="State"
              required
              requiredIndicator="*"
            />
          </div>
          <div className="col-3">
            <Field
              component={Input}
              label="Postal Code"
              name="publicPostalCode"
              required
              requiredIndicator="*"
            />
          </div>
        </div>

        <div className="row">
          <Field
            component={Input}
            label="Website URL"
            name="websiteUrl"
            placeholder="https://www.example.com"
          />
        </div>
      </div>
      <SubmitButton {...{ submitting }}>Submit</SubmitButton>
    </form>
  )
}

ProgramApplicationForm.propTypes = propTypes
ProgramApplicationForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'ProgramApplicationForm',
    constraints: {
      businessName: { presence: { allowEmpty: false } },
      email: { email: true, presence: true },
      firstName: { presence: { allowEmpty: false } },
      lastName: { presence: { allowEmpty: false } },
      phone: { presence: { allowEmpty: false } },
      publicAddress: ADDRESS_FORM_VALIDATOR,
      publicCity: { presence: { allowEmpty: false } },
      publicCountry: { presence: { allowEmpty: false } },
      publicPostalCode: { presence: { allowEmpty: false } },
      publicState: { presence: { allowEmpty: false } },
      websiteUrl: {
        presence: false,
        url: {
          message: 'is not valid, must contain "https://" or "http://"',
        },
      },
    },
  }),
  formValues('publicCountry')
)(ProgramApplicationForm)
