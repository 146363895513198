import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { AppliedDiscountCodeType, DISCOUNT_CODE_TYPE } from 'types'
import { useHistory } from 'react-router-dom'
import { DiscountCodeForm } from 'member-portal/forms'
import {
  getInitialDiscountCodeValues,
  handleSubmitFailWithFlashMessage,
  makeQueryPath,
} from 'utils'

const propTypes = {
  basePath: PropTypes.string.isRequired,
  clearDiscountCodeStateAndLS: PropTypes.func.isRequired,
  discountCodeDetails: AppliedDiscountCodeType,
  formName: PropTypes.string.isRequired,
  isRecreationalCard: PropTypes.bool,
  isTrialMembershipsActive: PropTypes.bool,
  promoCodeIsTrial: PropTypes.bool,
}

const defaultProps = {}

function DiscountCode({
  basePath,
  clearDiscountCodeStateAndLS,
  discountCodeDetails,
  formName,
  isRecreationalCard,
  isTrialMembershipsActive,
  promoCodeIsTrial,
}) {
  const history = useHistory()
  const clearDiscountCode = () => {
    clearDiscountCodeStateAndLS()
    history.replace(basePath)
  }

  return (
    <DiscountCodeForm
      // form name must be provided to eliminate race condition
      // when switching between views that both use DiscountCode
      appliedDiscountCodeDescription={discountCodeDetails?.description}
      clearDiscountCode={clearDiscountCode}
      codeFieldType={discountCodeDetails?.type}
      initialValues={getInitialDiscountCodeValues(discountCodeDetails)}
      isRecreationalCard={isRecreationalCard}
      name={formName}
      onSubmit={({ activationCode, promoCode }) => {
        // clear code from url if a blank code is applied
        if (!activationCode && !promoCode) return clearDiscountCode()

        const codeType = activationCode
          ? DISCOUNT_CODE_TYPE.ACTIVATION
          : DISCOUNT_CODE_TYPE.PROMO
        history.replace(
          makeQueryPath(basePath, {
            [codeType]: activationCode ?? promoCode,
          })
        )
      }}
      onSubmitFail={handleSubmitFailWithFlashMessage}
      promoCodeIsTrial={isTrialMembershipsActive && promoCodeIsTrial}
    />
  )
}

DiscountCode.propTypes = propTypes
DiscountCode.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    isRecreationalCard: memberSelectors.isRecreationalCard(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
  }
}

export default compose(connect(mapStateToProps, null))(DiscountCode)
