import React from 'react'
import { pure } from 'recompose'
import { useHistory } from 'react-router-dom'
import { Button } from 'lp-components'
import { PageHeader } from 'components'

const propTypes = {}
const defaultProps = {}

function ServerError() {
  const history = useHistory()

  return (
    <>
      <PageHeader headerText="Error" />
      <section className="form-block-container">
        <div className="empty-state">
          <header>
            <h1>Server Error</h1>
          </header>
          <div className="card-inner">
            <p>
              Something went wrong. Please try again or contact us if the issue
              persists.
            </p>
            <Button
              className="button-primary"
              onClick={() => history.goBack()}
            >
              Retry Request
            </Button>
          </div>
        </div>
      </section>
    </>
  )
}

ServerError.propTypes = propTypes
ServerError.defaultProps = defaultProps

export default pure(ServerError)
