import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { flashSuccessMessage } from 'redux-flash'
import { PageHeader } from 'components'
import { ReferABoaterForm } from '../forms'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import { AccountType, API_KEY_MAP } from 'types'
import { COST, IMAGE_PATH, PATH, makeMemberPortalPath } from 'config'
import { apiValuesWithMappedKeys, withApiAuth } from 'utils'

const propTypes = {
  account: AccountType,
  createReferral: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
}
const defaultProps = {}

function ReferABoater({ account, createReferral, flashSuccessMessage }) {
  const { sfid: accountId } = account

  return (
    <>
      <PageHeader
        backLinkText="Back to Membership Dashboard"
        backLinkTo={makeMemberPortalPath(PATH.MEMBERSHIPS)}
        headerText="Referrals"
      />

      <section className="form-block-container">
        <div className="card with-image-header">
          <img alt="" src={IMAGE_PATH.REFERRALS_HEADER} />
          <div className="card-inner">
            <header className="secondary-header">
              <h2>Refer a Boater and Earn {COST.MEMBERSHIP_RENEWAL_CREDIT}!</h2>
              <p>
                Get {COST.MEMBERSHIP_RENEWAL_CREDIT} off your membership renewal
                for each Sea Tow member you refer. There is NO limit to the
                number of credits you can earn, so spread the word!
              </p>
            </header>
            <ReferABoaterForm
              onSubmit={(referralFormValues) => {
                const referralApiValues = apiValuesWithMappedKeys(
                  referralFormValues,
                  API_KEY_MAP.REFERRAL_KEY_MAP
                )

                return createReferral({
                  ...referralApiValues,
                  referring_member_account_id__c: accountId,
                })
              }}
              onSubmitSuccess={(_, dispatch) => {
                dispatch(reset('ReferABoaterForm'))
                flashSuccessMessage(
                  'Thanks for referring a boater to Sea Tow®!'
                )
              }}
            />
          </div>
        </div>
      </section>
    </>
  )
}

ReferABoater.propTypes = propTypes
ReferABoater.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    account: globalSelectors.account(state),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashSuccessMessage,
}

const mapApiAuthToProps = {
  createReferral: apiActions.createReferral,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(ReferABoater)
