import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button, Modal } from 'lp-components'

const propTypes = {
  errorMessage: PropTypes.string.isRequired,
}

const defaultProps = {}

function TrailerCareErrorModal({ errorMessage }) {
  const history = useHistory()

  return (
    <Modal preventClose={true}>
      <p>{errorMessage}</p>
      <div>
        <Button
          onClick={() => {
            history.goBack()
          }}
        >
          Back
        </Button>
      </div>
    </Modal>
  )
}

TrailerCareErrorModal.propTypes = propTypes
TrailerCareErrorModal.defaultProps = defaultProps

export default TrailerCareErrorModal
