import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as memberActions from 'member-actions'

const propTypes = {
  children: PropTypes.node,
  setResetShoppingCart: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
}

const defaultProps = {}

function RenewButton({ children, setResetShoppingCart, to, ...rest }) {
  const history = useHistory()
  return (
    <button
      onClick={() => {
        setResetShoppingCart(true)
        history.push(to)
      }}
      {...rest}
    >
      {children}
    </button>
  )
}

RenewButton.propTypes = propTypes
RenewButton.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  setResetShoppingCart: memberActions.setResetShoppingCart,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RenewButton
)
