import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { range } from 'lodash'
import classnames from 'classnames'

const propTypes = {
  activeStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
}
const defaultProps = {}

function ProgressSteps({ activeStep, totalSteps }) {
  const allSteps = range(1, totalSteps + 1)

  return (
    <ul className="progress-steps">
      {allSteps.map((step) => (
        <li className={classnames({ active: step === activeStep })} key={step}>
          <span>{step}</span>
        </li>
      ))}
    </ul>
  )
}

ProgressSteps.propTypes = exact(propTypes)
ProgressSteps.defaultProps = defaultProps

export default pure(ProgressSteps)
