import React from 'react'
import PropTypes from 'prop-types'
import { ProductPrices, MembershipType } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ApplicationSteps, MembershipSummary } from 'member-portal/components'
import { selectors as memberSelectors } from 'member-reducer'
import { makeMembershipIdPath, PATH } from 'config'

const propTypes = {
  children: PropTypes.node.isRequired,
  membership: MembershipType.isRequired,
  selectedTrailerCareType: PropTypes.string.isRequired,
  trailerCarePrices: ProductPrices.isRequired,
}

const defaultProps = {}

function TrailerCareUpgradeLayout({
  children,
  membership,
  selectedTrailerCareType,

  trailerCarePrices,
}) {
  const { pathname } = useLocation()
  const { sfid: membershipId } = membership

  return (
    <>
      <div className="form-with-aside-wrapper">
        <header className="header-title">
          <h1>Upgrade Trailer Care</h1> 
        </header>
        <div className="form-with-aside-block">
          <div className="form-with-aside">
            <ApplicationSteps
              pathname={pathname}
              stepLinks={trailerCareUpgradeStepLinks(membershipId)}
            >
              {children}
            </ApplicationSteps>
          </div>
          <aside>
            <MembershipSummary
              trailerCareType={selectedTrailerCareType}
              trailerCarePrices={trailerCarePrices}
            />
          </aside>
        </div>
      </div>
    </>
  )
}

TrailerCareUpgradeLayout.propTypes = propTypes
TrailerCareUpgradeLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    membership: memberSelectors.membership(state),
    selectedTrailerCareType: memberSelectors.selectedTrailerCareType(state),
    trailerCarePrices: memberSelectors.trailerCarePrices(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TrailerCareUpgradeLayout
)

const trailerCareUpgradeStepLinks = (membershipId) => {
  return [
    {
      stepName: 'Payment',
      path: makeMembershipIdPath(
        membershipId,
        PATH.UPGRADES,
        PATH.TRAILER_CARE,
        PATH.PAYMENT
      ),
      stepIcon: '/assets/images/icons/with-background/ST_YIcon_Payment.svg',
    },
    {
      stepName: 'Review Your Order',
      path: makeMembershipIdPath(
        membershipId,
        PATH.UPGRADES,
        PATH.TRAILER_CARE,
        PATH.REVIEW_ORDER
      ),
      stepIcon: '/assets/images/icons/with-background/ST_YIcon_Dashboard.svg',
    },
  ]
}
