import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { omit } from 'lodash'
import { lpForm } from 'lp-form'
import { Button, Checkbox, MaskedInput, SubmitButton } from 'lp-components'
import { Field, propTypes as formPropTypes, SubmissionError } from 'redux-form'
import {
  PATH,
  SEATOW_INFO_CONTACT_EMAIL,
  SEATOW_PHONE,
  SEATOW_PHONE_DIGITS,
  SEATOW_URL,
} from 'config'
import {
  ccExpirationDateAfterProvidedDate,
  formatISODate,
  formatCreditCardExpirationDate,
  removeNonDigits,
} from 'utils'

const propTypes = {
  closeEditForm: PropTypes.func,
  renewalDateString: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  withTerms: PropTypes.bool,
  ...formPropTypes,
}

const defaultProps = {
  withCancel: false,
  withTerms: false,
}

function PaymentMethodEditForm({
  closeEditForm,
  handleSubmit,
  pristine,
  submitButtonText,
  submitting,
  withTerms,
}) {
  return (
    <div className="card-inner">
      <form onSubmit={handleSubmit} noValidate>
        <div className="group-block medium-spacing">
          <h3 className="group-title">Payment Method</h3>
          <div className="row">
            <div className="col-6">
              <Field
                component={MaskedInput}
                label="Credit Card Number"
                maskOptions={{ creditCard: true }}
                name="cardNumber"
                placeholder="Credit card number (digits only)"
                parse={removeNonDigits}
                required
                requiredIndicator="*"
              />
            </div>
            <div className="col-3">
              <Field
                component={MaskedInput}
                label="Expiration"
                maskOptions={{
                  date: true,
                  datePattern: ['m', 'y'],
                }}
                name="cardExpirationDate"
                placeholder="MM/YY"
                required
                requiredIndicator="*"
              />
            </div>
            <div className="col-3">
              <Field
                component={MaskedInput}
                label="Security Code"
                maskOptions={{
                  numeral: true,
                  numeralDecimalScale: 0,
                  numeralIntegerScale: 4,
                  numeralPositiveOnly: true,
                  numeralThousandsGroupStyle: 'none',
                  stripLeadingZeroes: false,
                }}
                name="securityCode"
                placeholder="CVV"
                required
                requiredIndicator="*"
              />
            </div>
          </div>
        </div>

        {withTerms && (
          <div className="group-block small-spacing terms">
            <h3 className="group-title">Terms</h3>
            <p>
              By opting into the Sea Tow Automatic Renewal Program you agree to
              the following:
            </p>
            <p>
              Unless Sea Tow notifies you otherwise for reasons that remain
              within Sea Tow's sole discretion, your Sea Tow and/or Trailer Care
              membership(s) will automatically and annually renew under this
              agreement for your selected membership options at the then current
              pricing using the credit card provided. Accordingly, unless you
              receive that notification, each year approximately 10 days prior
              to the end of your membership term, your membership(s) will
              automatically renew for an additional one-year term, unless you
              have chosen to cancel participation in the Automatic Renewal
              Program at least twenty (20) days prior to the expiration date of
              your current membership term.
            </p>
            <p>
              You are required to have a current email address on file with Sea
              Tow so we may notify you of events, including notification of an
              upcoming automatic renewal or declination. All such notices will
              be sent to that email address. No printed mailings will be done.
              It is the member's ongoing responsibility to keep all information
              on file with Sea Tow up to date and to modify such information
              immediately upon a change. Prior to each renewal period an email
              will be sent informing you of upcoming renewal date. The date and
              amount of the charge are available at any time by logging into
              your account on seatow.com.
            </p>
            <p>
              Upon such automatic renewal, the credit, debit or bank card on
              file with Sea Tow will automatically be charged the membership
              fee(s) at the then current rate for the membership option(s)
              selected by member. Should your credit, debit, or bank card be
              declined the membership(s) will not be renewed, you will be
              notified via email, and it is the member's responsibility to
              contact Sea Tow to provide updated payment information in order to
              renew the membership(s) by logging into your account on{' '}
              <a href={PATH.SEATOW}>{SEATOW_URL}</a> and renewing or calling Sea
              Tow at <a href={`tel:${SEATOW_PHONE}`}>{SEATOW_PHONE}</a> (
              {SEATOW_PHONE_DIGITS}).
            </p>
            <p>
              Full refunds will be provided when a membership has been
              automatically renewed when the refund request is made within 30
              days of the effective date of the membership term and no
              membership services have been rendered in the interim. In all
              other cases, Sea Tow membership is non-refundable, but any
              remaining term on the membership is transferable by the member
              upon written notice to Sea Tow International.
            </p>
            <p>
              You can manage your Automatic Renewal subscription or opt out of
              the Automatic Renewal program at any time. In order to cancel
              participation or change your preferences or credit card for the
              Automatic Renewal Program, you may either login to your account on{' '}
              <a href={PATH.SEATOW}>{SEATOW_URL}</a>, call us at{' '}
              <a href={`tel:${SEATOW_PHONE}`}>{SEATOW_PHONE}</a> (
              {SEATOW_PHONE_DIGITS}) or email us at{' '}
              <a href={`mailto:${SEATOW_INFO_CONTACT_EMAIL}`}>
                {SEATOW_INFO_CONTACT_EMAIL}
              </a>
              .
            </p>
            <p>
              This agreement is entered in New York, and New York law applies.
              Members may only raise a claim concerning or arising under this
              Agreement in the Courts of the State of New York.
            </p>

            <Field
              component={Checkbox}
              label="I Agree to the Terms"
              name="termsAgreement"
            />
          </div>
        )}
        <div className="button-group">
          {closeEditForm && (
            <Button onClick={closeEditForm} className="button-primary-outline">
              Cancel
            </Button>
          )}
          <SubmitButton {...{ pristine, submitting }}>
            {submitButtonText}
          </SubmitButton>
        </div>
      </form>
    </div>
  )
}

PaymentMethodEditForm.propTypes = propTypes
PaymentMethodEditForm.defaultProps = defaultProps

const beforeSubmit = (formValues, { renewalDateString, withTerms }) => {
  // Remove the formatting spaces from the credit expiration date
  const { cardExpirationDate: rawCardExpirationDateString } = formValues
  const cardExpirationDate = formatCreditCardExpirationDate(
    rawCardExpirationDateString
  )
  const currentDate = new Date()
  const currentDateString = formatISODate(currentDate)

  if (
    !ccExpirationDateAfterProvidedDate(currentDateString, cardExpirationDate)
  ) {
    throw new SubmissionError({
      cardExpirationDate:
        'The credit card has expired. Please enter a valid credit card.',
    })
  }

  if (
    !ccExpirationDateAfterProvidedDate(renewalDateString, cardExpirationDate)
  ) {
    throw new SubmissionError({
      cardExpirationDate:
        'The credit card expiration date must be later than the membership renewal date. Please use a different credit card or unenroll in automatic renewal.',
    })
  }

  let normalizedFormValues = {
    ...formValues,
    cardExpirationDate,
  }

  if (withTerms) {
    const { termsAgreement } = normalizedFormValues

    if (!termsAgreement) {
      throw new SubmissionError({
        termsAgreement:
          'You must accept the terms of the autorenewal agreement',
      })
    }

    // Autorenewal terms are not sent to the backend so omit them...
    normalizedFormValues = omit(normalizedFormValues, ['termsAgreement'])
  }

  return normalizedFormValues
}

export default compose(
  lpForm({
    beforeSubmit,
    name: 'PaymentMethodEditForm',
    constraints: {
      cardNumber: { presence: true, length: { minimum: 13 } },
      cardExpirationDate: {
        presence: true,
        length: { is: 5 },
      },
      securityCode: {
        presence: true,
        length: { minimum: 3, maximum: 4 },
        numericality: {
          message: 'can contain only numbers',
          onlyInteger: true,
        },
      },
    },
    initialValueFilters: { reject: ['securityCode'] },
  })
)(PaymentMethodEditForm)
