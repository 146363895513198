import React from 'react'
import PropTypes from 'prop-types'
import { SearchResult } from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import OutsideClickHandler from 'react-outside-click-handler'
import { formatDisplayFromAddressSearchResult } from 'utils'
import classnames from 'classnames'

const propTypes = {
  closePanel: PropTypes.func.isRequired,
  hideLocationName: PropTypes.bool,
  isPanelOpen: PropTypes.bool.isRequired,
  searchResults: PropTypes.arrayOf(SearchResult),
  updateFormValues: PropTypes.func.isRequired,
}

const defaultProps = {
  hideLocationName: false,
}

function SearchResultsPanel({
  closePanel,
  hideLocationName,
  isPanelOpen,
  searchResults,
  updateFormValues,
}) {
  return (
    <OutsideClickHandler disabled={!isPanelOpen} onOutsideClick={closePanel}>
      <div
        className={classnames('autocomplete-wrapper', {
          'is-open': isPanelOpen,
        })}
      >
        <ul className="autocomplete">
          {searchResults &&
            searchResults.map((result, index) => {
              const resultDisplay = hideLocationName
                ? result.formatted_address
                : formatDisplayFromAddressSearchResult(result)

              return (
                <li key={resultDisplay + index}>
                  <button
                    type="button"
                    onClick={() => {
                      updateFormValues(result)
                      closePanel()
                    }}
                  >
                    {resultDisplay}
                  </button>
                </li>
              )
            })}
        </ul>
      </div>
    </OutsideClickHandler>
  )
}

SearchResultsPanel.propTypes = exact(propTypes)
SearchResultsPanel.defaultProps = defaultProps

export default pure(SearchResultsPanel)
