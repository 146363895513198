import React from 'react'
import PropTypes from 'prop-types'
import { ProductPrices, MembershipType } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ApplicationSteps, MembershipSummary } from 'member-portal/components'
import { selectors as memberSelectors } from 'member-reducer'
import { makeMembershipIdPath, PATH } from 'config'

const propTypes = {
  children: PropTypes.node.isRequired,
  membership: MembershipType.isRequired,
  selectedMembershipType: PropTypes.string.isRequired,
  membershipPrices: ProductPrices.isRequired,
}

const defaultProps = {}

function LakeToGoldLayout({
  children,
  membership,
  membershipPrices,
  selectedMembershipType,
}) {
  const { pathname } = useLocation()
  const { sfid: membershipId } = membership

  return (
    <>
      <div className="form-with-aside-wrapper">
        <header className="header-title">
          <h1>Upgrade Membership</h1> 
        </header>
        <div className="form-with-aside-block">
          <div className="form-with-aside">
            <ApplicationSteps
              pathname={pathname}
              stepLinks={lakeToGoldUpgradeStepLinks(membershipId)}
            >
              {children}
            </ApplicationSteps>
          </div>
          <aside>
            <MembershipSummary
              membershipType={selectedMembershipType}
              membershipPrices={membershipPrices}
            />
          </aside>
        </div>
      </div>
    </>
  )
}

LakeToGoldLayout.propTypes = propTypes
LakeToGoldLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    membership: memberSelectors.membership(state),
    selectedMembershipType: memberSelectors.selectedMembershipType(state),
    membershipPrices: memberSelectors.membershipPrices(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  LakeToGoldLayout
)

const lakeToGoldUpgradeStepLinks = (membershipId) => {
  return [
    {
      stepName: 'Payment',
      path: makeMembershipIdPath(
        membershipId,
        PATH.UPGRADES,
        PATH.MEMBERSHIP,
        PATH.PAYMENT
      ),
      stepIcon: '/assets/images/icons/with-background/ST_YIcon_Payment.svg',
    },
    {
      stepName: 'Review Your Order',
      path: makeMembershipIdPath(
        membershipId,
        PATH.UPGRADES,
        PATH.MEMBERSHIP,
        PATH.REVIEW_ORDER
      ),
      stepIcon: '/assets/images/icons/with-background/ST_YIcon_Dashboard.svg',
    },
  ]
}
