import React from 'react'
import { MEMBER_APPLICATION_FLOW, AllMembershipsType } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as membershipSelectors } from 'memberships/reducer'
import { MembershipSelection } from 'member-portal/components'
import { PATH, makePath } from 'config'

const propTypes = {
  allMemberships: AllMembershipsType.isRequired,
}

const defaultProps = {}

function RenewalSelectYourMembership({ allMemberships }) {
  const { memberships } = allMemberships
  return (
    <MembershipSelection
      memberApplicationFlow={MEMBER_APPLICATION_FLOW.RENEWAL}
      memberships={memberships}
      nextStep={makePath(PATH.RENEW_MEMBERSHIP, PATH.SELECTION)}
    />
  )
}

RenewalSelectYourMembership.propTypes = propTypes
RenewalSelectYourMembership.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    allMemberships: membershipSelectors.allMemberships(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RenewalSelectYourMembership
)
