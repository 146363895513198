import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { change } from 'redux-form'
import * as memberActions from 'member-actions'
import { selectors as memberSelectors } from 'member-reducer'
import { Button, Modal } from 'lp-components'
import { PATH, makeMemberApplicationPath } from 'config'

const propTypes = {
  clearDiscountCodeStateAndLS: PropTypes.func.isRequired,
  clearMembershipSelectionAndLS: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isTrialMembershipsActive: PropTypes.bool.isRequired,
  promoCodeIsTrial: PropTypes.bool.isRequired,
  toggleModalOpen: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
}

const defaultProps = {}

function AutoRenewalWarningModal({
  clearDiscountCodeStateAndLS,
  clearMembershipSelectionAndLS,
  isOpen,
  isTrialMembershipsActive,
  promoCodeIsTrial,
  toggleModalOpen,
  updateFormValues,
}) {
  const history = useHistory()
  const cancelButtonText = promoCodeIsTrial
    ? 'Continue with trial activation'
    : 'Cancel'
  const unenrollButtonText = promoCodeIsTrial
    ? 'Exit activation'
    : 'Yes, Opt Out'

  const exitTrialActivation = () => {
    clearDiscountCodeStateAndLS()
    clearMembershipSelectionAndLS()
    toggleModalOpen()
    history.push(makeMemberApplicationPath(PATH.ROOT))
  }

  return (
    <>
      {isOpen && (
        <Modal preventClose={true}>
          {promoCodeIsTrial && isTrialMembershipsActive ? (
            <p>
              This Trial membership requires automatic renewal. If you choose to
              unenroll, you will not be able to qualify and proceed. In order to
              continue to your trial membership, you will need to select
              automatic renewal.
            </p>
          ) : (
            <p>
              By opting out of Automatic Renewal your membership will expire at
              the end of the term unless you renew prior to that date. Automatic
              renewal is recommended so there is no lapse in membership.
            </p>
          )}
          <div className="button-group">
            <Button
              onClick={() => {
                toggleModalOpen()
                updateFormValues()
              }}
              className="button-primary-outline"
            >
              {isTrialMembershipsActive ? cancelButtonText : 'Cancel'}
            </Button>
            <Button
              onClick={() => {
                promoCodeIsTrial && isTrialMembershipsActive
                  ? exitTrialActivation()
                  : toggleModalOpen()
              }}
              className="button-warn"
            >
              {isTrialMembershipsActive ? unenrollButtonText : 'Yes, Opt Out'}
            </Button>
          </div>
        </Modal >
      )
      }
    </>
  )
}

function mapStateToProps(state) {
  return {
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
    promoCodeIsTrial: memberSelectors.promoCodeIsTrial(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearDiscountCodeStateAndLS: () =>
      dispatch(memberActions.clearDiscountCodeStateAndLS()),
    clearMembershipSelectionAndLS: () =>
      dispatch(memberActions.clearMembershipSelectionAndLS()),
    updateFormValues: function () {
      dispatch(change('PaymentForm', 'autoRenewalAgreement', true))
    },
  }
}

AutoRenewalWarningModal.propTypes = propTypes
AutoRenewalWarningModal.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AutoRenewalWarningModal
)
