import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { isEmpty } from 'lodash'
import SavingsClub from './SavingsClub'
import { AllSavingsClubTypes } from 'types'

const propTypes = {
  programs: AllSavingsClubTypes.isRequired,
}
const defaultProps = {}

function ActivePrograms({ programs }) {
  const { savings_club } = programs
  const anyPrograms = !isEmpty(savings_club)

  return (
    <section>
      <h2>Your Programs</h2>
      {anyPrograms ? (
        savings_club && <SavingsClub offers={savings_club} />
      ) : (
        <p>You have no active programs</p>
      )}
    </section>
  )
}

ActivePrograms.propTypes = exact(propTypes)
ActivePrograms.defaultProps = defaultProps

export default pure(ActivePrograms)
