import { stringify } from 'query-string'
import { decamelizeKeys } from 'humps'

// Decamelize and stringify query params object
function stringifyQuery(query) {
  return stringify(decamelizeKeys(query), { skipNull: true })
}

function makeQueryPath(path, query) {
  if (!query) return path
  return path + '?' + stringifyQuery(query)
}

export default makeQueryPath
