import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { debounce } from 'lodash'
import { Field } from 'redux-form'
import { Input, MaskedInput, Select } from 'lp-components'
import { SearchResultsPanel } from 'components'
import { COUNTRY, PHONE_REGION_CODE } from 'config'
import { parsePhoneNumber, stateListFromCountry } from 'utils'

const propTypes = {
  autoCompleteAddress: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  country: PropTypes.string,
  updateAddressValues: PropTypes.func.isRequired,
  usageLabel: PropTypes.string.isRequired,
  withEmail: PropTypes.bool,
}
const defaultProps = {
  withEmail: true,
}

function GiftContactFields({
  autoCompleteAddress,
  change,
  country,
  updateAddressValues,
  usageLabel,
  withEmail,
}) {
  const [searchResults, updateSearchResults] = useState(null)
  const [resultsPanelOpen, setResultsPanelOpen] = useState(false)
  const closeResultsPanel = () => setResultsPanelOpen(false)
  const openResultsPanel = () => setResultsPanelOpen(true)

  return (
    <>
      <div className="row">
        <div className="col-6">
          <Field
            component={Input}
            label="First Name"
            name="firstName"
            required
            requiredIndicator="*"
          />
        </div>
        <div className="col-6">
          <Field
            component={Input}
            label="Last Name"
            name="lastName"
            required
            requiredIndicator="*"
          />
        </div>
      </div>

      {withEmail && (
        <div className="row">
          <Field
            component={Input}
            label="Email"
            name="email"
            required
            requiredIndicator="*"
          />
        </div>
      )}

      <div className="row">
        <Field
          component={MaskedInput}
          label="Phone Number"
          maskOptions={{
            numericOnly: true,
            blocks: [0, 3, 0, 3, 4],
            delimiters: ['(', ')', ' ', '-'],
            phoneRegionCode: PHONE_REGION_CODE[country],
          }}
          name="phone"
          parse={parsePhoneNumber}
          placeholder="(800) 555-1212"
          required
          requiredIndicator="*"
        />
      </div>
      <Field
        autoComplete="off"
        component={Input}
        id="address-search-input"
        label={`${usageLabel} Address`}
        name="address"
        onChange={debounce(async (_, newSearchQuery) => {
          const normalizedSearchQuery = newSearchQuery.split(' ').join('+')
          const placeSearchResult = await autoCompleteAddress(
            normalizedSearchQuery
          )

          updateSearchResults(placeSearchResult.results)
          openResultsPanel()
        }, 350)}
        required
        requiredIndicator="*"
      />
      <SearchResultsPanel
        closePanel={closeResultsPanel}
        hideLocationName={true}
        isPanelOpen={resultsPanelOpen}
        searchResults={searchResults}
        updateFormValues={updateAddressValues}
      />
      <div className="row">
        <div className="col-6">
          <Field
            component={Select}
            label="Country"
            name="country"
            onChange={() => change('state', '')}
            options={Object.values(COUNTRY)}
            placeholder="Country"
            required
            requiredIndicator="*"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Field
            component={Input}
            label="City"
            name="city"
            required
            requiredIndicator="*"
          />
        </div>
        <div className="col-3">
          <Field
            component={Select}
            label="State"
            name="state"
            options={stateListFromCountry(country)}
            placeholder="State"
            required
            requiredIndicator="*"
          />
        </div>
        <div className="col-3">
          <Field
            component={Input}
            label="Postal Code"
            name="postalCode"
            required
            requiredIndicator="*"
          />
        </div>
      </div>
    </>
  )
}

GiftContactFields.propTypes = exact(propTypes)
GiftContactFields.defaultProps = defaultProps

export default pure(GiftContactFields)
