import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Spinner } from 'lp-components'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { INITIAL_SAVINGS_CLUB_SEARCH, QUERY_KEY } from 'config'
import { AccountType, SearchCriteriaType } from 'types'
import { searchParametersFromQuery, parseQuery } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  children: PropTypes.node.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchCriteria: SearchCriteriaType,
  setSearchCriteria: PropTypes.func.isRequired,
}
const defaultProps = {}

// The SavingsClubSearchProvider provides the *initial* search conditions for
// the Savings Club. While within a Savings Club route, the user will manipulate
// the Savings Club search criteria to change the search results. Note that
// *leaving* a Savings Club route will reset the state to its initial value.
// This means that if the user uses the "Back" browser button to re-enter the
// Savings Club, they will likely see different search results because another
// search API call will be performed.
function SavingsClubSearchProvider({
  account,
  children,
  resetSearch,
  searchCriteria,
  setSearchCriteria,
}) {
  const { search } = useLocation()
  const query = parseQuery(search)
  const { shippingcountry, shippingpostalcode } = account

  useEffect(() => {
    setSearchCriteria({
      ...INITIAL_SAVINGS_CLUB_SEARCH,
      [QUERY_KEY.COUNTRY]: shippingcountry,
      [QUERY_KEY.ZIP]: shippingpostalcode,
      ...searchParametersFromQuery(query),
    })

    return () => resetSearch()
  }, [])

  if (!searchCriteria) return <Spinner />

  return children
}

SavingsClubSearchProvider.propTypes = propTypes
SavingsClubSearchProvider.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    account: globalSelectors.account(state),
    searchCriteria: selectors.searchCriteria(state),
  }
}

const mapDispatchToProps = {
  resetSearch: actions.resetSearch,
  setSearchCriteria: actions.setSearchCriteria,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SavingsClubSearchProvider
)
