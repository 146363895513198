import React from 'react'
import PropTypes from 'prop-types'
import { ProductPrices, MEMBERSHIP_DETAIL } from 'types'
import { pure } from 'recompose'
import { formatCurrency } from 'utils'

const propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  membershipPrices: ProductPrices.isRequired,
}

const defaultProps = {}

function MembershipTypeInputLabel({
  id,
  label: membershipType,
  membershipPrices,
}) {
  const details = MEMBERSHIP_DETAIL[membershipType]
  return (
    <>
      <img
        src={details.image}
        alt=""
      />
      <label htmlFor={id} className="product-details">
        <div className="text-block">
          <h3>{membershipType}</h3>{' '}
          <ul>
            <li><em>{details.coverage || details.usedIn}</em></li>
          </ul>
        </div>
        <div className="price-block">
          <p className="price">
            <span>{formatCurrency(membershipPrices[membershipType])}</span>
            <span className="time"> / year</span>
          </p>
        </div>
      </label>
    </>
  )
}

MembershipTypeInputLabel.propTypes = propTypes
MembershipTypeInputLabel.defaultProps = defaultProps

export default pure(MembershipTypeInputLabel)
