import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import * as Views from './views'
import { GiftSelectionRedirect, GiftStepsLayout } from './components'
import Layout from './Layout'
import { PATH, makeGiftStepsPath, makePath } from 'config'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <GiftSelectionRedirect>
      <Layout>
        <Switch>
          <Route exact path={makePath(path, PATH.ROOT)}>
            <Views.ProductSelection />
          </Route>
          <Route path={makePath(path, PATH.STEPS)}>
            <GiftStepsLayout>
              <Switch>
                <Route path={makeGiftStepsPath(PATH.DELIVERY)}>
                  <Views.Delivery />
                </Route>
                <Route path={makeGiftStepsPath(PATH.RECIPIENT)}>
                  <Views.Recipient />
                </Route>
                <Route path={makeGiftStepsPath(PATH.PAYMENT)}>
                  <Views.Payment />
                </Route>
                <Route path={makeGiftStepsPath(PATH.REVIEW_ORDER)}>
                  <Views.ReviewOrder />
                </Route>
              </Switch>
            </GiftStepsLayout>
          </Route>
          <Route path={makePath(path, PATH.CONFIRM_GIFT)}>
            <Views.ConfirmGift />
          </Route>
        </Switch>
      </Layout>
    </GiftSelectionRedirect>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
