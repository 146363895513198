import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { ReviewStep } from 'member-portal/components'
import * as apiActions from 'api-actions'
import {
  AccountType,
  AppliedDiscountCodeType,
  BillingType,
  MembershipType,
  ReferralType,
} from 'types'
import {
  makeMemberApplicationApplyPath,
  makeMemberApplicationPath,
  PATH,
} from 'config'
import { apiValuesWithPaymentDetails } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  billing: BillingType,
  addPaymentToOpportunity: PropTypes.func.isRequired,
  discountCodeDetails: AppliedDiscountCodeType,
  gclid: PropTypes.string,
  membership: MembershipType.isRequired,
  referralInfo: ReferralType,
  referredByMemberNumber: PropTypes.string,
  oppGuid: PropTypes.string,
  testVariant: PropTypes.string,
}
const defaultProps = {}

function NewMemberReviewOrder({
  account,
  billing,
  addPaymentToOpportunity,
  discountCodeDetails,
  gclid,
  membership,
  referralInfo,
  referredByMemberNumber,
  oppGuid,
  testVariant,
}) {
  return (
    <div>
      <ReviewStep
        account={account}
        confirmAndFinalizePurchase={(formValues) => {
          const {smsOptIn} = formValues
          
          return addPaymentToOpportunity(
            apiValuesWithPaymentDetails({
              account,
              billing,
              discountCodeDetails,
              gclid,
              membership,
              referralInfo,
              referredByMemberNumber,
              oppGuid,
              smsOptIn,
              testVariant,
            }),
          )
        }}
        membership={membership}
        paymentPath={makeMemberApplicationApplyPath(PATH.PAYMENT)}
        nextStep={makeMemberApplicationPath(PATH.CONFIRM_AND_LOGIN)}
        canEditBoat
        canEditContactInfo
        canEditProductSelections
        showDonation
        showMembershipCost
        showTrailerCareCost
      />
    </div>
  )
}

NewMemberReviewOrder.propTypes = propTypes
NewMemberReviewOrder.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    account: memberSelectors.account(state),
    billing: memberSelectors.billing(state),
    discountCodeDetails: memberSelectors.discountCodeDetails(state),
    gclid: memberSelectors.gclid(state),
    membership: memberSelectors.membership(state),
    referralInfo: memberSelectors.referralInfo(state),
    referredByMemberNumber: memberSelectors.referredByMemberNumber(state),
    oppGuid: memberSelectors.oppGuid(state),
    testVariant: memberSelectors.testVariant(state),
  }
}

const mapDispatchToProps = {
  addPaymentToOpportunity: apiActions.addPaymentToOpportunity,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NewMemberReviewOrder,
)
