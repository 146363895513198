import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import { PATH, makePath } from 'config'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <Layout>
      <Switch>
        <Route exact path={makePath(path, PATH.ROOT)}>
          <Views.Boats />
        </Route>
        <Route path={makePath(path, PATH.NEW)}>
          <Views.NewBoat />
        </Route>
        <Route path={makePath(path, PATH.EDIT_BOAT)}>
          <Views.EditBoat />
        </Route>
        <Route path={makePath(path, PATH.EDIT_OTHER_BOAT)}>
          <Views.EditOtherBoat />
        </Route>
        <Route path={makePath(path, PATH.OTHER)}>
          <Views.OtherBoat />
        </Route>
        <Route path={path + PATH.HOME_PORT_TYPE}>
          <Views.HomePortType />
        </Route>
        <Route path={path + PATH.HOME_PORT_ADDRESS}>
          <Views.SearchPortAddress />
        </Route>
        <Route path={path + PATH.HOME_PORT_LOCATION}>
          <Views.LocatePort />
        </Route>
      </Switch>
    </Layout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
