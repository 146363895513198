import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { selectors as memberSelectors } from 'member-reducer'
import { PATH, makeMemberApplicationPath } from 'config'

const propTypes = {
  children: PropTypes.node.isRequired,
  selectedMembershipType: PropTypes.string,
}

const defaultProps = {}

const INITIAL_MEMBER_APPLICATION_PATHS = [
  makeMemberApplicationPath(),
  makeMemberApplicationPath(PATH.ROOT),
  makeMemberApplicationPath(PATH.NEW_MEMBER_QUESTIONNAIRE),

  // Required to prevent redirect after successful membership application
  makeMemberApplicationPath(PATH.CONFIRM_AND_LOGIN),
]

function QuestionnaireRedirect({ children, selectedMembershipType }) {
  const location = useLocation()

  if (
    !selectedMembershipType &&
    !INITIAL_MEMBER_APPLICATION_PATHS.includes(location.pathname)
  ) {
    // Push user back through member application root
    // if no type has been recommended / selected yet
    return <Redirect to={makeMemberApplicationPath(PATH.ROOT)} />
  }

  return children
}

QuestionnaireRedirect.propTypes = propTypes
QuestionnaireRedirect.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    selectedMembershipType: memberSelectors.selectedMembershipType(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  QuestionnaireRedirect
)
