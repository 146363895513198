import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import BackLink from './BackLink'

const propTypes = {
  backLinkText: PropTypes.string,
  backLinkTo: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  subheaderText: PropTypes.string,
}
const defaultProps = {}

function PageHeader({ backLinkText, backLinkTo, headerText, subheaderText }) {
  return (
    <header className="page-header">
      <div className="inner-block-container">
        <div className="inner">
          <div className="text-block">
            <h1>{headerText}</h1>
            {subheaderText && <p>{subheaderText}</p>}
          </div>
          {backLinkTo && (
            <div className="actions">
              <BackLink linkText={backLinkText} to={backLinkTo} />
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

PageHeader.propTypes = exact(propTypes)
PageHeader.defaultProps = defaultProps

export default pure(PageHeader)
