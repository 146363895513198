import React from 'react'
import PropTypes from 'prop-types'
import ExternalLink from './ExternalLink'

const propTypes = {
  name: PropTypes.string.isRequired,
}
const defaultProps = {}

function SmsNotificationLabel({ name }) {
  return (
    <span>
      <label htmlFor={name}>
        I would like confirmations and reminders regarding my Sea Tow membership
        via text to this number, and occasional promotional outreach. I
        understand message frequency will vary and that standard message and
        data rates apply. View{' '}
        <ExternalLink
          className="external-link-in-text"
          href="https://seatow.com/terms/"
          showIcon={false}
        >
          Terms
        </ExternalLink>{' '}
        and{' '}
        <ExternalLink
          className="external-link-in-text"
          href="https://seatow.com/privacy/"
          showIcon={false}
        >
          Privacy
        </ExternalLink>
        .
      </label>
    </span>
  )
}

SmsNotificationLabel.propTypes = propTypes
SmsNotificationLabel.defaultProps = defaultProps

export default SmsNotificationLabel
