import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { selectors } from 'memberships/reducer'
import { selectors as memberSelectors } from 'member-reducer'
import * as apiActions from 'api-actions'
import { SectionHeader } from 'components'
import { MembershipPageHeader } from 'memberships/components'
import { OtherBoatForm } from '../forms'
import { API_KEY_MAP, BoatType, MembershipType } from 'types'
import { PATH, makeMembershipIdPath } from 'config'
import {
  apiValuesWithMappedKeys,
  setInitialValues,
  setObjectIdentifiers,
  withApiAuth,
} from 'utils'

const propTypes = {
  fetchAllMembershipsForAccount: PropTypes.func.isRequired,
  membership: MembershipType.isRequired,
  primaryBoat: BoatType.isRequired,
  updateBoat: PropTypes.func.isRequired,
}
const defaultProps = {}
const OTHER_BOAT_KEYMAP = API_KEY_MAP['OTHER_BOAT_KEYMAP']

function EditOtherBoat({
  fetchAllMembershipsForAccount,
  membership,
  primaryBoat,
  updateBoat,
}) {
  const history = useHistory()
  const location = useLocation()
  const { account__c, sfid: membershipId } = membership
  const { boat } = location.state

  return (
    <>
      <MembershipPageHeader
        backLinkText="Back to Boat & Home Port Information"
        backLinkTo={makeMembershipIdPath(membership.sfid, PATH.BOATS)}
        membership={membership}
      />
      <section className="form-block-container">
        <div className="card card-medium">
          <div className="card-inner">
            <div className="form-block">
              <SectionHeader sectionHeaderName="Edit Other (Boat Club Member, etc.)" />
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <OtherBoatForm
                initialValues={setInitialValues(location, OTHER_BOAT_KEYMAP)}
                boat={boat}
                existingMemberBoat
                name={'OtherBoatForm'}
                nextStep={makeMembershipIdPath(
                  membershipId,
                  PATH.BOATS,
                  PATH.HOME_PORT_TYPE
                )}
                onSubmit={(formValues) => {
                  const apiValues = apiValuesWithMappedKeys(
                    formValues,
                    OTHER_BOAT_KEYMAP
                  )
                  return updateBoat(setObjectIdentifiers(apiValues, boat))
                }}
                onSubmitSuccess={async () => {
                  await fetchAllMembershipsForAccount(account__c)
                  history.push(makeMembershipIdPath(membershipId, PATH.BOATS))
                }}
                primaryBoat={primaryBoat}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

EditOtherBoat.propTypes = propTypes
EditOtherBoat.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    account: selectors.account(state),
    membership: memberSelectors.membership(state),
    primaryBoat: selectors.primaryBoat(state),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  createBoat: apiActions.createBoat,
  fetchAllMembershipsForAccount: apiActions.fetchAllMembershipsForAccount,
  updateBoat: apiActions.updateBoat,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(EditOtherBoat)
