import React from 'react'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Select } from 'lp-components'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { SAVINGS_CLUB_SORT } from 'config'

const propTypes = {
  ...formPropTypes,
}
const defaultProps = {}

function SortSelectorForm() {
  return (
    <form noValidate>
      <Field
        component={Select}
        label="Sort By"
        name="sort"
        options={[...Object.values(SAVINGS_CLUB_SORT)]}
      />
    </form>
  )
}

SortSelectorForm.propTypes = propTypes
SortSelectorForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'SortSelectorForm',
    constraints: {},
  })
)(SortSelectorForm)
