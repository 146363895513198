import { parse } from 'query-string'
import { camelizeKeys } from 'humps'

function parseQuery(search) {
  if (!search) return {}

  return camelizeKeys(parse(search))
}

export default parseQuery
