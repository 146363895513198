import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MembershipType, MEMBERSHIP_TYPE } from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { flashErrorMessage } from 'redux-flash'
import { selectors as memberSelectors } from 'member-reducer'
import * as memberActions from 'member-actions'
import * as apiActions from 'api-actions'
import {
  apiErrorToErrorMessage,
  isTrialPromoType,
  useTrackingParams,
} from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
  clearDiscountCodeStateAndLS: PropTypes.func.isRequired,
  fetchActivationCodeDetails: PropTypes.func.isRequired,
  fetchPromoCodeDetails: PropTypes.func.isRequired,
  fetchReferral: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  isRecreationalCard: PropTypes.bool,
  isRenewal: PropTypes.bool,
  isTrialMembershipsActive: PropTypes.bool,
  membership: MembershipType,
  setActivationCode: PropTypes.func.isRequired,
  setGCLid: PropTypes.func.isRequired,
  setPromoCode: PropTypes.func.isRequired,
  setSelectedMembershipType: PropTypes.func.isRequired,
  setTestVariant: PropTypes.func.isRequired,
  setTrialMembershipType: PropTypes.func.isRequired,
}

const defaultProps = {
  isRenewal: false,
}

function MarketingCodeProvider({
  children,
  clearDiscountCodeStateAndLS,
  fetchActivationCodeDetails,
  fetchPromoCodeDetails,
  fetchReferral,
  flashErrorMessage,
  isRecreationalCard,
  isRenewal,
  isTrialMembershipsActive,
  membership,
  setActivationCode,
  setGCLid,
  setPromoCode,
  setSelectedMembershipType,
  setTestVariant,
  setTrialMembershipType,
}) {
  const { pathname } = useLocation()
  const history = useHistory()
  const membershipStatus = membership?.membership_status__c

  const {
    activationCode,
    promoCode,
    gclid,
    testVariant,
    referralId,
  } = useTrackingParams()

  const handleError = useCallback(
    (_error) => {
      // clear any saved codes if an invalid code is provided
      clearDiscountCodeStateAndLS()
      flashErrorMessage(apiErrorToErrorMessage(_error))
      history.replace(pathname)
    },
    [clearDiscountCodeStateAndLS, flashErrorMessage, history, pathname],
  )

  const applyActivationCode = useCallback(async () => {
    try {
      await fetchActivationCodeDetails(activationCode)
      setActivationCode(activationCode)
    } catch ({ errors: { _error } }) {
      handleError(_error)
    }
  }, [
    activationCode,
    fetchActivationCodeDetails,
    setActivationCode,
    handleError,
  ])

  const applyPromoCode = useCallback(async () => {
    try {
      const promoResponse = await fetchPromoCodeDetails({
        promoCode,
        isRenewal,
        membershipStatus,
      })
      setPromoCode(promoCode)
      const { promotion_type__c: promoType, related_product: relatedProduct } =
        promoResponse
      const isTrialType = isTrialPromoType(promoType)

      if (isTrialMembershipsActive && isTrialType) {
        setSelectedMembershipType(MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP)
        setTrialMembershipType(relatedProduct.name)
      }
    } catch ({ errors: { _error } }) {
      handleError(_error)
    }
  }, [
    promoCode,
    isRenewal,
    membershipStatus,
    isTrialMembershipsActive,
    setSelectedMembershipType,
    setTrialMembershipType,
    fetchPromoCodeDetails,
    setPromoCode,
    handleError,
  ])

  const applyReferralInfo = useCallback(async () => {
    try {
      fetchReferral(referralId)
    } catch ({ errors: { _error } }) {
      handleError(_error)
    }
  }, [referralId, fetchReferral, handleError])

  useEffect(() => {
    if (activationCode && isRecreationalCard) {
      applyActivationCode()
    } else if (promoCode) {
      applyPromoCode()
    } else {
      clearDiscountCodeStateAndLS()
    }
  }, [
    activationCode,
    promoCode,
    applyActivationCode,
    applyPromoCode,
    clearDiscountCodeStateAndLS,
    isRecreationalCard,
  ])

  useEffect(() => {
    if (gclid) setGCLid(gclid)
    if (testVariant) setTestVariant(testVariant)
  }, [
    gclid,
    setGCLid,
    testVariant,
    setTestVariant,
  ])

  useEffect(() => {
    if (referralId) applyReferralInfo()
  }, [applyReferralInfo, referralId])

  return children
}

MarketingCodeProvider.propTypes = propTypes
MarketingCodeProvider.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    isRecreationalCard: memberSelectors.isRecreationalCard(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {
  clearDiscountCodeStateAndLS: memberActions.clearDiscountCodeStateAndLS,
  fetchActivationCodeDetails: apiActions.fetchActivationCodeDetails,
  fetchPromoCodeDetails: apiActions.fetchPromoCodeDetails,
  fetchReferral: apiActions.fetchReferral,
  flashErrorMessage: flashErrorMessage,
  setActivationCode: memberActions.setActivationCode,
  setGCLid: memberActions.setGCLid,
  setPromoCode: memberActions.setPromoCode,
  setSelectedMembershipType: memberActions.setSelectedMembershipType,
  setTestVariant: memberActions.setTestVariant,
  setTrialMembershipType: memberActions.setTrialMembershipType,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MarketingCodeProvider,
)
