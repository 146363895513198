import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import { PATH, QUERY_KEY } from 'config'
import { ERROR_STATUS } from 'types'
import { makeQueryPath } from 'utils'

const propTypes = {}
const defaultProps = {}

// Note that the Sea Tow.com routes are relative to root (/)...
function Routes() {
  return (
    <Switch>
      {/* Routes without the global layout */}
      <Route path={PATH.POST_LOGIN}>
        <Views.PostLogin />
      </Route>

      {/* Routes *with* the global layout */}
      <Route>
        <Layout>
          <Switch>
            <Route exact path={PATH.ROOT}>
              <Views.SeaTow />
            </Route>
            <Route>
              <Redirect
                to={makeQueryPath(PATH.CLIENT_ERROR, {
                  [QUERY_KEY.STATUS]: ERROR_STATUS.NOT_FOUND,
                })}
              />
            </Route>
          </Switch>
        </Layout>
      </Route>
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
