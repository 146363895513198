import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useParams } from 'react-router-dom'
import { StatusIndicator } from 'components'
import { RenewButton } from 'member-portal/components'
import NextInvoice from './NextInvoice'
import { STATUS_CLASS, MembershipType } from 'types'
import { PATH, makeMembershipIdPath } from 'config'

const propTypes = {
  membership: MembershipType.isRequired,
  withAutoRenewStatus: PropTypes.bool,
  withRenewNowCta: PropTypes.bool,
}
const defaultProps = {
  withAutoRenewStatus: false,
  withRenewNowCta: false,
}

function BillingDetails({ membership, withAutoRenewStatus, withRenewNowCta }) {
  const {
    auto_renew__c,
    auto_renew_date,
    communication_member_renewal_amount__c,
    membership_expiration_date__c,
    membership_number__c,
  } = membership

  return (
    <>
      <BillingDetailsHeader
        autorenews={auto_renew__c}
        memberNumber={membership_number__c}
        {...{
          withAutoRenewStatus,
          withRenewNowCta,
        }}
      />
      <NextInvoice
        autorenews={auto_renew__c}
        renewalCost={communication_member_renewal_amount__c}
        invoiceTermDate={
          auto_renew__c ? auto_renew_date : membership_expiration_date__c
        }
        withInvoiceTerms
      />
    </>
  )
}

BillingDetails.propTypes = exact(propTypes)
BillingDetails.defaultProps = defaultProps

export default pure(BillingDetails)

function BillingDetailsHeader({
  autorenews,
  memberNumber,
  withAutoRenewStatus,
  withRenewNowCta,
}) {
  const { sfid: membershipSfid } = useParams()

  return (
    <header className="with-actions">
      <div className="icon-and-header">
        <img
          src="/assets/images/icons/with-background/ST_YIcon_M-Card.svg"
          alt=""
          className="icon"
        />
        <h2>Membership Billing Details</h2>
      </div>
      <div className="actions">
        {withAutoRenewStatus && (
          <StatusIndicator
            statusClass={autorenews ? STATUS_CLASS.OK : STATUS_CLASS.NOT_OK}
            statusText={`Auto Renew: ${autorenews ? 'On' : 'Off'}`}
          />
        )}
        {withRenewNowCta && (
          <RenewButton
            to={makeMembershipIdPath(
              membershipSfid,
              PATH.RENEW_MEMBERSHIP,
              PATH.SELECTION
            )}
            className="button-primary"
            aria-label={`Renew membership now for membership ${memberNumber}`}
          >
            Renew Now
          </RenewButton>
        )}
      </div>
    </header>
  )
}
