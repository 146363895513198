import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  OFFER_GROUP,
  OFFER_EMPTY_STATE,
  SavingsClubType,
  AccountType,
} from 'types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { TabBar } from 'lp-components'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as programsPortalSelectors } from 'programs-portal/reducer'
import * as apiActions from 'api-actions'
import { PageHeader, QuickLinks, ButtonLink } from 'components'
import { Offer, SavingsClubNotificationCenter } from '../components'
import { SAVINGS_CLUB_INFO_LINKS, PATH, makeProgramsPortalPath } from 'config'
import { withApiAuth } from 'utils'
import { isEmpty, values, startCase, first } from 'lodash'

const propTypes = {
  offers: SavingsClubType.isRequired,
  updateOffer: PropTypes.func.isRequired,
  fetchAllProgramsForPartner: PropTypes.func.isRequired,
  account: AccountType.isRequired,
}

const defaultProps = {}

function SavingsClubOffers({
  offers,
  updateOffer,
  fetchAllProgramsForPartner,
  account,
}) {
  const { sfid: accountId } = account
  useEffect(() => {
    // fetch on each mount to pull in latest updates after creating/editing offers
    fetchAllProgramsForPartner(accountId)
  }, [])

  const categoryTabs = values(OFFER_GROUP).map((category) => ({
    key: startCase(category),
    value: category,
  }))
  const [activeTab, setActiveTab] = useState(first(categoryTabs).value)
  const offersForTab = offers[activeTab]
  const emptyState = OFFER_EMPTY_STATE[activeTab]

  return (
    <>
      <PageHeader
        headerText="Savings Club"
        backLinkTo={makeProgramsPortalPath(PATH.PROGRAMS_DASHBOARD)}
        backLinkText="Back to Dashboard"
      />
      <div className="dashboard-block-container">
        <SavingsClubNotificationCenter account={account} />
        <div className="content-with-aside-wrapper">
          <div className="content-with-aside">
            <section>
              <h2>Your Offers</h2>
              <div className="action-and-tabs">
                <ButtonLink
                  className="button-secondary plus-left-button-icon"
                  to={makeProgramsPortalPath(
                    PATH.SAVINGS_CLUB_OFFERS,
                    PATH.NEW
                  )}
                >
                  Create New Offer
                </ButtonLink>
                <TabBar
                  options={categoryTabs}
                  value={activeTab}
                  onChange={setActiveTab}
                />
              </div>
              {!isEmpty(offersForTab) ? (
                <>
                  {offersForTab.map((offer) => (
                    <Offer
                      key={offer.id}
                      offer={offer}
                      updateOffer={updateOffer}
                      fetchOffers={fetchAllProgramsForPartner}
                      showActions={activeTab === OFFER_GROUP.ACTIVE}
                      accountId={accountId}
                    />
                  ))}
                </>
              ) : (
                <div className="empty-state">
                  <h3>
                    <strong>{emptyState.heading}</strong>
                  </h3>
                  <p>{emptyState.body}</p>
                </div>
              )}
            </section>
          </div>
          <aside>
            <QuickLinks
              links={SAVINGS_CLUB_INFO_LINKS}
              header="Savings Club Information"
            />
          </aside>
        </div>
      </div>
    </>
  )
}

SavingsClubOffers.propTypes = propTypes

SavingsClubOffers.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    offers: programsPortalSelectors.savingsClubProgram(state),
    account: globalSelectors.account(state),
  }
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  fetchAllProgramsForPartner: apiActions.fetchAllProgramsForPartner,
  updateOffer: apiActions.updateOffer,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps)
)(SavingsClubOffers)
