import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { UnexpectedError } from 'components'
import { UpgradeButton } from 'member-portal/components'
import { MEMBERSHIP_TYPE, ProductPrices } from 'types'
import { COST, SEATOW_PHONE, SEATOW_PHONE_DIGITS } from 'config'
import { formatCurrency } from 'utils'

const propTypes = {
  isTrialMembershipsActive: PropTypes.bool,
  membershipPrices: ProductPrices.isRequired,
  memberType: PropTypes.string.isRequired,
  upgradePath: PropTypes.string.isRequired,
}
const defaultProps = {}

function MembershipBenefits({
  isTrialMembershipsActive,
  membershipPrices,
  memberType,
  upgradePath,
}) {
  const membershipBenefitsSummary = getMembershipBenefitsSummary(
    isTrialMembershipsActive,
    upgradePath,
  )

  return (
    <div className="accordion-content with-checkmark-list">
      {memberType === MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP ? (
        <TrialBenefits
          isTrialMembershipsActive={isTrialMembershipsActive}
          membershipPrices={membershipPrices}
        />
      ) : (
        <BenefitsSelector
          membershipBenefitsSummary={membershipBenefitsSummary}
          memberType={memberType}
        />
      )}
    </div>
  )
}

MembershipBenefits.propTypes = propTypes
MembershipBenefits.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
  }
}

export default compose(connect(mapStateToProps, null))(MembershipBenefits)

function BenefitsSelector({ membershipBenefitsSummary, memberType }) {
  if (membershipBenefitsSummary[memberType]) {
    return (
      <ul>
        {membershipBenefitsSummary[memberType].map((benefit) => (
          <li key={benefit.title}>
            <p>
              <b>{benefit.title} </b>
              {benefit.content}
            </p>
          </li>
        ))}
      </ul>
    )
  } else {
    return (
      <UnexpectedError
        message={`[ERROR]: unknown membership type: ${memberType}`}
      />
    )
  }
}

function TrialBenefits({ isTrialMembershipsActive, membershipPrices }) {
  const goldMembershipYearlyCost =
    membershipPrices[MEMBERSHIP_TYPE.GOLD_CARD] ?? 0
  const goldMembershipMonthlyCost = formatCurrency(
    goldMembershipYearlyCost / 12,
  )

  return (
    <>
      <p>
        <b>
          {isTrialMembershipsActive
            ? 'Your Sea Tow Trial Card membership includes:'
            : 'We hope you are enjoying your complimentary trial Sea Tow membership which includes:'}
        </b>
      </p>

      <ul>
        <li>
          <p>
            Service for the single, primary vessel listed on your membership,
            nationwide on all fresh and saltwater.
          </p>
        </li>
        <li>
          <p>
            <b>No Out-of-Pocket costs</b> for covered services{' '}
            <b>& up to {COST.REIMBURSEMENT_LIMIT} of service</b> when boating
            outside your home area.
          </p>
        </li>
        <li>
          <p>
            <b>Ask Sea Tow&trade;:</b> Membership gives you a direct connection
            to your local captain. Ask about local navigation and referrals,
            anchorages, troubleshooting, tides, weather, and more.
          </p>
        </li>
      </ul>
      <p />
      <p>
        <b>
          {isTrialMembershipsActive ? (
            <i>UPGRADE TO A LAKE OR GOLD CARD TO GET SO MUCH MORE:</i>
          ) : (
            <i>Join today with a Gold Card membership and get so much more!</i>
          )}
        </b>
      </p>
      <ul>
        <li>
          <p>
            <b>Service For:</b> Every boat you own regardless of who is
            operating
            {isTrialMembershipsActive
              ? ' and every boat you rent or bareboat charter.'
              : ' and the listed member on boats chartered, rented, or borrowed (cannot be a guest onboard).'}
          </p>
        </li>
        <li>
          <p>
            <b>100% Dock-to-Dock Tow Coverage</b> from home port to ramp or
            repair facility up to 25-miles on all covered vessels.
          </p>
        </li>
        <li>
          <p>
            Optional <b>Trailer Care</b> for roadside assistance for your marine
            trailers and towing vehicle.
          </p>
        </li>
      </ul>
      <p />
      {!isTrialMembershipsActive && (
        <p>
          <b>So many benefits for only {goldMembershipMonthlyCost} a month!</b>
        </p>
      )}
    </>
  )
}

const COMMON_BENEFITS = {
  PRIORITY_SERVICE: {
    title: '24/7 Priority Service:',
    content: 'Sea Tow members served before non-members.',
  },
  PRIORITY_SERVICE_ALWAYS: {
    title: '24/7 Priority Service:',
    content: 'Sea Tow members always served before non-members.',
  },
  SERVICE_AREA_ALL: {
    title: 'Service Area:',
    content: 'All fresh & saltwater nationwide.',
  },
  SERVICE_AREA_FRESH: {
    title: 'Service Area:',
    content: 'Freshwater, excluding AK, FL, HI, PR, VI.',
  },
  SERVICE_FOR: {
    title: 'Service For:',
    content: 'The single, primary vessel listed on your membership.',
  },
  DOCK_TO_DOCK_COVERAGE: {
    title: '100% Dock-to-Dock Tow Coverage',
    content:
      'from home port to ramp or repair facility up to 25-miles on all covered vessels.',
  },
  REIMBURSEMENT: {
    title: `Up to ${COST.REIMBURSEMENT_LIMIT} in service`,
    content: 'when boating outside of your home area.',
  },
  ASK_SEA_TOW: {
    title: 'Ask Sea Tow™:',
    content:
      'Membership gives you a direct connection to your local captain. Ask about local navigation and referrals, anchorages, troubleshooting, tides, weather, and more.',
  },
  TRANSFERRABLE: {
    title: 'Membership is Transferrable:',
    content: `Call us at ${SEATOW_PHONE} (${SEATOW_PHONE_DIGITS}) to assist with transferring any remaining time on your membership to a friend or family member.`,
  },
}

const getMembershipBenefitsSummary = (
  isTrialMembershipsActive,
  upgradePath,
) => {
  return {
    [MEMBERSHIP_TYPE.COMMERCIAL_CARD]: [
      COMMON_BENEFITS.PRIORITY_SERVICE,
      COMMON_BENEFITS.SERVICE_AREA_ALL,
      COMMON_BENEFITS.SERVICE_FOR,
      COMMON_BENEFITS.REIMBURSEMENT,
      COMMON_BENEFITS.TRANSFERRABLE,
    ],
    [MEMBERSHIP_TYPE.FLEET_CARD]: [
      COMMON_BENEFITS.PRIORITY_SERVICE,
      COMMON_BENEFITS.SERVICE_AREA_ALL,
      COMMON_BENEFITS.SERVICE_FOR,
      COMMON_BENEFITS.REIMBURSEMENT,
      COMMON_BENEFITS.TRANSFERRABLE,
    ],
    [MEMBERSHIP_TYPE.GOLD_CARD]: [
      COMMON_BENEFITS.PRIORITY_SERVICE_ALWAYS,
      COMMON_BENEFITS.SERVICE_AREA_ALL,
      {
        ...COMMON_BENEFITS.SERVICE_FOR,
        content: `Every boat you own regardless of who is operating
        ${isTrialMembershipsActive
            ? ' and every boat you rent or bareboat charter.'
            : ' and the listed member on boats chartered, rented or borrowed (cannot be guest onboard).'
          }`,
      },
      COMMON_BENEFITS.DOCK_TO_DOCK_COVERAGE,
      COMMON_BENEFITS.REIMBURSEMENT,
      COMMON_BENEFITS.ASK_SEA_TOW,
      COMMON_BENEFITS.TRANSFERRABLE,
    ],
    [MEMBERSHIP_TYPE.LAKE_CARD]: [
      COMMON_BENEFITS.PRIORITY_SERVICE_ALWAYS,
      {
        ...COMMON_BENEFITS.SERVICE_AREA_FRESH,
        content: (
          <>
            Freshwater, excluding AK, FL, HI, PR, VI. Need service in these
            states or on saltwater too?{' '}
            <UpgradeButton to={upgradePath} className="link-primary">
              Upgrade
            </UpgradeButton>{' '}
            to a Gold Card membership today for only {COST.GOLD_UPGRADE}.
          </>
        ),
      },
      {
        ...COMMON_BENEFITS.SERVICE_FOR,
        content:
          'Every boat you own regardless of who is operating and every boat you rent or bareboat charter.',
      },
      COMMON_BENEFITS.DOCK_TO_DOCK_COVERAGE,
      COMMON_BENEFITS.REIMBURSEMENT,
      COMMON_BENEFITS.ASK_SEA_TOW,
      COMMON_BENEFITS.TRANSFERRABLE,
    ],
    [MEMBERSHIP_TYPE.LAKE_FLEET_CARD]: [
      COMMON_BENEFITS.PRIORITY_SERVICE,
      COMMON_BENEFITS.SERVICE_AREA_FRESH,
      COMMON_BENEFITS.SERVICE_FOR,
      COMMON_BENEFITS.REIMBURSEMENT,
      COMMON_BENEFITS.TRANSFERRABLE,
    ],
    [MEMBERSHIP_TYPE.PROFESSIONAL_MARINER_CARD]: [
      COMMON_BENEFITS.PRIORITY_SERVICE,
      COMMON_BENEFITS.SERVICE_AREA_ALL,
      {
        ...COMMON_BENEFITS.SERVICE_FOR,
        content:
          'The individual listed as the member on any vessel they are operating and the master of.',
      },
      COMMON_BENEFITS.REIMBURSEMENT,
      COMMON_BENEFITS.TRANSFERRABLE,
    ],
  }
}
