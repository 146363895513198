import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { isNil } from 'lodash'
import classnames from 'classnames'
import { Button, Modal } from 'lp-components'
import { RemoveBoatForm } from '../forms'
import { BOAT_STATUS, BoatType, MembershipType } from 'types'
import { PATH, makeMembershipIdPath } from 'config'
import { boatMake, useToggle } from 'utils'
import HomePortMapAndAddress from './HomePortMapAndAddress'

const propTypes = {
  boat: BoatType.isRequired,
  fetchAllMembershipsForAccount: PropTypes.func.isRequired,
  membership: MembershipType.isRequired,
  setOldInactiveAndSetNewPrimary: PropTypes.func.isRequired,
  updateBoat: PropTypes.func.isRequired,
}
const defaultProps = {}

function Boat({
  boat,
  fetchAllMembershipsForAccount,
  membership,
  setOldInactiveAndSetNewPrimary,
  updateBoat,
}) {
  const [moreBoatDetails, toggleMoreBoatDetails] = useToggle()
  const [showRemoveBoatModal, toggleShowRemoveBoatModal] = useToggle()
  const {
    address__c: address,
    geolocation2__latitude__s: lat,
    geolocation2__longitude__s: lng,
    home_port_city__c: city,
    home_port_country__c: country,
    home_port_state__c: state,
    marina_name__c: homePortName,
  } = boat
  const { boats } = membership

  return (
    <div
      className={classnames('accordion-block', { 'is-open': moreBoatDetails })}
    >
      <div className="accordion-header">
        <BoatInfoSnippet
          boat={boat}
          membership={membership}
          moreBoatDetails={moreBoatDetails}
          toggleMoreBoatDetails={toggleMoreBoatDetails}
          toggleShowRemoveBoatModal={toggleShowRemoveBoatModal}
          totalBoats={boats.length}
        />
      </div>

      {showRemoveBoatModal && (
        <RemoveBoatModal
          boat={boat}
          boats={boats}
          fetchAllMembershipsForAccount={fetchAllMembershipsForAccount}
          membership={membership}
          setOldInactiveAndSetNewPrimary={setOldInactiveAndSetNewPrimary}
          toggleShowRemoveBoatModal={toggleShowRemoveBoatModal}
          updateBoat={updateBoat}
        />
      )}

      {moreBoatDetails && (
        <div className="content">
          <div className="row">
            <GeneralDetails boat={boat} />
            <div className="col-6">
              <h3>Home Port Details</h3>
              <div className="content-with-thumbnail">
                <HomePortMapAndAddress
                  address={address}
                  city={city}
                  country={country}
                  homePortName={homePortName}
                  lat={lat}
                  lng={lng}
                  state={state}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Boat.propTypes = propTypes
Boat.defaultProps = defaultProps

export default Boat

function BoatInfoSnippet({
  boat,
  membership,
  toggleMoreBoatDetails,
  toggleShowRemoveBoatModal,
  totalBoats,
}) {
  const {
    name__c,
    marina_name__c,
    name_of_boat_club__c,
    no_boat_reason__c,
    primary_boat__c,
  } = boat
  const { sfid: membershipId } = membership

  return (
    <>
      <div className="header">
        <div>
          <h3>{boatMake(boat)}</h3>
          <p>{name__c ?? name_of_boat_club__c ?? marina_name__c}</p>
        </div>
        <div className="actions">
          <Link
            className="link-primary"
            to={{
              pathname: makeMembershipIdPath(
                membershipId,
                PATH.BOATS,
                isNil(no_boat_reason__c) ? PATH.EDIT_BOAT : PATH.EDIT_OTHER_BOAT
              ),
              state: { boat: boat },
            }}
          >
            Edit Boat
          </Link>
          {(primary_boat__c === false ||
            (primary_boat__c && totalBoats > 1)) && (
            <Button
              onClick={toggleShowRemoveBoatModal}
              className="link-secondary"
            >
              Remove Boat
            </Button>
          )}
        </div>
      </div>
      <div className="trigger">
        <Button onClick={toggleMoreBoatDetails} className="link-secondary">
          <img
            src="/assets/images/icons/dropdown.svg"
            alt=""
            className="icon"
          />
        </Button>
      </div>
    </>
  )
}

function GeneralDetails({ boat }) {
  const {
    color__c,
    engine_type__c,
    fuel_type__c,
    hull_type__c,
    no_boat_reason__c,
    number_of_engines__c,
    registration_document__c,
  } = boat

  return (
    <div className="col-6">
      {isNil(no_boat_reason__c) && (
        <div>
          <h3>General Details</h3>
          <p>Reg/Doc#: {registration_document__c ?? '—'}</p>
          <p>Color: {color__c ?? '—'}</p>
          <p>Hull Type: {hull_type__c ?? '—'}</p>
          <p>Fuel Type: {fuel_type__c ?? '—'}</p>
          <p>Engine Type: {engine_type__c ?? '—'}</p>
          <p>Number of Engines: {number_of_engines__c ?? '—'}</p>
        </div>
      )}
    </div>
  )
}

function RemoveBoatModal({
  boat,
  boats,
  fetchAllMembershipsForAccount,
  membership,
  setOldInactiveAndSetNewPrimary,
  toggleShowRemoveBoatModal,
  updateBoat,
}) {
  const { primary_boat__c: isPrimaryBoat } = boat
  const activeNonPrimaryBoats = boats.filter(
    ({ boat_status__c, primary_boat__c }) =>
      primary_boat__c === false && boat_status__c === BOAT_STATUS.ACTIVE
  )
  const { account__c } = membership

  return (
    <div>
      <Modal onClose={() => toggleShowRemoveBoatModal()}>
        <h2>Are you sure you want to remove this boat?</h2>
        <p>
          You should only remove vessels that you have sold, were damaged or
          destroyed, or are otherwise not in use anymore. All memberships must
          have a Primary Boat or Other Type of Use listed.
        </p>
        <p>
          Please do not remove your Primary Boat until you have added a
          replacement. You can do so by adding a Secondary Vessel and at that
          time choosing for it to be your Primary Vessel.
        </p>

        <RemoveBoatForm
          activeNonPrimaryBoats={activeNonPrimaryBoats}
          isPrimaryBoat={isPrimaryBoat}
          onSubmit={(removeBoatFormValues) => {
            const { removalReason } = removeBoatFormValues

            if (isPrimaryBoat) {
              const { newPrimary: newPrimaryId } = removeBoatFormValues
              const newPrimaryBoat = activeNonPrimaryBoats.find(
                ({ id }) => id === Number(newPrimaryId)
              )

              return setOldInactiveAndSetNewPrimary(
                boat,
                newPrimaryBoat,
                removalReason
              )
            } else {
              boat.boat_status__c = removalReason
              return updateBoat(boat)
            }
          }}
          onSubmitSuccess={() => {
            fetchAllMembershipsForAccount(account__c)
            toggleShowRemoveBoatModal()
          }}
          toggleShowRemoveBoatModal={toggleShowRemoveBoatModal}
        />
      </Modal>
    </div>
  )
}
