import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { RegistrationForm } from '../forms'
import * as apiActions from 'api-actions'
import { API_KEY_MAP } from 'types'
import { PATH, makeMarketingSitePath, makeMemberPortalPath } from 'config'
import {
  apiValuesWithMappedKeys,
  forceMemberPortalReload,
  withApiAuth,
} from 'utils'

const propTypes = {
  verifyMembership: PropTypes.func.isRequired,
}
const defaultProps = {}

function ExistingMemberRegistration({ verifyMembership }) {
  const { logout, user } = useAuth0()
  const { state } = useLocation()
  const history = useHistory()
  
  const registrationFailureUrl = makeMemberPortalPath(
    PATH.REGISTRATION,
    PATH.ERROR,
  )

  // If we have no Auth0 user available, registration cannot continue...
  if (!user) {
    return <Redirect to={registrationFailureUrl} />
  }

  const { sub: auth0UserId } = user
  const failRegistration = () => history.replace(registrationFailureUrl, state)

  return (
    <div className="auth">
      <div className="modal">
        <div className="ReactModal__Overlay ReactModal__Overlay--after-open modal-fade-screen">
          <div className="modal-inner auth-card">
            <header>
              <img src="/assets/images/logo-color.svg" alt="Sea Tow" />
              <h2>Register</h2>
              <img
                src="/assets/images/member-card-example.png"
                alt=""
                className="helper-image"
              />
            </header>
            <RegistrationForm
              cancel={() =>
                logout({
                  logoutParams: {
                    returnTo: makeMarketingSitePath(PATH.SEATOW_LOGOUT),
                  },
                })
              }
              onSubmit={(registrationFormValues) => {
                const registrationApiValues = apiValuesWithMappedKeys(
                  registrationFormValues,
                  API_KEY_MAP.REGISTRATION_KEY_MAP,
                )

                return verifyMembership({
                  ...registrationApiValues,
                  auth0_user_id: auth0UserId,
                })
              }}
              onSubmitSuccess={() => forceMemberPortalReload(state?.returnTo)}
              onSubmitFail={failRegistration}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ExistingMemberRegistration.propTypes = propTypes
ExistingMemberRegistration.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

const mapApiAuthToProps = {
  verifyMembership: apiActions.verifyMembership,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApiAuth(mapApiAuthToProps),
)(ExistingMemberRegistration)
