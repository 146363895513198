import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Modal, Button } from 'lp-components'
import { useConfirm } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function ConfirmModalProvider({ children }) {
  const {
    heading,
    prompt,
    confirmLabel,
    cancelLabel,
    isOpen,
    proceed,
    cancel,
    ...rest
  } = useConfirm()

  return (
    <>
      {children}
      {isOpen && (
        <Modal onClose={cancel} {...rest}>
          <h2>{heading}</h2>
          <p className="with-line-breaks">{prompt}</p>
          <div className="button-group">
            {cancelLabel && (
              <Button onClick={cancel} className="button-primary-outline">
                {cancelLabel}
              </Button>
            )}
            {confirmLabel && <Button onClick={proceed}>{confirmLabel}</Button>}
          </div>
        </Modal>
      )}
    </>
  )
}

ConfirmModalProvider.propTypes = exact(propTypes)
ConfirmModalProvider.defaultProps = defaultProps

export default pure(ConfirmModalProvider)
