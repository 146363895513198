import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useHistory } from 'react-router-dom'
import { ProgramApplicationForm } from '../forms'
import { PATH, makeProgramsPortalPath } from 'config'
import { API_KEY_MAP, AccountType, ContactType } from 'types'
import { apiValuesWithMappedKeys, formValuesWithMappedKeys } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
  apply: PropTypes.func.isRequired,
  contact: ContactType.isRequired,
  programImage: PropTypes.string.isRequired,
  successPath: PropTypes.string.isRequired,
}
const defaultProps = {}

function ProgramApplication({
  account,
  apply,
  contact,
  programImage,
  successPath,
}) {
  const history = useHistory()

  return (
    <div className="card with-image-header capped-image">
      <img alt="" src={programImage} />
      <div className="card-inner">
        <header>
          <h2>Apply to Participate</h2>
          <p>
            Please submit your name and contact information so that someone can
            reach out to you about participation.
          </p>
        </header>
        <ProgramApplicationForm
          initialValues={formInitialValues(account, contact)}
          onSubmit={(formValues) => {
            const mappedApiValues = apiValuesWithMappedKeys(
              formValues,
              API_KEY_MAP.PROGRAM_APPLICATION_KEYMAP
            )

            return apply(mappedApiValues)
          }}
          onSubmitSuccess={() =>
            history.push(makeProgramsPortalPath(successPath, PATH.THANK_YOU))
          }
        />
      </div>
    </div>
  )
}

ProgramApplication.propTypes = exact(propTypes)
ProgramApplication.defaultProps = defaultProps

export default pure(ProgramApplication)

function formInitialValues(account, contact) {
  const {
    name,
    public_facing_url__c,
    shippingcity,
    shippingcountry,
    shippingpostalcode,
    shippingstate,
    shippingstreet,
    sfid,
  } = account
  const { email, firstname, lastname, mobilephone, phone } = contact

  return formValuesWithMappedKeys(
    {
      accountid: sfid,
      email,
      firstname,
      lastname,
      name,
      phone: phone ?? mobilephone,
      public_facing_url__c,
      shippingcity,
      shippingcountry,
      shippingpostalcode,
      shippingstate,
      shippingstreet,
    },
    API_KEY_MAP.PROGRAM_APPLICATION_KEYMAP
  )
}
