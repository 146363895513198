import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Spinner } from 'lp-components'
import { selectors } from '../reducer'
import { selectors as memberSelectors } from 'member-reducer'
import * as memberActions from 'member-actions'
import { AllMembershipsType, MembershipType } from 'types'
import { findMembershipBySfid } from 'utils'

const propTypes = {
  allMemberships: AllMembershipsType,
  children: PropTypes.node.isRequired,
  clearMembership: PropTypes.func.isRequired,
  membership: MembershipType,
  setMembership: PropTypes.func.isRequired,
}
const defaultProps = {}

function MembershipProvider({
  allMemberships,
  children,
  clearMembership,
  membership,
  setMembership,
}) {
  const { sfid: membershipSfid } = useParams()

  useEffect(() => {
    const { memberships } = allMemberships
    const membership = findMembershipBySfid(memberships, membershipSfid)

    setMembership(membership)

    return () => clearMembership()
  }, [allMemberships, membershipSfid])

  if (!membership) return <Spinner />

  return children
}

MembershipProvider.propTypes = propTypes
MembershipProvider.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    allMemberships: selectors.allMemberships(state),
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {
  clearMembership: memberActions.clearMembership,
  setMembership: memberActions.setMembership,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MembershipProvider
)
