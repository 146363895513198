import React from 'react'
import PropTypes from 'prop-types'
// import * as Types from 'types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { Field } from 'redux-form'
import { Checkbox, Input, MaskedInput, Select, Textarea } from 'lp-components'
import { COUNTRY, MAXIMUM_DESCRIPTION_LENGTH, PHONE_REGION_CODE } from 'config'
import { parsePhoneNumber, stateListFromCountry } from 'utils'

const propTypes = {
  change: PropTypes.func.isRequired,
  // Note that these field values are obtained from formValues() Redux Form
  // calls and are undefined when this component is first rendered. Thus,
  // they are marked as not required.
  mailingAddressSameAsPublic: PropTypes.bool,
  mailingCountry: PropTypes.string,
  multipleLocation: PropTypes.bool,
  publicCountry: PropTypes.string,
}
const defaultProps = {}

function PartnerAccountFields({
  change,
  mailingAddressSameAsPublic,
  mailingCountry,
  multipleLocation,
  publicCountry,
}) {
  return (
    <>
      <div className="row">
        <div className="col-6">
          <Field
            component={Input}
            label="Business Name"
            name="businessName"
            required
            requiredIndicator="*"
          />
        </div>
        <div className="col-6">
          <Field
            component={Input}
            label="Public Email"
            name="publicEmail"
            required
            requiredIndicator="*"
          />
        </div>
      </div>

      <Field
        component={Input}
        label="Public/Physical Address"
        name="publicAddress"
        required
        requiredIndicator="*"
      />
      <div className="row">
        <div className="col-6">
          <Field
            component={Select}
            label="Country"
            name="publicCountry"
            onChange={() => change('publicState', '')}
            options={Object.values(COUNTRY)}
            placeholder="Country"
            required
            requiredIndicator="*"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Field
            component={Input}
            label="City"
            name="publicCity"
            required
            requiredIndicator="*"
          />
        </div>
        <div className="col-3">
          <Field
            component={Select}
            label="State"
            name="publicState"
            options={stateListFromCountry(publicCountry)}
            placeholder="State"
            required
            requiredIndicator="*"
          />
        </div>
        <div className="col-3">
          <Field
            component={Input}
            label="Postal Code"
            name="publicPostalCode"
            required
            requiredIndicator="*"
          />
        </div>
      </div>

      <div className="checkbox-group">
        <Field
          component={Checkbox}
          label="More than one location"
          name="multipleLocation"
        />
        {multipleLocation && (
          <p>
            <i>
              We will be in touch to get more information on additional
              locations
            </i>
          </p>
        )}
        <Field
          component={Checkbox}
          label="Mailing address same as public address (deselect if different)"
          name="mailingAddressSameAsPublic"
        />
      </div>

      {!mailingAddressSameAsPublic && (
        <>
          <Field
            component={Input}
            label="Mailing Address"
            name="mailingAddress"
            required
            requiredIndicator="*"
          />
          <div className="row">
            <div className="col-6">
              <Field
                component={Select}
                label="Country"
                name="mailingCountry"
                onChange={() => change('mailingState', '')}
                options={Object.values(COUNTRY)}
                placeholder="Country"
                required
                requiredIndicator="*"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Field
                component={Input}
                label="City"
                name="mailingCity"
                required
                requiredIndicator="*"
              />
            </div>
            <div className="col-3">
              <Field
                component={Select}
                label="State"
                name="mailingState"
                options={stateListFromCountry(mailingCountry)}
                placeholder="State"
                required
                requiredIndicator="*"
              />
            </div>
            <div className="col-3">
              <Field
                component={Input}
                label="Postal Code"
                name="mailingPostalCode"
                required
                requiredIndicator="*"
              />
            </div>
          </div>
        </>
      )}
      <hr />
      <div className="group-block">
        <div className="row">
          <div className="col-6">
            <Field
              component={MaskedInput}
              label="Public Phone Number"
              maskOptions={{
                numericOnly: true,
                blocks: [0, 3, 0, 3, 4],
                delimiters: ['(', ')', ' ', '-'],
                phoneRegionCode: PHONE_REGION_CODE[publicCountry],
              }}
              name="phone"
              parse={parsePhoneNumber}
              placeholder="(800) 555-1212"
              required
              requiredIndicator="*"
            />
          </div>
          <div className="col-6">
            <Field
              component={Input}
              label="Website URL"
              name="websiteUrl"
              placeholder="https://www.example.com"
            />
          </div>
        </div>

        <Field
          component={Textarea}
          label="Business Description"
          maxLength={MAXIMUM_DESCRIPTION_LENGTH}
          name="description"
          placeholder="Description of business offerings as well as general information such as history and mission"
          required
          requiredIndicator="*"
        />
      </div>
    </>
  )
}

PartnerAccountFields.propTypes = exact(propTypes)
PartnerAccountFields.defaultProps = defaultProps

export default pure(PartnerAccountFields)
