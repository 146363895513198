import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { SavingsClubSearchProvider } from './components'
import * as Views from './views'
import Layout from './Layout'
import { PATH, makePath } from 'config'

const propTypes = {}

const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <Layout>
      <SavingsClubSearchProvider>
        <Switch>
          <Route exact path={makePath(path, PATH.ROOT)}>
            <Views.SavingsClub />
          </Route>
          <Route path={makePath(path, PATH.OFFERS)}>
            <Views.SavingsClubOfferShow />
          </Route>
        </Switch>
      </SavingsClubSearchProvider>
    </Layout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
