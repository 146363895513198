import React from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ExternalLink } from 'components'
import { AccountType } from 'types'
import { formatPhoneNumber } from 'utils'

const propTypes = {
  account: AccountType.isRequired,
}
const defaultProps = {}

function BusinessSidePanel({ account }) {
  const {
    partner_facebook__c,
    partner_instagram__c,
    partner_linkedin__c,
    phone,
    public_facing_url__c,
    shippingcity,
    shippingcountry,
    shippingpostalcode,
    shippingstate,
    shippingstreet,
  } = account

  return (
    <aside>
      <section className="card contact-links">
        <h3>Contact Details</h3>
        <ul>
          <li>
            <img
              src="/assets/images/icons/location.svg"
              alt=""
              className="icon"
            />
            <p>
              {shippingstreet}, {shippingcity}, {shippingstate}{' '}
              {shippingpostalcode}, {shippingcountry}
            </p>
          </li>
          <li>
            <img src="/assets/images/icons/phone.svg" alt="" className="icon" />
            <p>{formatPhoneNumber(phone)}</p>
          </li>
          <li>
            <img
              src="/assets/images/icons/website.svg"
              alt=""
              className="icon"
            />
            <ExternalLink href={public_facing_url__c}>Website</ExternalLink>
          </li>

          <li>
            <ul className="social-links">
              {partner_facebook__c && (
                <li>
                  <ExternalLink
                    aria-label="Facebook"
                    href={partner_facebook__c}
                  >
                    <img
                      src="/assets/images/social-icons/facebook.svg"
                      alt=""
                    />
                  </ExternalLink>
                </li>
              )}
              {partner_instagram__c && (
                <li>
                  <ExternalLink
                    aria-label="Instagram"
                    href={partner_instagram__c}
                  >
                    <img
                      src="/assets/images/social-icons/instagram.svg"
                      alt=""
                    />
                  </ExternalLink>
                </li>
              )}
              {partner_linkedin__c && (
                <li>
                  <ExternalLink
                    aria-label="LinkedIn"
                    href={partner_linkedin__c}
                  >
                    <img
                      src="/assets/images/social-icons/linkedin.svg"
                      alt=""
                    />
                  </ExternalLink>
                </li>
              )}
            </ul>
          </li>
        </ul>
      </section>
    </aside>
  )
}

BusinessSidePanel.propTypes = exact(propTypes)
BusinessSidePanel.defaultProps = defaultProps

export default pure(BusinessSidePanel)
