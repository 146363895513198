import React from 'react'
import PropTypes from 'prop-types'
import { PageHeader, Paginator } from 'components'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { omit } from 'lodash'
import { useHistory } from 'react-router-dom'
import { MdClear } from 'react-icons/md'
import classnames from 'classnames'
import LocationSelector from './LocationSelector'
import OfferCard from './OfferCard'
import {
  BusinessSearchForm,
  CategorySelectorForm,
  SortSelectorForm,
} from '../forms'
import {
  DEFAULT_SEARCH_RESULTS_PAGE_SIZE,
  PATH,
  QUERY_KEY,
  makeMemberPortalPath,
} from 'config'
import { SavingsClubOfferType, SearchCriteriaType } from 'types'
import { makeQueryPath, useToggle } from 'utils'

const propTypes = {
  currentPage: PropTypes.number.isRequired,
  results: PropTypes.arrayOf(SavingsClubOfferType).isRequired,
  searchCriteria: SearchCriteriaType.isRequired,
}
const defaultProps = {}

function SavingsClubSearchResults({ currentPage, results, searchCriteria }) {
  const [filterActive, toggleFilterActive] = useToggle(false)
  const history = useHistory()
  const category = searchCriteria[QUERY_KEY.CATEGORY]
  const searchTerm = searchCriteria[QUERY_KEY.SEARCH_TERM]
  const totalResults = results.length

  return (
    <>
      <PageHeader
        backLinkText="Back to Membership Dashboard"
        backLinkTo={makeMemberPortalPath(PATH.MEMBERSHIPS)}
        headerText="Savings Club"
      />
      <div className="search-and-filter-block-container">
        <div className="container-inner">
          <button
            className="button-primary filter"
            onClick={toggleFilterActive}
          >
            <img
              src="/assets/images/icons/filter.svg"
              alt=""
              className="icon"
            />
            Filter
          </button>
          <aside className={classnames({ ['is-active']: filterActive })}>
            <header>
              <h2>Browse Offers</h2>
              <button aria-label="Close filters" className="link-secondary">
                <MdClear />
              </button>
            </header>
            <BusinessSearchForm
              enableReinitialize
              initialValues={{ searchTerm }}
              onSubmit={({ searchTerm: newSearchTerm }) => {
                if (newSearchTerm) {
                  history.push(
                    makeSearchQuery(searchCriteria, {
                      [QUERY_KEY.SEARCH_TERM]: newSearchTerm,
                    })
                  )
                }
              }}
            />
            <LocationSelector {...{ makeSearchQuery, searchCriteria }} />
            <CategorySelectorForm
              enableReinitialize
              initialValues={{ category }}
              onChange={({ category: newCategory }) =>
                history.push(
                  makeSearchQuery(searchCriteria, {
                    [QUERY_KEY.CATEGORY]: newCategory,
                  })
                )
              }
              touchOnChange
            />
          </aside>

          <section className="results-block">
            <SearchResults {...{ currentPage, results, searchCriteria }} />
            <Paginator
              onChange={(newPageNumber) =>
                history.push(
                  makeSearchQuery(searchCriteria, {
                    [QUERY_KEY.PAGE]: newPageNumber,
                  })
                )
              }
              page={currentPage}
              totalResults={totalResults}
            />
          </section>
        </div>
      </div>
    </>
  )
}

SavingsClubSearchResults.propTypes = exact(propTypes)
SavingsClubSearchResults.defaultProps = defaultProps

export default pure(SavingsClubSearchResults)

function SearchResults({ currentPage, results, searchCriteria }) {
  const history = useHistory()
  const totalResults = results.length
  const currentOffset = (currentPage - 1) * DEFAULT_SEARCH_RESULTS_PAGE_SIZE
  const currentResultSet = results.slice(
    currentOffset,
    currentOffset + DEFAULT_SEARCH_RESULTS_PAGE_SIZE
  )
  const searchTerm = searchCriteria[QUERY_KEY.SEARCH_TERM]
  const sort = searchCriteria[QUERY_KEY.SORT]
  const country = searchCriteria[QUERY_KEY.COUNTRY]
  const category = searchCriteria[QUERY_KEY.CATEGORY]
  const displayClear = searchTerm || category !== 'All'

  return (
    <>
      <header className="search-header">
        <div>
          <h2>
            Showing {totalResults} Offer{totalResults !== 1 && 's'}
            {searchTerm && <span> for "{searchTerm}" </span>}
          </h2>
          {displayClear && (
            <button
              onClick={() => {
                const newSearchCriteria = omit(
                  searchCriteria,
                  QUERY_KEY.SEARCH_TERM
                )

                history.push(
                  makeSearchQuery(newSearchCriteria, {
                    [QUERY_KEY.CATEGORY]: 'All',
                  })
                )
              }}
              className="link-secondary"
            >
              <MdClear />
              Clear
            </button>
          )}
        </div>
        <SortSelectorForm
          enableReinitialize
          initialValues={{ sort }}
          onChange={({ sort: newSort }) =>
            history.push(
              makeSearchQuery(searchCriteria, {
                [QUERY_KEY.SORT]: newSort,
              })
            )
          }
          touchOnChange
        />
      </header>
      <div className="card-preview-list">
        {totalResults > 0 ? (
          currentResultSet.map((result) => (
            <OfferCard
              key={result.id}
              country={country}
              savingsClubOffer={result}
            />
          ))
        ) : (
          <div className="empty-state">
            <h3>No results</h3>
            <p>
              Sorry. We couldn't find any offers that match your search. Please
              try searching for another business.
            </p>
          </div>
        )}
      </div>
    </>
  )
}

function makeSearchQuery(currentSearchCriteria, newSearchCriteria) {
  return makeQueryPath(makeMemberPortalPath(PATH.SAVINGS_CLUB), {
    ...currentSearchCriteria,
    ...newSearchCriteria,
  })
}
