import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { QuickLinkType } from 'types'

const propTypes = {
  links: PropTypes.arrayOf(QuickLinkType).isRequired,
  header: PropTypes.string,
}
const defaultProps = {
  header: 'Quick Links',
}

function QuickLinks({ links, header }) {
  return (
    <section className="card quick-links">
      <h3>{header}</h3>

      <ul>
        {links.map(({ linkName, path }) => (
          <li key={linkName}>
            <a href={path}>{linkName}</a>
          </li>
        ))}
      </ul>
    </section>
  )
}

QuickLinks.propTypes = exact(propTypes)
QuickLinks.defaultProps = defaultProps

export default pure(QuickLinks)
