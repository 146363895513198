import React from 'react'
import PropTypes from 'prop-types'
import { pure } from 'recompose'
import { Paginator as BasePaginator } from 'lp-components'
import { DEFAULT_SEARCH_RESULTS_PAGE_SIZE } from 'config'

const propTypes = {
  onChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
}
const defaultProps = {}

function Paginator({ onChange, page, totalResults }) {
  const totalPages = Math.ceil(totalResults / DEFAULT_SEARCH_RESULTS_PAGE_SIZE)

  if (totalPages === 0) return null

  return (
    <BasePaginator
      max={totalPages}
      nextLabel=""
      previousLabel=""
      onChange={onChange}
      value={page}
    />
  )
}

Paginator.propTypes = propTypes
Paginator.defaultProps = defaultProps

export default pure(Paginator)
