import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { ButtonLink, PageHeader } from 'components'
import completed from 'utility-icons/completed-yellow.svg'
import { PATH, makePath } from 'config'

const propTypes = {
  backLinkText: PropTypes.string,
  backLinkTo: PropTypes.string,
  headerText: PropTypes.string.isRequired,
}
const defaultProps = {
  backLinkText: 'Back to Dashboard',
  backLinkTo: makePath(PATH.PROGRAMS_PORTAL),
}

function ThankYou({ backLinkText, backLinkTo, headerText }) {
  return (
    <>
      <PageHeader {...{ headerText }} />
      <section className="form-block-container">
        <div className="card card-small centered">
          <div className="card-inner">
            <header>
              <img alt="success" src={completed} className="icon" />
              <h2>Thank you for applying!</h2>
            </header>
            <p>
              Your application will be reviewed by the Sea Tow team. Within 72
              hours we will be in touch via email to share next steps.
            </p>
            <ButtonLink
              className="button-primary"
              to={backLinkTo}
            >
              {backLinkText}
            </ButtonLink>
          </div>
        </div>
      </section>
    </>
  )
}

ThankYou.propTypes = exact(propTypes)
ThankYou.defaultProps = defaultProps

export default pure(ThankYou)
