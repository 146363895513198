import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { Routes as AccountRoutes } from './account'
import { Routes as DashboardRoutes } from './dashboard'
import { Routes as MembershipKitRoutes } from './membership_kit'
import { Routes as ProgramsApplicationRoutes } from './participant_application'
import { Routes as SeaCareRoutes } from './sea_care'
import { Routes as SavingsClubOffersRoutes } from './savings_club_offers'
import Layout from './Layout'
import { AuthenticatedRoutes, Logout } from 'components'
import { AllProgramsProvider, PartnerAccountProvider } from './components'
import { PATH, makePath } from 'config'

const propTypes = {}
const defaultProps = {}

function Routes() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      {/* Unauthenticated routes */}
      <Route path={makePath(path, PATH.APPLY)}>
        <ProgramsApplicationRoutes />
      </Route>

      {/* Authenticated routes */}
      <Route>
        <AuthenticatedRoutes>
          <PartnerAccountProvider>
            <AllProgramsProvider>
              <Layout>
                <Switch>
                  <Route path={makePath(path, PATH.ACCOUNT)}>
                    <AccountRoutes />
                  </Route>
                  <Route path={makePath(path, PATH.PROGRAMS_DASHBOARD)}>
                    <DashboardRoutes />
                  </Route>
                  <Route path={makePath(path, PATH.LOGOUT)}>
                    <Logout />
                  </Route>
                  <Route path={makePath(path, PATH.MEMBERSHIP_KIT)}>
                    <MembershipKitRoutes />
                  </Route>
                  <Route path={makePath(path, PATH.SEA_CARE)}>
                    <SeaCareRoutes />
                  </Route>
                  <Route path={makePath(path, PATH.SAVINGS_CLUB_OFFERS)}>
                    <SavingsClubOffersRoutes />
                  </Route>
                  <Redirect
                    path="*"
                    to={makePath(path, PATH.PROGRAMS_DASHBOARD)}
                  />
                </Switch>
              </Layout>
            </AllProgramsProvider>
          </PartnerAccountProvider>
        </AuthenticatedRoutes>
      </Route>
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
