import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { MembershipType } from 'types'
import { selectors as memberSelectors } from 'member-reducer'
import { LocatePortSection } from 'memberships/boats/components/'
import { PATH, makeMembershipIdPath } from 'config'

const propTypes = {
  membership: MembershipType.isRequired,
}

const defaultProps = {}

function RenewalLocatePort({ membership }) {
  return (
    <LocatePortSection
      previousStep={makeMembershipIdPath(
        membership.sfid,
        PATH.RENEW_MEMBERSHIP,
        PATH.BOATS,
        PATH.HOME_PORT_ADDRESS
      )}
    />
  )
}

RenewalLocatePort.propTypes = propTypes
RenewalLocatePort.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    membership: memberSelectors.membership(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RenewalLocatePort
)
