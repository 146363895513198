import React from 'react'
import PropTypes from 'prop-types'
import { SiteMetadata } from 'components'
import { SITE_METADATA } from 'config'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function Layout({ children }) {
  const { metaTitle, description, keywords } = SITE_METADATA.MEMBERS_RENEW
  return (
    <>
      <SiteMetadata
        metaTitle={metaTitle}
        description={description}
        keywords={keywords}
      />
      <div className="image-and-form-layout purchase-flow">
        <div className="split-image-block">
          <img src="/assets/images/imagery/purchase-flow/boat.png" alt="" />
        </div>
        {children}
      </div>
    </>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default Layout
