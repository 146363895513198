import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button } from 'lp-components'
import { IMAGE_PATH, PATH, makeMembershipsPath } from 'config'

const propTypes = {
  refreshMemberships: PropTypes.func.isRequired,
  confirmationHeader: PropTypes.string.isRequired,
  confirmationMessage: PropTypes.string.isRequired,
}
const defaultProps = {}

function UpgradesConfirmationStep({ refreshMemberships, confirmationHeader, confirmationMessage }) {
  const history = useHistory()
  return (
    <section className="form-block-container confirmation">
      <div className="card card-medium">
        <header>
          <img alt="" src={IMAGE_PATH.MEMBER_APPLICATION_CONFIRMATION} className="confirmation-icon" />
        </header>
        <h2>{confirmationHeader}</h2>
        <p>{confirmationMessage}</p>

        <Button
          onClick={async () => {
            await refreshMemberships()
            history.push(makeMembershipsPath(PATH.ROOT))
          }}
        >
          Return to Member Center
        </Button>
      </div>
    </section>
  )
}

UpgradesConfirmationStep.propTypes = propTypes
UpgradesConfirmationStep.defaultProps = defaultProps

export default UpgradesConfirmationStep
